import { Component, OnInit } from '@angular/core';
import { GraphqlService, Noticia, ProprietarioNoticia } from '../../../service/graphql/graphql.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-detalhe-noticia-view',
  templateUrl: './detalhe-noticia-view.component.html',
  styleUrls: ['./detalhe-noticia-view.component.css']
})
export class DetalheNoticiaViewComponent implements OnInit {

  public noticias: Noticia[];
  public proprietario: string;
  public titulo: string;
  private sub: Subscription;

  constructor(private graphqlService: GraphqlService,
    private route: ActivatedRoute) {}

  public get rotaAtual() {
    return window.location.href;
  }

  ngOnInit() {
   this.sub = this.route.params.subscribe(
      (params) => {
        this.proprietario = (params.proprietario || "").toUpperCase();
        this.inicializar();
      }
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  
  private inicializar(): void {
    this.noticias = [];
    this.graphqlService.obtemNoticiasPublicadas().subscribe(
      noticias => {
        this.noticias = noticias.filter(n => n.proprietario === this.proprietario);
      }
    );
    if (this.proprietario === ProprietarioNoticia.CONPEDI) {
      this.titulo = "Notícias CONPEDI";
    } else {
      this.titulo = "Notícias PPGDs";
    }
  }

}
