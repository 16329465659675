import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { EventoComponent } from './pages/evento/evento.component';
import { HotsiteComponent } from './pages/hotsite/hotsite.component';
import { HotsiteEditorComponent } from './pages/hotsite-editor/hotsite-editor.component';
import { HotsiteViewComponent } from './pages/hotsite-view/hotsite-view.component';
import { GaleriaListComponent } from './pages/galeria-list/galeria-list.component';
import { GaleriaViewComponent } from './pages/galeria-view/galeria-view.component';
import { TemlateSiteComponent } from './pages/temlate-site/temlate-site.component';
import { InicialComponent } from './pages/inicial/inicial.component';
import { NoticiasListComponent } from './pages/noticias-list/noticias-list.component';
import { NoticiasViewComponent } from './pages/noticias-view/noticias-view.component';
import { NoticiasInsertComponent } from './pages/noticias-insert/noticias-insert.component';
import { NoticiasEditComponent } from './pages/noticias-edit/noticias-edit.component';
import { MenuLateralListComponent } from './pages/menu-lateral-list/menu-lateral-list.component';
import { ColunistaListComponent } from './pages/colunista-list/colunista-list.component';
import { ColunistaEditorComponent } from './pages/colunista-editor/colunista-editor.component';
import { ColunistaViewComponent } from './pages/colunista-view/colunista-view.component';
import { TvConpediListComponent } from './pages/tv-conpedi-list/tv-conpedi-list.component';
import { MapaPpdgListComponent } from './pages/mapa-ppdg-list/mapa-ppdg-list.component';
import { MapaPpdgEditComponent } from './pages/mapa-ppdg-edit/mapa-ppdg-edit.component';
import { MapaPpgdInsertComponent } from './pages/mapa-ppgd-insert/mapa-ppgd-insert.component';
import { AgendaListComponent } from './pages/agenda-list/agenda-list.component';
import { EditalListComponent } from './pages/edital-list/edital-list.component';
import { EspacoDoAutorListComponent } from './pages/espaco-do-autor-list/espaco-do-autor-list.component';
import { RevistaConpediListComponent } from './pages/revista-conpedi-list/revista-conpedi-list.component';
import { NoticiaViewComponent } from './pages/noticia-view/noticia-view.component';
import { TvsConpediViewComponent } from './pages/tvs-conpedi-view/tvs-conpedi-view.component';
import { EspacosAutorViewComponent } from './pages/espacos-autor-view/espacos-autor-view.component';
import { EspacoAutorLivroViewComponent } from './pages/espaco-autor-livro-view/espaco-autor-livro-view.component';
import { RevistasViewComponent } from './pages/revistas-view/revistas-view.component';
import { ColunistaPortalViewComponent } from './pages/colunista-portal-view/colunista-portal-view.component';
import { ColunistasPortalViewComponent } from './pages/colunistas-portal-view/colunistas-portal-view.component';
import { DetalheNoticiaViewComponent } from './pages/portal/detalhe-noticia-view/detalhe-noticia-view.component';
import { DetalheGaleriaViewComponent } from './pages/portal/detalhe-galeria-view/detalhe-galeria-view.component';
import { AnuidadeManualComponent } from './pages/anuidade-manual/anuidade-manual.component';
import { EventoEditorComponent } from './pages/evento-editor/evento-editor.component';
import { GtpEditaComponent } from './pages/gtp-edita/gtp-edita.component';
import { GtpCoordenadorComponent } from './pages/gtp-coordenador/gtp-coordenador.component';
import { FaleConoscoComponent } from './pages/fale-conosco/fale-conosco.component';
import { MemoriaComponent } from './pages/memoria/memoria.component';
import { MemoriaEditComponent } from './pages/memoria-edit/memoria-edit.component';
import { FaleConoscoEditComponent } from './pages/fale-conosco-edit/fale-conosco-edit.component';
import { QuemSomosComponent } from './pages/quem-somos/quem-somos.component';
import { QuemSomosSobreGerenciarComponent } from './pages/quem-somos-sobre-gerenciar/quem-somos-sobre-gerenciar.component';
import { QuemSomosMissaoGerenciarComponent } from './pages/quem-somos-missao-gerenciar/quem-somos-missao-gerenciar.component';
import { QuemSomosValoresGerenciarComponent } from './pages/quem-somos-valores-gerenciar/quem-somos-valores-gerenciar.component';
import { QuemSomosSobreEditComponent } from './pages/quem-somos-sobre-edit/quem-somos-sobre-edit.component';
import { QuemSomosMissaoEditComponent } from './pages/quem-somos-missao-edit/quem-somos-missao-edit.component';
import { QuemSomosValoresEditComponent } from './pages/quem-somos-valores-edit/quem-somos-valores-edit.component';
import { RoleGuardService } from './service/role-guard/role-guard.service';
import { AgendaComponent } from './pages/agenda/agenda.component';
import { GtpPublicacaoComponent } from './pages/gtp-publicacao/gtp-publicacao.component';
import { GtpPublicacaoListaComponent } from './pages/gtp-publicacao-lista/gtp-publicacao-lista.component';
import { GtpPublicacaoTextoComponent } from './pages/gtp-publicacao-texto/gtp-publicacao-texto.component';
import { PublicacaoListaEventoComponent } from './pages/publicacao-lista-evento/publicacao-lista-evento.component';
import { PublicacaoListaGtComponent } from './pages/publicacao-lista-gt/publicacao-lista-gt.component';
import { PublicacaoListaTrabalhoComponent } from './pages/publicacao-lista-trabalho/publicacao-lista-trabalho.component';
import { TrabalhosListComponent } from './pages/trabalhos-list/trabalhos-list.component';
import { LinksGtsViewComponent } from './pages/links-gts-view/links-gts-view.component';
import { GtPublicacaoComponent } from './pages/gt-publicacao/gt-publicacao.component';
import { PainelEventoInsertComponent } from './pages/painel-evento-insert/painel-evento-insert.component';
import { PainelEventoEditComponent } from './pages/painel-evento-edit/painel-evento-edit.component';
import { DiretoriaComponent } from './pages/diretoria/diretoria.component';
import { DiretoriaListComponent } from './pages/diretoria-list/diretoria-list.component';
import { DiretoresListComponent } from './pages/diretores-list/diretores-list.component';
import { DiretoriaUpsertComponent } from './pages/diretoria-upsert/diretoria-upsert.component';
import { DiretorUpsertComponent } from './pages/diretor-upsert/diretor-upsert.component';
import { DiretorFotoComponent } from './pages/diretor-foto/diretor-foto.component';
import { DocumentosComponent } from './pages/documentos/documentos.component';
import { DocumentosListComponent } from './pages/documentos-list/documentos-list.component';
import { BannerListComponent } from './pages/banner-list/banner-list.component';
import { DocumentosTextoIntrodutorioComponent } from './pages/documentos-texto-introdutorio/documentos-texto-introdutorio.component';
import { DocumentosTextoIntrodutorioEditComponent } from './pages/documentos-texto-introdutorio-edit/documentos-texto-introdutorio-edit.component';
import { DocumentoUpsertComponent } from './pages/documento-upsert/documento-upsert.component';
import { AreaDoAssociadoComponent } from './pages/area-do-associado/area-do-associado.component';
import { EditaisComponent } from './pages/editais/editais.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { MapaPpgdComponent } from './pages/mapa-ppgd/mapa-ppgd.component';
import { EventoAssociadoComponent } from './pages/evento-associado/evento-associado.component';
import { EnviarArtigoComponent } from './pages/enviar-artigo/enviar-artigo.component';
import { AuthLoginExternoService } from './service/auth-login-externo/auth-login-externo.service';
import { EventoInscricaoComponent } from './pages/evento-inscricao/evento-inscricao.component';
import { EnviarPosterComponent } from './pages/enviar-poster/enviar-poster.component';
import { EventoTrabalhoComponent } from './pages/evento-trabalho/evento-trabalho.component';
import { AuthGuardService } from './service/auth-guard/auth-guard.service';
import { AtualizaDadosComponent } from './pages/atualiza-dados/atualiza-dados.component';
import { LoginAdmComponent } from './pages/login-adm/login-adm.component';
import { ConfiguracaoGeralComponent } from './pages/configuracao-geral/configuracao-geral.component';

export const routes: Routes = [
    {
        path: '', component: TemlateSiteComponent, children: [
            {
                path: 'adm', children: [
                    { path: 'evento', component: EventoComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'configuracao-geral', component: ConfiguracaoGeralComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'evento-editor/:id', component: EventoEditorComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'gtp-edita/:id', component: GtpEditaComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'gtp-coordenador/:id', component: GtpCoordenadorComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'gtp-publicacao/:id', component: GtpPublicacaoComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'gt-publicacao/:id', component: GtPublicacaoComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'gtp-publicacao-lista', component: GtpPublicacaoListaComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'gtp-publicacao-texto/:id', component: GtpPublicacaoTextoComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'hotsite', component: HotsiteComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'hotsite-editor/:id', component: HotsiteEditorComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'galeria', component: GaleriaListComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'noticias-list', component: NoticiasListComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'noticias-view/:id', component: NoticiasViewComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'menu-lateral-list', component: MenuLateralListComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'colunistas-list', component: ColunistaListComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'mapa-ppgd-list', component: MapaPpdgListComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'mapa-ppgd-edit/:id', component: MapaPpdgEditComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'mapa-ppgd-insert', component: MapaPpgdInsertComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'tv-conpedi-list', component: TvConpediListComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'revista-conpedi-list', component: RevistaConpediListComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'edital-list', component: EditalListComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'agenda-list', component: AgendaListComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'espaco-autor-list', component: EspacoDoAutorListComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'colunistas-view/:id', component: ColunistaViewComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'colunistas-editor/:id', component: ColunistaEditorComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'noticias-insert', component: NoticiasInsertComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'noticias-edit/:id', component: NoticiasEditComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'revistas-view', component: RevistasViewComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'colunistas-portal-view', component: ColunistasPortalViewComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'anuidade-manual', component: AnuidadeManualComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS',] } },
                    { path: 'fale-conosco-edit', component: FaleConoscoEditComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'memoria-edit', component: MemoriaEditComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'quem-somos-sobre', component: QuemSomosSobreGerenciarComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'quem-somos-missao', component: QuemSomosMissaoGerenciarComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'quem-somos-valores', component: QuemSomosValoresGerenciarComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'quem-somos-sobre-edit', component: QuemSomosSobreEditComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'quem-somos-missao-edit', component: QuemSomosMissaoEditComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'quem-somos-valores-edit', component: QuemSomosValoresEditComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'quem-somos-valores-edit', component: QuemSomosValoresEditComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'trabalhos-list/:id', component: TrabalhosListComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'painel-evento-insert/:idEvento', component: PainelEventoInsertComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'painel-evento-edit/:idEvento/:idPainel', component: PainelEventoEditComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'diretoria-list', component: DiretoriaListComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'diretores-list/:idDiretoriaGrupo', component: DiretoresListComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'diretoria-edit/:idDiretoriaGrupo', component: DiretoriaUpsertComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'diretoria-insert', component: DiretoriaUpsertComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'diretor-edit/:idDiretor', component: DiretorUpsertComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'diretor-insert/:idDiretoriaGrupo', component: DiretorUpsertComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'diretor-foto/:idDiretor', component: DiretorFotoComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'banner-list', component: BannerListComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'documentos-list', component: DocumentosListComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'documentos-texto-introdutorio', component: DocumentosTextoIntrodutorioComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'documentos-texto-introdutorio-edit', component: DocumentosTextoIntrodutorioEditComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'documento-insert', component: DocumentoUpsertComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'documento-edit/:idDocumento', component: DocumentoUpsertComponent, canActivate: [RoleGuardService], data: { roles: ['CRIA_EVENTOS', 'GESTOR_CONTEUDO'] } },
                    { path: 'area-do-associado', component: AreaDoAssociadoComponent, canActivate: [AuthGuardService] },
                    { path: 'area-do-associado/eventos/:idEvento', component: EventoAssociadoComponent, canActivate: [AuthGuardService] },
                    { path: 'area-do-associado/eventos/:idEvento/enviar-artigo', component: EnviarArtigoComponent, canActivate: [AuthGuardService] },
                    { path: 'area-do-associado/eventos/:idEvento/inscricao', component: EventoInscricaoComponent, canActivate: [AuthGuardService] },
                    { path: 'area-do-associado/eventos/:idEvento/enviar-poster', component: EnviarPosterComponent, canActivate: [AuthGuardService] },
                    { path: 'area-do-associado/eventos/:idEvento/trabalho/:idTrabalho', component: EventoTrabalhoComponent, canActivate: [AuthGuardService] },
                    { path: 'atualizar-dados', component: AtualizaDadosComponent, canActivate: [AuthGuardService] },
                ]
            },
            { path: '', component: InicialComponent },
            { path: 'login', component: LoginAdmComponent },
            { path: 'publicacao-lista-evento/:tipo', component: PublicacaoListaEventoComponent },
            { path: 'publicacao-lista-gt/:tipo/:idEvento', component: PublicacaoListaGtComponent },
            { path: 'publicacao-lista-trabalho/:tipo/:idEvento/:idGt', component: PublicacaoListaTrabalhoComponent },
            { path: 'galeria-view/:id', component: GaleriaViewComponent },
            { path: 'noticia-view/:id', component: NoticiaViewComponent },
            { path: 'tvs-conpedi-view', component: TvsConpediViewComponent },
            { path: 'agenda', component: AgendaComponent },
            { path: 'quem-somos', component: QuemSomosComponent },
            { path: 'diretoria', component: DiretoriaComponent },
            { path: 'documentos', component: DocumentosComponent },
            { path: 'memoria', component: MemoriaComponent },
            { path: 'fale-conosco', component: FaleConoscoComponent },
            { path: 'detalhe-galeria-view', component: DetalheGaleriaViewComponent },
            { path: 'espacos-autor-view', component: EspacosAutorViewComponent },
            { path: 'espacos-autor-view/:id', component: EspacoAutorLivroViewComponent },
            { path: 'colunista-portal-view/:id', component: ColunistaPortalViewComponent },
            { path: 'detalhe-noticia-view/:proprietario', component: DetalheNoticiaViewComponent },
            { path: 'editais/conpedi', component: EditaisComponent },
            { path: 'editais/parceiros', component: EditaisComponent },
            { path: 'editais/diversos', component: EditaisComponent },
            { path: 'eventos/conpedi', component: EventosComponent },
            { path: 'eventos/parceiros', component: EventosComponent },
            { path: 'mapa-dos-ppgds', component: MapaPpgdComponent },
            { path: 'login/:token', children: [], canActivate: [AuthLoginExternoService] }
        ],

    },
    { path: 'h/:caminho', component: HotsiteViewComponent },
    { path: 'links-gts-view/:idEvento/:idPessoa', component: LinksGtsViewComponent },

];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled',
});
