import { Injectable } from '@angular/core';
import { Http } from '../shared/http'
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import _ from 'lodash';
import fileSaver from 'file-saver';
import * as mime from 'mime-types';
import { emit } from 'cluster';

export interface Usuario {
  id: number,
  nome: string,
  token: string,
  roles: string[],
}

export interface Evento {
  id: string,
  titulo: string,
  tema: string,
  tipoEvento: TipoEvento,
  skEvento?: string,
  textoDataEvento: string,
  eventoEncerrado: boolean,
  publicacaoLiberadaArtigo: boolean,
  publicacaoLiberadaPoster: boolean,
  dataInicial: string,
  dataFinal: string,
  hotsite: Hotsite
  recebePosters: boolean
  limitePalavrasResumoPoster: number
  autoresMinimoPoster: number
  autoresMaximoPoster: number
  limitePalavrasResumoArtigo: number
  autoresMinimoArtigo: number
  autoresMaximoArtigo: number
  paginaArtigoMin: number
  paginaArtigoMax: number
  paginaPosterMin: number
  paginaPosterMax: number
  possuiAcessoEvento?: boolean
  linkEventoLiberado: boolean
}

export interface DiretoriaGrupo {
  id: string
  nomeGrupo: string
  diretores: Diretor[]
  ordem: number
  ativo: boolean
}

export interface Diretor {
  id: string
  foto: Arquivo
  ordem: number
  titulo: string
  qualificacao: string
  nomeCompleto: string
  diretoriaGrupo?: DiretoriaGrupo
  instituicao: string
  rodape: string
  urlLattes: string
  ativo: boolean
}

export interface Arquivo {
  id: string,
  url: string
}

export interface HotsiteSecao {
  id: string,
  ordem: number,
  titulo: string,
  conteudo: string
}

export interface Contato {
  nome: string,
  email: string,
  area: string,
  telefone?: string,
  mensagem: string
}

export interface Hotsite {
  id: string,
  descricao: string,
  caminho: string,
  imagemInicial: Arquivo,
  imagemHotsite: Arquivo,
  habilitado: boolean
  secao: HotsiteSecao[]
}

export interface Arquivo {
  id: string,
  hash?: string,
  url: string
  nome?: string
}

export interface Galeria {
  id: string,
  titulo: string,
  descricao: string,
  ordem: number,
  foto: Arquivo[]
}

export interface Banner {
  id: string
  link: string
  imagem: Arquivo
}

export enum StatusNoticia {
  RASCUNHO = "RASCUNHO",
  PUBLICADO = "PUBLICADO",
  AGENDADO = "AGENDADO"
}

export enum ProprietarioNoticia {
  CONPEDI = "CONPEDI",
  PPGD = "PPGD"
}

export interface Noticia {
  id: string,
  proprietario: ProprietarioNoticia,
  status: StatusNoticia,
  dataNoticia: string,
  titulo: string,
  descricao: string,
  arquivoFotoChamada: Arquivo,
  arquivoImagemNoticia: Arquivo,
  texto: string
}

export interface EspacoAutor {
  id: string,
  titulo: string,
  genero: string,
  editora: string,
  palavrasChave: string,
  ondeComprar: string,
  numeroPaginas: number,
  sinopse: string,
  autoresPublicacao: string,
  capa: Arquivo,
  valorVenda: number,
  comprarUrl: string
}

export interface MenuLateral {
  id: string,
  descricao: string,
  link: string,
  arquivo: Arquivo
}

export interface Colunista {
  id: string,
  statusAssociado: string,
  foto: Arquivo,
  nomePublico: string,
  miniCurriculo: string,
  pessoa: Pessoa,
  publicado: boolean
}

export interface Pessoa {
  id: string,
  nome: string,
  email?: string,
  inscrito?: boolean,
  confirmacaoAutoria?: ConfirmacaoAutoria
}

export enum TipoDocumento {
  CPF = "CPF",
  PASSAPORTE = "PASSAPORTE",
  OUTRO = "OUTRO"
}

export interface PessoaFisica {
  id: string
  nome: string
  email: string
  emailAlt: string
  telefone: string
  telefoneAlt: string
  cpf: string
  tipoDocumento: TipoDocumento
  outroDocumentoTipo: string
  outroDocumentoValor: string
  instituicaoCadastrado: boolean
  sexo: string
  dataNascimento: string
  formacao: string
  urlCurriculoLattes: string
  instituicaoNome: string
  instituicaoSigla: string
  instituicaoUf: string
  nacionalidade: string
  endereco: PessoaEndereco
}

export interface PessoaEndereco {
  logradouro: string
  numero: string
  complemento: string
  bairro: string
  municipio: string
  cep: string
  uf: string
  pais: string
}

export interface Video {
  id: string,
  titulo: string,
  descricao: string,
  dataInclusao: string,
  urlYoutube: string,
  publicado: boolean,
  destaqie: boolean
}

export interface MapaPpgd {
  id: string,
  titulo: string,
  sigla: string,
  iesNomeCompleto: string,
  iesSigla: string,
  cidade: string,
  uf: string,
  apresentacao: string,
  email: string,
  telefone: string,
  nomeCoordenador: string,
  urlPrograma: string,
  urlFacebook: string,
  urlTwitter: string,
  urlOutro: string,
  fgAtivo: boolean
}

export interface Agenda {
  id: string,
  dataItem: string,
  titulo: string,
  descricao: string,
  urlLabel: string,
  urlDestino: string,
  fgPublicado: boolean
}

export interface PessoaAutor {
  id: string,
  nome: string
}

export interface Edital {
  id: string,
  pessoaAutor: PessoaAutor,
  arquivo: Arquivo,
  autor: string,
  secao: SecaoEdital,
  descricaoEdital: string,
  fgPublicado: boolean,
  dtCadastro: string,
  dataPrazo: string
}

export enum SecaoEdital {
  CONPEDI = "CONPEDI",
  PARCEIRO = "PARCEIRO",
  OUTROS = "OUTROS"
}

export interface ResultadoLogin {
  ok: boolean,
  token: string,
  msg: string
}

export interface LinhaPesquisa {
  id: string,
  descricao: string,
  numeroGtp: number,
  totalArtigos: number
}

export interface GrupoTrabalho {
  id: string,
  numeroGrupo: number,
  ativo: boolean,
  linhaPesquisa?: LinhaPesquisa,
  tipoPublicacao?: string,
  isbnIssn?: string,
  textoApresentacao: string,
  versaoFinalTextoApresentacao: boolean,
  dtTextoApresentacao: string,
  fichaCatalografica: Arquivo,
  trabalhos: Trabalho[],
  linkApresentacao?: string
}

export interface Documento {
  id: string
  descDocumento: string
  publicado: boolean
  grupo: GrupoDocumento
  arquivo: Arquivo
}

export interface GrupoTrabalhoPoster extends GrupoTrabalho {
  filePublicacao: Arquivo
}

export interface Trabalho {
  id: string,
  titulo: string,
  tituloOutroIdioma: string
  resumo: string
  resumoOutroIdioma: string
  tipoTrabalho?: TipoTrabalho,
  linhaPesquisa?: LinhaPesquisa,
  grupoTrabalhoPoster?: GrupoTrabalhoPoster,
  grupoTrabalho?: GrupoTrabalho,
  dataHoraRecebimento?: string,
  analiseEditorial?: string,
  nota?: string,
  paginaInicial: number,
  paginaFinal: number,
  skTrabalho: string,
  orientador?: Pessoa[],
  arquivo: Arquivo
  autor?: Pessoa[]
}

export interface OrdemTrabalho {
  idGrupo: number,
  idTrabalho: number,
  ordem: number
}

export interface QuemSomos {
  sobre: DescricaoQuemSomos,
  missao: DescricaoQuemSomos,
  valores: DescricaoQuemSomos
}

export interface DescricaoQuemSomos {
  descricao: string,
  imagem: Arquivo
}

export interface AgendaInput {
  mes: number,
  ano: number
}

export enum TipoTrabalho {
  ARTIGO = "ARTIGO",
  POSTER = "POSTER"
};

export enum TipoEvento {
  CONPEDI = "CONPEDI",
  PARCEIRO = "PARCEIRO"
};

export enum FiltroAprovacaoTrabalho {
  TODOS = "TODOS",
  SOMENTE_APROVADOS = "SOMENTE_APROVADOS",
  SOMENTE_NAO_APROVADOS = "SOMENTE_NAO_APROVADOS"
}

export enum FiltroAutor {
  TODOS = "TODOS",
  SEM_RESPOSTA = "SEM_RESPOSTA",
  CONFIRMADOS = "CONFIRMADOS",
  RECUSADOS = "RECUSADOS"
}

export enum OrdenacaoTrabalho {
  TITULO = "TITULO",
  NOTA = "NOTA",
  NOTA_PRESENCIAL = "NOTA_PRESENCIAL",
  RECEBIMENTO = "RECEBIMENTO"
}

export enum ConfirmacaoAutoria {
  CONFIRMADO = "CONFIRMADO",
  RECUSADO = "RECUSADO",
  SEM_RESPOSTA = "SEM_RESPOSTA"
}

export enum GrupoDocumento {
  ESTATUTO = "ESTATUTO",
  ATAS = "ATAS",
  OUTROS = "OUTROS",
  PORTARIAS = "PORTARIAS"
}

export interface Painel {
  id: string
  tipo: string
  titulo: string
  link: string
  data: string
  evento: Evento
}

export enum TipoInscricaoEvento {
  ARTIGO = "ARTIGO",
  POSTER = "POSTER",
  OUVINTE = "OUVINTE"
}


export interface InscricaoEvento {
  tipo: TipoInscricaoEvento,
  valores: {
    data: string
    valor: number
  }
}

export interface TituloEventoInput {
  idFormacaoExigidaAutor?: number
  formacaoAutor: string
  formacoesExigidas: {
    idEventoFormacaoExigida?: number
    formcao: string
  }
}

export interface EventoFormacaoExigidaAutor {
  id: string
  formacao: string
  eventoFormacaoExigida: EventoFormacaoExigida[]
}

export interface EventoValorInscricao {
  idEvento: number
  dataLimite: string
  tipoInscricao: string
  valor: number
  tipoAssociado: string
  disponivel: boolean
}

export enum TipoInscricaoEventoValor {
  ApresentaArtigo = "APRESENTA_ARTIGO",
  ApresentaPoster = "APRESENTA_POSTER",
  Ouvinte = "OUVINTE"
}

export interface EventoFormacaoExigida {
  id: string
  formacaoExigida: string
}

@Injectable({
  providedIn: 'root'
})
export class GraphqlService extends Http {

  constructor(private apollo: Apollo, protected http: HttpClient) {
    super(http);
  }

  private watchMutation(mutation: string, proprietyPath?: string | string[], variables?: any): Observable<any> {
    return new Observable(
      o => {
        this.apollo.mutate({
          mutation: gql(mutation),
          variables: variables
        }).subscribe(
          res => {
            // o appolo não trás os resultados encapsulados em um objeto com o nome do mutation
            const dados = res.data[Object.keys(res.data)[0]] as any;
            if (dados.ok) {
              if (proprietyPath) {
                o.next(_.get(dados, proprietyPath));
              } else {
                o.next();
              }
            } else {
              o.error(dados.msg);
            }
            o.complete()
          }, error => {
            o.error(error);
            o.complete();
          }
        )
      }
    )
  }

  private watchMutation2(mutation: string, proprietyPath?: string | string[], variables?: any): Observable<any> {
    return new Observable(
      o => {
        this.apollo.mutate({
          mutation: gql(mutation),
          variables: variables
        }).subscribe(
          res => {
            // o appolo não trás os resultados encapsulados em um objeto com o nome do mutation
            const dados = res.data[Object.keys(res.data)[0]] as any;
            if (proprietyPath) {
              o.next(_.get(dados, proprietyPath));
            } else {
              o.next(dados);
            }
            o.complete()
          }, error => {
            o.error(error);
            o.complete();
          }
        )
      }
    )
  }


  private watchQuery(query: string, proprietyPath: string | string[], variables?: any): Observable<any> {
    return new Observable(
      o => {
        this.apollo.watchQuery({
          query: gql(query),
          variables: variables
        }).valueChanges.subscribe(
          res => {
            o.next(_.get(res.data, proprietyPath));
            o.complete()
          }, error => {
            o.error(error);
            o.complete();
          }
        )
      }
    );
  }

  public obtemEventos(): Observable<Evento[]> {
    return this.watchQuery(`{
      evento {
        id
        titulo
        tema
        tipoEvento
        textoDataEvento
        skEvento
        recebePosters
        limitePalavrasResumoPoster
        autoresMinimoPoster
        autoresMaximoPoster
        limitePalavrasResumoArtigo
        autoresMinimoArtigo
        autoresMaximoArtigo
        paginaArtigoMin
        paginaArtigoMax
        paginaPosterMin
        paginaPosterMax
        linkEventoLiberado
        hotsite {
          id
          descricao
          caminho
          secao {
            id
            ordem
            titulo
            conteudo
          }
          imagemInicial {
            id
            url
          }
          imagemHotsite {
            id
            url
          }
        }
      }
     }`, "evento");
  }

  public obtemEventosAcessoEvento(idPessoa: string): Observable<Evento[]> {
    return this.watchQuery(`query evento($idPessoa: Int){
      evento {
        id
        titulo
        tema
        tipoEvento
        textoDataEvento
        skEvento
        recebePosters
        limitePalavrasResumoPoster
        autoresMinimoPoster
        autoresMaximoPoster
        limitePalavrasResumoArtigo
        autoresMinimoArtigo
        autoresMaximoArtigo
        paginaArtigoMin
        paginaArtigoMax
        paginaPosterMin
        paginaPosterMax
        linkEventoLiberado
        possuiAcessoEvento(idPessoa: $idPessoa)
        hotsite {
          id
          descricao
          caminho
          secao {
            id
            ordem
            titulo
            conteudo
          }
          imagemInicial {
            id
            url
          }
          imagemHotsite {
            id
            url
          }
        }
      }
     }`, "evento", {
      idPessoa: Number(idPessoa)
    });
  }

  public obtemEventosPorTipo(tipoEvento: TipoEvento): Observable<Evento[]> {
    return this.watchQuery(`query eventoPorTipo($tipo: TipoEvento!){
      eventoPorTipo(tipo: $tipo) {
        id
        titulo
        tema
        tipoEvento
        textoDataEvento
        eventoEncerrado
        publicacaoLiberadaArtigo
        publicacaoLiberadaPoster
        dataInicial
        dataFinal
        hotsite {
          id
          descricao
          caminho
          secao {
            id
            ordem
            titulo
            conteudo
          }
          imagemInicial {
            id
            url
          }
          imagemHotsite {
            id
            url
          }
        }
      }
     }`, "eventoPorTipo", {
      tipo: tipoEvento.toUpperCase()
    });
  }

  public obtemHotsitePorId(idHotsiteInfo: number): Observable<Hotsite> {
    return this.watchQuery(`
    query hotsite($idHotsiteInfo: Int) {
      hotsite(idHotsiteInfo: $idHotsiteInfo) {
        id
        descricao
        caminho
        secao {
          id
          ordem
          titulo
          conteudo
        }
        imagemInicial {
          id
          url
        }
        imagemHotsite {
          id
          url
        }
      }
    }`, "hotsite[0]", {
      idHotsiteInfo: idHotsiteInfo
    });
  }

  public obtemHotsitePorCaminho(caminho: string): Observable<Hotsite> {
    return this.watchQuery(`
    query hotsite($caminho: String) {
      hotsite(caminho: $caminho) {
        id
        descricao
        caminho
        secao {
          id
          ordem
          titulo
          conteudo
        }
        imagemInicial {
          id
          url
        }
        imagemHotsite {
          id
          url
        }
      }
    }`, "hotsite[0]", {
      caminho: caminho
    });
  }

  public obtemHotsitesPorDescricao(descricao: string, limite?: number): Observable<Hotsite[]> {
    return this.watchQuery(`
    query hotsitePorDescricao($descricao: String!, $limite: Int) {
      hotsitePorDescricao(descricao: $descricao, limite: $limite) {
        id
        descricao
        caminho
        secao {
          id
          ordem
          titulo
          conteudo
        }
        imagemInicial {
          id
          url
        }
        imagemHotsite {
          id
          url
        }
      }
    }`, "hotsitePorDescricao", {
      descricao: descricao,
      limite: limite
    });
  }

  public obtemHotsites(): Observable<Hotsite[]> {
    return this.watchQuery(`{
      hotsite {
        id
        descricao
        caminho
        imagemInicial {
          id
          url
        }
        imagemHotsite {
          id
          url
        }
        secao {
          id
          ordem
          titulo
          conteudo
        }
        habilitado
      }
    }`, "hotsite");
  }

  public login(email: String, senha: String): Observable<ResultadoLogin> {
    const mutation = `mutation autenticacao($email: String!, $senha: String!){
      autenticacao(email: $email, senha: $senha) {
        ok
        token
        msg
      }
    }`;
    const variables = {
      email: email,
      senha: senha
    };
    return new Observable(
      o => {
        this.apollo.mutate({
          mutation: gql(mutation),
          variables: variables
        }).subscribe(
          res => {
            const dados = res.data[Object.keys(res.data)[0]] as any;
            o.next(dados);
            o.complete();
          }, error => {
            o.error(error);
            o.complete();
          }
        )
      }
    )
  }

  public excluirHotsite(idHotsite: number): Observable<void> {
    return this.watchMutation(`
    mutation excluiHotsiteInfo($id: Int){
      excluiHotsiteInfo(id: $id) {
        ok
        msg
      }
    }`, undefined, {
      id: idHotsite
    });
  }

  public excluirGaleria(idGaleria: number): Observable<void> {
    return this.watchMutation(` 
    mutation excluiGaleria($id: Int!){
      excluiGaleria(id: $id) {
        ok
        msg
      }
    }`, undefined, {
      id: idGaleria
    });
  }

  public inserirHotsite(descricao: string, caminho: string, habilitado: boolean): Observable<void> {
    return this.watchMutation(`
    mutation insereHotsiteInfo($descHotsiteInfo: String!, $caminho: String!, $habilitado: Boolean!) {
      insereHotsiteInfo(descHotsiteInfo: $descHotsiteInfo, caminho: $caminho, habilitado: $habilitado) {
        ok
        msg
      }
    }`, undefined, {
      descHotsiteInfo: descricao,
      caminho: caminho,
      habilitado: habilitado
    });
  }

  public atualizarHotsite(id: string, descricao: string, caminho: string, habilitado: boolean): Observable<void> {
    return this.watchMutation(`
    mutation atualizaHotsiteInfo($id: Int!, $descHotsiteInfo: String!, $caminho: String!, $habilitado: Boolean!) {
      atualizaHotsiteInfo(id: $id, descHotsiteInfo: $descHotsiteInfo, caminho: $caminho, habilitado: $habilitado) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(id),
      descHotsiteInfo: descricao,
      caminho: caminho,
      habilitado: habilitado
    });
  }

  public atualizaSecoesHotsite(idHotsite: number, secoes: HotsiteSecao[]): Observable<void> {
    return this.watchMutation(`
    mutation atualizaHotsiteSecoes($id: Int!, $secoes: [HotsiteSecaoInput]!) {
      atualizaHotsiteSecoes(idHotsiteInfo: $id, secoes: $secoes) {
        ok
        msg
      }
    }`, undefined, {
      id: idHotsite,
      secoes: secoes
    });
  }

  public obtemGalerias(): Observable<Galeria[]> {
    return this.watchQuery(`{
      galeria {
        id
        titulo
        descricao
        foto {
          id
          url
        }
      }
    }`, "galeria");
  }

  public obtemGaleria(idGaleria): Observable<Galeria> {
    return this.watchQuery(`query galeria($idGaleria: Int!) {
      galeria(idGaleria: $idGaleria) {
        id
        titulo
        descricao
        foto {
          id
          url
        }
      }
    }`, "galeria[0]", {
      idGaleria: idGaleria
    });
  }

  public obtemColunistas(): Observable<Colunista[]> {
    return this.watchQuery(`{
      colunista {
        id
        statusSolicitacao
        foto {
          id
          url
        }
        nomePublico
        miniCurriculo
        pessoa {
          id
          nome
        }
        publicado
      }
     }`, "colunista")
  }

  public obtemColunista(idPessoa): Observable<Colunista> {
    return this.watchQuery(`
      query colunista($idPessoa: Int!) {
        colunista(idPessoa: $idPessoa) {
          id
          statusSolicitacao
          foto {
            id
            url
          }
          nomePublico
          miniCurriculo
          pessoa {
            id
            nome
          }
          publicado
        }
      }
    `, "colunista[0]", {
      idPessoa: idPessoa
    })
  }

  public atualizarColunista(idPessoa: string, nome: string, miniCurriculo: string): Observable<void> {
    return this.watchMutation(`mutation atualizaColunista($idPessoa: Int!, $nome: String!, $curriculo:String!) {
      atualizaColunista(idPessoa: $idPessoa, nome: $nome, curriculo: $curriculo) {
        ok
        msg
      }
    }`, undefined, {
      idPessoa: Number(idPessoa),
      nome: nome,
      curriculo: miniCurriculo
    })
  }

  public inserirColunista(idPessoa: string): Observable<Number> {
    return this.watchMutation(`mutation insereColunista($idPessoa: Int!) {
      insereColunista(idPessoa: $idPessoa) {
        ok
        id
        msg
      }
    }`, "id", {
      idPessoa: Number(idPessoa)
    })
  }

  public excluirColunista(idColunista: string): Observable<void> {
    return this.watchMutation(`mutation excluiColunista($idPessoa: Int!) {
      excluiColunista(idPessoa: $idPessoa) {
        ok
        msg
      }
    }`, undefined, {
      idPessoa: Number(idColunista)
    })
  }

  public uploadFile(file: File, funcao: string, id?: string): Observable<string> {
    const form = new FormData();
    form.append('imagem', file);
    const tipo = mime.extension(file.type);
    if (id) {
      return this.post(`upload/${funcao}/${tipo}/${id}`, form);
    }
    return this.post(`upload/${funcao}/${tipo}`, form);
  }

  public downloadFile(hash: string, tipo: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http.get(this.montarURL(`download/file/${hash}`), { responseType: 'arraybuffer' }).subscribe(
        data => {
          var blob = new Blob([data]);
          fileSaver.saveAs(blob, `${hash}.${tipo}`);
          resolve();
        }, error => {
          reject(error);
        }
      );
    })

  }

  public downloadFilePorUrl(url: string, nomeArquivo: string): void {
    this.http.get(url, { responseType: 'arraybuffer' }).subscribe(
      data => {
        var blob = new Blob([data]);
        fileSaver.saveAs(blob, nomeArquivo);
      }
    );
  }

  public inserirGaleria(titulo: string, descricao: string): Observable<string> {
    return this.watchMutation(`mutation insereGaleria($titulo: String!, $descricao: String) {
      insereGaleria(titulo: $titulo, descricao: $descricao) {
        ok
        id
        msg
      }
    }`, "id", {
      titulo: titulo,
      descricao: descricao
    });
  }

  public atualizarGaleria(id: string, titulo: string, descricao: string): Observable<string> {
    return this.watchMutation(`mutation atualizaGaleria($id: Int!, $titulo: String!, $descricao: String) {
      atualizaGaleria(id: $id, titulo: $titulo, descricao: $descricao) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(id),
      titulo: titulo,
      descricao: descricao
    });
  }


  public removerFotoGaleria(id: string): Observable<void> {
    return this.watchMutation(`mutation excluiFotoGaleria($id: Int!) {
      excluiFotoGaleria(id: $id) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(id)
    })
  }

  public obtemNoticias(): Observable<Noticia[]> {
    return this.watchQuery(`{
      noticia {
        id
        proprietario
        status
        dataNoticia
        titulo
        descricao
        arquivoFotoChamada {
          id
          url      
        }
        arquivoImagemNoticia {
          id
          url
        }
        texto
      }
     }`, "noticia");
  }

  public obtemNoticiasPublicadas(): Observable<Noticia[]> {
    return this.watchQuery(`{
      noticiaPublicada {
        id
        proprietario
        status
        dataNoticia
        titulo
        descricao
        arquivoFotoChamada {
          id
          url      
        }
        arquivoImagemNoticia {
          id
          url
        }
        texto
      }
     }`, "noticiaPublicada");
  }

  public obtemNoticia(idNoticia: number): Observable<Noticia> {
    return this.watchQuery(`query noticia($idNoticia: Int) {
      noticia(idNoticia: $idNoticia) {
        id
        proprietario
        status
        dataNoticia
        titulo
        descricao
        arquivoFotoChamada {
          id
          url      
        }
        arquivoImagemNoticia {
          id
          url
        }
        texto
      }
     }`, "noticia[0]", {
      idNoticia: idNoticia
    });
  }

  public obtemMenusLaterais(): Observable<MenuLateral[]> {
    return this.watchQuery(`{
      menuLateral {
        id
        descricao
        link
        arquivo {
          id
          url
        }
      }
    }`, "menuLateral");
  }

  public inserirMenuLateral(descricao: string, link: string): Observable<string> {
    return this.watchMutation(`mutation insereMenuLateral($descricao: String, $link: String) {
      insereMenuLateral(descricao: $descricao, link: $link) {
        id
        ok
        msg
      }
    }`, "id", {
      descricao: descricao,
      link: link
    })
  }

  public atualizarMenuLateral(id: string, descricao: string, link: string): Observable<string> {
    return this.watchMutation(`mutation atualizaMenuLateral($id: Int!, $descricao: String, $link: String) {
      atualizaMenuLateral(id:$id, descricao: $descricao, link: $link) {
        id
        ok
        msg
      }
    }`, undefined, {
      id: Number(id),
      descricao: descricao,
      link: link
    })
  }

  public excluirMenuLateral(id: string): Observable<void> {
    return this.watchMutation(`mutation excluiMenuLateral($id: Int!) {
      excluiMenuLateral(id:$id) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(id),
    })
  }

  public alterarStatusPublicacaoColunista(idPessoa: string, status: boolean): Observable<void> {
    return this.watchMutation(`mutation atualizaFgPublicadoColunista($idPessoa: Int!, $status: String!) {
      atualizaFgPublicadoColunista(idPessoa: $idPessoa, status: $status) {
        ok
        msg
      }
    }`, undefined, {
      idPessoa: Number(idPessoa),
      status: status ? 'S' : 'N'
    })
  }

  public obtemVideos(): Observable<Video[]> {
    return this.watchQuery(`{
      video {
        id
        titulo
        descricao
        dataInclusao
        urlYoutube
        thumbnailYoutube
        codigoYoutube
        publicado
        destaque
      }
     }`, "video")
  }

  public obtemEspacosAutor(): Observable<EspacoAutor[]> {
    return this.watchQuery(`{
      espacosAutor {
        id
        titulo
        genero
        editora
        palavrasChave
        ondeComprar
        numeroPaginas
        sinopse
        autoresPublicacao
        capa {
          id
          url
        }
        valorVenda
        comprarUrl
     }}`, "espacosAutor")
  }

  public obtemEspacoAutor(idLivro: string): Observable<EspacoAutor> {
    return this.watchQuery(`query espacosAutor($idLivro: Int) {
      espacosAutor(idLivro: $idLivro)  {
        id
        titulo
        genero
        editora
        palavrasChave
        ondeComprar
        numeroPaginas
        sinopse
        autoresPublicacao
        capa {
          id
          url
        }
        valorVenda
        comprarUrl
     }
    }`, "espacosAutor[0]", {
      idLivro: Number(idLivro)
    })
  }

  public excluirVideo(id: string): Observable<void> {
    return this.watchMutation(`
    mutation excluiVideo($id: Int!){
      excluiVideo(id: $id) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(id)
    });
  }

  public definirVideoDestaque(id: string): Observable<void> {
    return this.watchMutation(`mutation defineVideoDestaque($id: Int!){
      defineVideoDestaque(id: $id) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(id)
    })
  }

  public inserirVideo(titulo: string, descricao: string, urlYoutube: string, publicado: boolean): Observable<number> {
    return this.watchMutation(`mutation insereVideo($titulo: String!, $descricao: String, $urlYoutube: String!, $publicado: Boolean!) {
      insereVideo(titulo: $titulo, descricao: $descricao, urlYoutube: $urlYoutube, publicado: $publicado) {
        id
        ok
        msg
      }
    }`, "id", {
      titulo: titulo,
      descricao: descricao,
      urlYoutube: urlYoutube,
      publicado: publicado
    });
  }

  public atualizarVideo(id: string, titulo: string, descricao: string, urlYoutube: string, publicado: boolean): Observable<void> {
    return this.watchMutation(`mutation atualizaVideo($id: Int!, $titulo: String!, $descricao: String, $urlYoutube: String!, $publicado: Boolean!) {
      atualizaVideo(id: $id, titulo: $titulo, descricao: $descricao, urlYoutube: $urlYoutube, publicado: $publicado) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(id),
      titulo: titulo,
      descricao: descricao,
      urlYoutube: urlYoutube,
      publicado: publicado
    });
  }

  public obtemFotoRevista(): Observable<Arquivo> {
    return this.watchQuery(`{
      revistaConpedi {
        id
        url
      }
    }`, "revistaConpedi");
  }

  public obtemFotoEspacoAutor(): Observable<Arquivo> {
    return this.watchQuery(`{
      espacoAutor {
        id
        url
      }
    }`, "espacoAutor");
  }

  public obtemArquivosGaleria(): Observable<Arquivo[]> {
    return this.watchQuery(`{
      arquivosGaleria {
        id
        arquivo {
          id
          url
        }
        tipo
      }
    }`, "arquivosGaleria");
  }

  public obtemMapasPpgd(): Observable<MapaPpgd[]> {
    return this.watchQuery(`{
      mapaPpgd {
        id
        titulo
        sigla
        iesNomeCompleto
        iesSigla
        cidade
        uf
        apresentacao
        email
        telefone
        nomeCoordenador
        urlPrograma
        urlFacebook
        urlTwitter
        urlOutro
        fgAtivo
      }
    }`, "mapaPpgd");
  }


  public obtemMapaPpgd(idMapa: string): Observable<MapaPpgd> {
    return this.watchQuery(`query mapaPpgd ($idMapaPpgd: Int) {
      mapaPpgd(idMapaPpgd: $idMapaPpgd) {
        id
        titulo
        sigla
        iesNomeCompleto
        iesSigla
        cidade
        uf
        apresentacao
        email
        telefone
        nomeCoordenador
        urlPrograma
        urlFacebook
        urlTwitter
        urlOutro
        fgAtivo
      }
    }`, "mapaPpgd[0]", {
      idMapaPpgd: Number(idMapa)
    });
  }

  public inserirMapaPpgd(mapa: MapaPpgd): Observable<number> {
    return this.watchMutation(`mutation insereMapaPpgd($titulo: String, $sigla: String, $iesNomeCompleto: String, $iesSigla: String, $cidade: String, 
      $uf: String, $apresentacao: String, $email: String, $telefone: String, $nomeCoordenador: String, $urlPrograma: String, 
      $urlFacebook: String, $urlTwitter: String, $urlOutro: String, $fgAtivo: Boolean) {
        insereMapaPpgd(titulo: $titulo, sigla: $sigla, iesNomeCompleto: $iesNomeCompleto, iesSigla: $iesSigla, cidade: $cidade, 
          uf: $uf, apresentacao: $apresentacao, email: $email, telefone: $telefone, nomeCoordenador: $nomeCoordenador, urlPrograma: $urlPrograma, 
          urlFacebook: $urlFacebook, urlTwitter: $urlTwitter, urlOutro: $urlOutro, fgAtivo: $fgAtivo) {
            ok
            msg
            id
          }
      }`, "id", mapa);
  }

  public excluirMapaPpgd(idMapa: string): Observable<void> {
    return this.watchMutation(`mutation excluiMapaPpgd($id: Int!) {
      excluiMapaPpgd(id: $id) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(idMapa)
    })
  }

  public atualizarMapaPpgd(mapa: MapaPpgd): Observable<void> {
    const m = mapa as any;
    m.id = Number(m.id);
    return this.watchMutation(`mutation atualizaMapaPpgd($id: Int!, $titulo: String, $sigla: String, $iesNomeCompleto: String, $iesSigla: String, $cidade: String, 
      $uf: String, $apresentacao: String, $email: String, $telefone: String, $nomeCoordenador: String, $urlPrograma: String, 
      $urlFacebook: String, $urlTwitter: String, $urlOutro: String, $fgAtivo: Boolean) {
        atualizaMapaPpgd(id: $id, titulo: $titulo, sigla: $sigla, iesNomeCompleto: $iesNomeCompleto, iesSigla: $iesSigla, cidade: $cidade, 
          uf: $uf, apresentacao: $apresentacao, email: $email, telefone: $telefone, nomeCoordenador: $nomeCoordenador, urlPrograma: $urlPrograma, 
          urlFacebook: $urlFacebook, urlTwitter: $urlTwitter, urlOutro: $urlOutro, fgAtivo: $fgAtivo) {
            ok
            msg
          }
      }`, undefined, m);
  }

  public obtemAgendasPorMesAno(data: AgendaInput): Observable<Agenda[]> {
    return this.watchQuery(`query agenda($data: AgendaInput){
      agenda(data: $data) {
        id
        dataItem
        titulo
        descricao
        urlLabel
        urlDestino
        fgPublicado
      }
    }`, "agenda", {
      data: data
    })
  }

  public obtemAgendas(): Observable<Agenda[]> {
    return this.watchQuery(`{
      agenda {
        id
        dataItem
        titulo
        descricao
        urlLabel
        urlDestino
        fgPublicado
      }
    }`, "agenda")
  }

  public inserirAgenda(agenda: Agenda): Observable<string> {
    return this.watchMutation(`mutation insereAgenda($dataItem: String, $titulo: String, $descricao: String, $urlLabel: String, $urlDestino: String, $fgPublicado: Boolean) {
      insereAgenda(dataItem: $dataItem, titulo: $titulo, descricao: $descricao, urlLabel: $urlLabel, urlDestino: $urlDestino, fgPublicado: $fgPublicado) {
        ok
        id
        msg
      }
    }`, "id", agenda)
  }

  public atualizarAgenda(agenda: Agenda): Observable<void> {
    const ag = agenda as any;
    ag.id = Number(ag.id);
    return this.watchMutation(`mutation atualizaAgenda($id: Int!, $dataItem: String, $titulo: String, $descricao: String, $urlLabel: String, $urlDestino: String, $fgPublicado: Boolean) {
      atualizaAgenda(id: $id, dataItem: $dataItem, titulo: $titulo, descricao: $descricao, urlLabel: $urlLabel, urlDestino: $urlDestino, fgPublicado: $fgPublicado) {
        ok
        msg
      }
    }`, undefined, ag);
  }

  public excluirAgenda(agenda: Agenda): Observable<void> {
    return this.watchMutation(`mutation excluiAgenda($id: Int!) {
      excluiAgenda(id: $id) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(agenda.id)
    });
  }

  public excluirNoticia(idNoticia: string): Observable<void> {
    return this.watchMutation(`mutation excluiNoticia($id: Int!) {
      excluiNoticia(id: $id) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(idNoticia)
    });
  }

  public obtemEditais(secao?: SecaoEdital): Observable<Edital[]> {
    return this.watchQuery(`query edital($secao: SecaoEdital){
      edital(secao: $secao) {
        id
        pessoaAutor {
          id
          nome
        }
        arquivo {
          id
          nome
          url
        }
        autor
        secao
        descricaoEdital
        fgPublicado
        dtCadastro
        dataPrazo
      }
    }`, "edital", {
      secao: secao
    })
  }

  public inserirEdital(edital: Edital): Observable<number> {
    return this.watchMutation(`mutation insereEdital($idPessoaAutor: Int, $idArquivo: Int!, $autor: String, $secao: String, $descricaoEdital: String, $fgPublicado: Boolean, $dtCadastro: String, $dataPrazo: String){
      insereEdital(idPessoaAutor: $idPessoaAutor, idArquivo: $idArquivo, autor: $autor, secao: $secao, descricaoEdital: $descricaoEdital, fgPublicado: $fgPublicado, dtCadastro: $dtCadastro, dataPrazo: $dataPrazo) {
        id
        ok
        msg
      }
    }`, "id", {
      idPessoaAutor: Number(edital.pessoaAutor.id),
      idArquivo: Number(edital.arquivo.id),
      autor: edital.autor,
      secao: edital.secao,
      descricaoEdital: edital.descricaoEdital,
      fgPublicado: edital.fgPublicado,
      dtCadastro: edital.dtCadastro,
      dataPrazo: edital.dataPrazo
    })
  }

  public atualizarEdital(edital: Edital): Observable<void> {
    return this.watchMutation(`mutation atualizaEdital($id: Int!, $idPessoaAutor: Int, $idArquivo: Int!, $autor: String, $secao: String, $descricaoEdital: String, $fgPublicado: Boolean, $dtCadastro: String, $dataPrazo: String){
      atualizaEdital(id: $id, idPessoaAutor: $idPessoaAutor, idArquivo: $idArquivo, autor: $autor, secao: $secao, descricaoEdital: $descricaoEdital, fgPublicado: $fgPublicado, dtCadastro: $dtCadastro, dataPrazo: $dataPrazo) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(edital.id),
      idPessoaAutor: Number(edital.pessoaAutor.id),
      idArquivo: Number(edital.arquivo.id),
      autor: edital.autor,
      secao: edital.secao,
      descricaoEdital: edital.descricaoEdital,
      fgPublicado: edital.fgPublicado,
      dtCadastro: edital.dtCadastro,
      dataPrazo: edital.dataPrazo
    })
  }

  public excluirEdital(id: string): Observable<void> {
    return this.watchMutation(`mutation excluiEdital($id: Int!){
      excluiEdital(id: $id) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(id)
    })
  }

  public obtemPessoasPorNome(nome: string, limite: number): Observable<Pessoa[]> {
    return this.watchQuery(`query listaPessoasComNome($nome: String!, $limite: Int!) {
      listaPessoasComNome(nome: $nome, limite: $limite) {
        id
        nome
      }
     }`, "listaPessoasComNome", {
      nome: nome,
      limite: limite
    });
  }

  public obtemPessoa(id: string): Observable<PessoaFisica> {
    return this.watchQuery(`query pessoa($id: Int!) {
      pessoa(id: $id) {
        id
        nome
        email
        emailAlt
        telefone
        telefoneAlt
        cpf
        tipoDocumento
        sexo
        dataNascimento
        formacao
        urlCurriculoLattes
        instituicaoNome
        instituicaoSigla
        instituicaoUf
        nacionalidade
        instituicaoCadastrado
        outroDocumentoValor
        outroDocumentoTipo
        endereco {
          logradouro
          numero
          complemento
          bairro
          municipio
          cep
          uf
          pais
        }
      }
     }`, "pessoa", {
      id: Number(id)
    });
  }

  public atualizarPessoaFisica(p: PessoaFisica): Observable<void> {
    return this.watchMutation(`mutation atualizaDadosPessoaFisica($id: Int!, $nome: String!, $dataNascimento: String!, $sexo: String!, $nacionalidade: String!, $tipoDocumento: String!, $outroDocumentoTipo: String, $outroDocumentoValor: String, $cpf: String, $instituicaoCadastrado: Boolean!, $instituicaoNome: String, $instituicaoSigla: String, $instituicaoUf: String, $formacao: String!, $curriculoLattes:String, $email: String!, $emailAlt: String, $telefone: String!, $telefoneAlt: String, $logradouro: String!, $numero: String!, $complemento: String!, $bairro: String!, $municipio:String!, $uf: String!, $cep: String, $pais: String) {
      atualizaDadosPessoaFisica(id: $id, nome: $nome, dataNascimento: $dataNascimento, sexo: $sexo, nacionalidade: $nacionalidade, tipoDocumento: $tipoDocumento, outroDocumentoTipo: $outroDocumentoTipo, outroDocumentoValor: $outroDocumentoValor, cpf: $cpf, instituicaoCadastrado: $instituicaoCadastrado, instituicaoNome: $instituicaoNome, instituicaoSigla: $instituicaoSigla, instituicaoUf: $instituicaoUf, formacao: $formacao, curriculoLattes: $curriculoLattes, email: $email, emailAlt: $emailAlt, telefone: $telefone, telefoneAlt: $telefoneAlt, logradouro: $logradouro, numero: $numero, complemento: $complemento, bairro: $bairro, municipio: $municipio, uf: $uf, cep: $cep, pais: $pais) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(p.id),
      nome: p.nome,
      dataNascimento: p.dataNascimento,
      sexo: p.sexo,
      nacionalidade: p.nacionalidade,
      tipoDocumento: p.tipoDocumento,
      outroDocumentoTipo: p.outroDocumentoTipo,
      outroDocumentoValor: p.outroDocumentoValor,
      cpf: p.cpf,
      instituicaoCadastrado: p.instituicaoCadastrado,
      instituicaoNome: p.instituicaoNome,
      instituicaoSigla: p.instituicaoSigla,
      instituicaoUf: p.instituicaoUf,
      formacao: p.formacao,
      curriculoLattes: p.urlCurriculoLattes,
      email: p.email,
      emailAlt: p.emailAlt,
      telefone: p.telefone,
      telefoneAlt: p.telefoneAlt,
      logradouro: p.endereco.logradouro,
      numero: p.endereco.numero,
      complemento: p.endereco.complemento,
      bairro: p.endereco.bairro,
      municipio: p.endereco.municipio,
      uf: p.endereco.uf,
      cep: p.endereco.cep,
      pais: p.endereco.pais
    })
  }

  public inserirNoticia(noticia: Noticia): Observable<number> {
    return this.watchMutation(`mutation insereNoticia($titulo: String!, $proprietario: String!, $descricao: String!, $texto: String!, $status: StatusNoticia!, $dataNoticia: String!) {
      insereNoticia(titulo: $titulo, proprietario: $proprietario, descricao: $descricao, texto: $texto, status: $status, dataNoticia: $dataNoticia) {
        id
        ok
        msg
      }
    }`, "id", noticia);
  }

  public atualizarNoticia(noticia: Noticia): Observable<void> {
    return this.watchMutation(`mutation atualizaNoticia($id: Int!, $proprietario: String!, $titulo: String!, $descricao: String!, $texto: String!, $status: StatusNoticia!, $dataNoticia: String!) {
      atualizaNoticia(id: $id, proprietario: $proprietario, titulo: $titulo, descricao: $descricao, texto: $texto, status: $status, dataNoticia: $dataNoticia) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(noticia.id),
      titulo: noticia.titulo,
      proprietario: noticia.proprietario,
      descricao: noticia.descricao,
      texto: noticia.texto,
      status: noticia.status,
      dataNoticia: noticia.dataNoticia
    })
  }

  public ordenarNoticia(idNoticia: string, acima: boolean): Observable<Noticia[]> {
    return this.watchMutation(`mutation ordenaNoticia($idNoticia: Int!, $acima: Boolean!) {
      ordenaNoticia(idNoticia: $idNoticia, acima: $acima) {
        ok
        noticias {
          id
          proprietario
          status
          dataNoticia
          titulo
          descricao
          arquivoFotoChamada {
            id
            url
          }
          arquivoImagemNoticia {
            id
            url
          }
          texto
        }
      }
    }`, "noticias", {
      idNoticia: Number(idNoticia),
      acima: acima
    })
  }

  public ordenarGaleria(idGaleria: string, acima: boolean): Observable<Galeria[]> {
    return this.watchMutation(`mutation ordenaGaleria($id: Int!, $acima: Boolean!) {
      ordenaGaleria(id: $id, acima: $acima) {
        ok
        galerias {
          id
          titulo
          descricao
          foto {
            id
            url
          }
        }
      }
    }`, "galerias", {
      id: Number(idGaleria),
      acima: acima
    })
  }

  public atualizaAnuidadeAssociado(email1: string, email2: string, ano: number): Observable<boolean> {
    return this.watchMutation2(`mutation atualizaAnuidadeAssociado($email1: String, $email2: String, $ano: Int) {
      atualizaAnuidadeAssociado(email1: $email1, email2: $email2, ano: $ano) {
        id
        ok
        msg
      }
    }`, null, {
      email1: email1,
      email2: email2,
      ano: ano
    })
  }

  public obtemLinhaDePesquisaEnvento(idEvento: string, artigos: boolean, posters: boolean): Observable<LinhaPesquisa[]> {
    return this.watchQuery(`query linhaPesquisa($idEvento: Int!, $artigos: Boolean!, $posters: Boolean!) {
      linhaPesquisa(idEvento: $idEvento, artigos: $artigos, posters: $posters) {
        id
        descricao
        numeroGtp
        totalArtigos
     }
    }`, "linhaPesquisa", {
      idEvento: Number(idEvento),
      artigos,
      posters
    })
  }

  public atualizaNumeroGrupoTrabalhoPoster(idLinhaPesquisa: string, numeroGrupos: number): Observable<void> {
    return this.watchMutation(`mutation atualizaNumeroGrupoTrabalhoPoster($idLinhaPesquisa: Int!, $numeroGrupos: Int!){
      atualizaNumeroGrupoTrabalhoPoster(idLinhaPesquisa: $idLinhaPesquisa, numeroGrupos: $numeroGrupos) {
        ok
        msg
      }
    }`, undefined, {
      idLinhaPesquisa: Number(idLinhaPesquisa),
      numeroGrupos,
    })
  }

  public atualizaGrupoTrabalhoPoster(grupoTrabalhoPoster: GrupoTrabalhoPoster): Observable<void> {
    return this.watchMutation(`mutation atualizaGrupoTrabalhoPoster($idGrupoTrabalhoPoster: Int!, $tipoPublicacao: String!, $isbnIssn: String!, $idFileFichaCatalografica: Int, $linkApresentacao: String){
      atualizaGrupoTrabalhoPoster(idGrupoTrabalhoPoster: $idGrupoTrabalhoPoster, tipoPublicacao: $tipoPublicacao, isbnIssn: $isbnIssn, idFileFichaCatalografica: $idFileFichaCatalografica, linkApresentacao: $linkApresentacao) {
        ok
        msg
      }
    }`, undefined, {
      idGrupoTrabalhoPoster: Number(grupoTrabalhoPoster.id),
      tipoPublicacao: grupoTrabalhoPoster.tipoPublicacao,
      isbnIssn: grupoTrabalhoPoster.isbnIssn,
      idFileFichaCatalografica: grupoTrabalhoPoster.fichaCatalografica ? Number(grupoTrabalhoPoster.fichaCatalografica.id) : null,
      linkApresentacao: grupoTrabalhoPoster.linkApresentacao
    })
  }

  public atualizaTextoGrupoTrabalhoPoster(grupoTrabalhoPoster: GrupoTrabalhoPoster): Observable<void> {
    return this.watchMutation(`mutation atualizaTextoApresentacaoGrupoTrabalhoPoster($idGrupoTrabalhoPoster: Int!, $textoApresentacao: String!, $versaoFinalTextoApresentacao: Boolean!){
      atualizaTextoApresentacaoGrupoTrabalhoPoster(idGrupoTrabalhoPoster: $idGrupoTrabalhoPoster, textoApresentacao: $textoApresentacao, versaoFinalTextoApresentacao: $versaoFinalTextoApresentacao) {
        ok
        msg
      }
    }`, undefined, {
      idGrupoTrabalhoPoster: Number(grupoTrabalhoPoster.id),
      textoApresentacao: grupoTrabalhoPoster.textoApresentacao,
      versaoFinalTextoApresentacao: grupoTrabalhoPoster.versaoFinalTextoApresentacao
    })
  }

  public obtemTrabalho(idtrabalho: string): Observable<Trabalho> {
    return this.watchQuery(`query trabalho($id: Int) {
      trabalho(id: $id) {
        id
        titulo 
        tituloOutroIdioma
        resumo
        resumoOutroIdioma
        tipoTrabalho
        arquivo {
          id
          hash
          url
          nome
        }
        orientador {
          id
          nome
          email
          inscrito
          skPessoa
        }
        autor {
          id
          nome
          email
          inscrito
          skPessoa
        }
        linhaPesquisa {
          id
          descricao
          numeroGtp
        }
        grupoTrabalhoPoster {
          id
          numeroGrupo
          ativo      
          linhaPesquisa {
            id
            descricao
          }
        }
        grupoTrabalho {
          id
          numeroGrupo
          ativo      
          linhaPesquisa {
            id
            descricao
          }
        }
      }
    }`, "trabalho[0]", {
      id: Number(idtrabalho)
    })
  }


  public obtemtrabalhosLinhaDePesquisaPoster(idLinhaPesquisa: string): Observable<Trabalho[]> {
    return this.watchQuery(`query trabalho($idLinhaPesquisa: Int) {
      trabalho(idLinhaPesquisa: $idLinhaPesquisa, tipoTrabalho: POSTER) {
        id
        titulo
        grupoTrabalhoPoster {
          id
          numeroGrupo
          ativo
          linhaPesquisa {
            id
            descricao
          }
        }
        paginaInicial
        paginaFinal
      }
    }`, "trabalho", {
      idLinhaPesquisa: Number(idLinhaPesquisa)
    })
  }

  public obtemGrupoDeTrabalhoLinhaDePesquisa(idLinhaPesquisa: string): Observable<GrupoTrabalhoPoster[]> {
    return this.watchQuery(`query grupoTrabalhoPosterLinhaPesquisa($idLinhaPesquisa: Int) {
      grupoTrabalhoPosterLinhaPesquisa(idLinhaPesquisa: $idLinhaPesquisa) {
        id
        numeroGrupo
        ativo
        linhaPesquisa {
          id
          descricao
        }
        fichaCatalografica {
          id
          hash
          url
        }
        filePublicacao {
          id
          hash
          url
        }
        tipoPublicacao
        isbnIssn
        textoApresentacao
        versaoFinalTextoApresentacao
        dtTextoApresentacao
      }
    }`, "grupoTrabalhoPosterLinhaPesquisa", {
      idLinhaPesquisa: Number(idLinhaPesquisa)
    })
  }

  public obtemGrupoDeTrabalhoPosterEvento(idEvento: string): Observable<GrupoTrabalhoPoster[]> {
    return this.watchQuery(`query grupoTrabalhoPosterEvento($idEvento: Int) {
      grupoTrabalhoPosterEvento(idEvento: $idEvento) {
        id
        numeroGrupo
        ativo
        linhaPesquisa {
          id
          descricao
        }
        fichaCatalografica {
          id
          hash
          url
        }
        filePublicacao {
          id
          hash
          url
        }
        linkApresentacao
        tipoPublicacao
        isbnIssn
        textoApresentacao
        versaoFinalTextoApresentacao
        dtTextoApresentacao
      }
    }`, "grupoTrabalhoPosterEvento", {
      idEvento: Number(idEvento)
    })
  }

  public obtemGrupoDeTrabalhoEvento(idEvento: string): Observable<GrupoTrabalho[]> {
    return this.watchQuery(`query grupoTrabalhoEvento($idEvento: Int!) {
      grupoTrabalhoEvento(idEvento: $idEvento) {
        id
        numeroGrupo
        tipoPublicacao
        isbnIssn
        textoApresentacao
        versaoFinalTextoApresentacao
        dtTextoApresentacao
        linhaPesquisa {
          id
          descricao
        }
        fichaCatalografica {
          id
          hash
          url
        }
        linkApresentacao
      }
    }`, "grupoTrabalhoEvento", {
      idEvento: Number(idEvento)
    })
  }

  public obtemGrupoDeTrabalhoPoster(idGrupoTrabalhoPoster: string): Observable<GrupoTrabalhoPoster> {
    return this.watchQuery(`query grupoTrabalhoPoster($idGrupoTrabalhoPoster: Int) {
      grupoTrabalhoPoster(idGrupoTrabalhoPoster: $idGrupoTrabalhoPoster) {
        id
        numeroGrupo
        ativo
        linhaPesquisa {
          id
          descricao
        }
        fichaCatalografica {
          id
          hash
          url
        }
        filePublicacao {
          id
          hash
          url
        }
        linkApresentacao
        tipoPublicacao
        isbnIssn
        textoApresentacao
        versaoFinalTextoApresentacao
        dtTextoApresentacao
      }
    }`, "grupoTrabalhoPoster", {
      idGrupoTrabalhoPoster: Number(idGrupoTrabalhoPoster)
    })
  }

  public obtemGrupoDeTrabalho(idGrupoTrabalho: string): Observable<GrupoTrabalho> {
    return this.watchQuery(`query grupoTrabalho($idGrupoTrabalho: Int!) {
      grupoTrabalho(idGrupoTrabalho: $idGrupoTrabalho) {
        id
        numeroGrupo
        tipoPublicacao
        isbnIssn
        textoApresentacao
        versaoFinalTextoApresentacao
        dtTextoApresentacao
        linhaPesquisa {
          id
          descricao
        }
        fichaCatalografica {
          id
          hash
          url
        }
        linkApresentacao
      }
    }`, "grupoTrabalho", {
      idGrupoTrabalho: Number(idGrupoTrabalho)
    })
  }

  public obtemTrabalhosGrupoTrabalho(idGrupoTrabalho: string): Observable<Trabalho[]> {
    return this.watchQuery(`query trabalhosGrupoTrabalho($idGrupoTrabalho: Int!) {
      trabalhosGrupoTrabalho(idGrupoTrabalho: $idGrupoTrabalho) {
          id
          titulo
          grupoTrabalhoPoster {
            id
            numeroGrupo
            ativo
            linhaPesquisa {
              id
              descricao
            }
          }
          autor {
            id
            nome
          }
          orientador {
            id
            nome
          }
          paginaInicial
          paginaFinal
      }
    }`, "trabalhosGrupoTrabalho", {
      idGrupoTrabalho: Number(idGrupoTrabalho)
    })
  }

  public obtemTrabalhosGrupoTrabalhoPoster(idGrupoTrabalhoPoster: string): Observable<Trabalho[]> {
    return this.watchQuery(`query trabalhosGrupoTrabalhoPoster($idGrupoTrabalhoPoster: Int!) {
      trabalhosGrupoTrabalhoPoster(idGrupoTrabalhoPoster: $idGrupoTrabalhoPoster) {
          id
          titulo
          grupoTrabalhoPoster {
            id
            numeroGrupo
            ativo
            linhaPesquisa {
              id
              descricao
            }
          }
          autor {
            id
            nome
          }
          orientador {
            id
            nome
          }
          paginaInicial
          paginaFinal
      }
    }`, "trabalhosGrupoTrabalhoPoster", {
      idGrupoTrabalhoPoster: Number(idGrupoTrabalhoPoster)
    })
  }

  public atualizaGruposTrabalhoOrdem(ordemTrabalho: OrdemTrabalho[]): Observable<void> {
    return this.watchMutation(`mutation gravaGruposTrabalhoOrdem($info: [OrdemTrabalho]) {
      gravaGruposTrabalhoOrdem(info: $info) {
        ok
        msg
      } 
    }`, undefined, {
      info: ordemTrabalho
    })
  }

  public obtemEvento(idEvento: string): Observable<Evento> {
    return this.watchQuery(`query evento($id: Int) {
      evento(id: $id) {
        id
        titulo
        tema
        tipoEvento
        textoDataEvento
        skEvento
        recebePosters
        limitePalavrasResumoPoster
        autoresMinimoPoster
        autoresMaximoPoster
        limitePalavrasResumoArtigo
        autoresMinimoArtigo
        autoresMaximoArtigo
        paginaArtigoMin
        paginaArtigoMax
        paginaPosterMin
        paginaPosterMax
        linkEventoLiberado
        publicacaoLiberadaArtigo
        publicacaoLiberadaPoster
        hotsite {
          id
          descricao
          caminho
          secao {
            id
            ordem
            titulo
            conteudo
          }
          imagemInicial {
            id
            url
          }
          imagemHotsite {
            id
            url
          }
        }
      }
    }`, "evento[0]", {
      id: Number(idEvento)
    });
  }

  public obtemInscricoesEvento(idEvento: string): Observable<InscricaoEvento[]> {
    return this.watchQuery(`query inscricoesEvento($idEvento: Int!) {
      inscricoesEvento(idEvento: $idEvento) {
        tipo
        valores {
          data
          valor
        }
      }
    }`, "inscricoesEvento", {
      idEvento: Number(idEvento)
    })
  }

  public obtemEventoLinhaPesquisa(idLinhaPesquisa: string): Observable<Evento> {
    return this.watchQuery(`query obtemEventoLinhaPesquisa($idLinhaPesquisa: Int!) {
      obtemEventoLinhaPesquisa(idLinhaPesquisa: $idLinhaPesquisa) {
        id
        titulo
        tema
        tipoEvento
        textoDataEvento
      }
    }`, "obtemEventoLinhaPesquisa", {
      idLinhaPesquisa: Number(idLinhaPesquisa)
    });
  }

  public obtemQuantidadeTrabalhosLinhaPesquisa(idLinhaPesquisa: string): Observable<number> {
    return this.watchQuery(`query obtemQuantidadeTrabalhosLinhaPesquisa($idLinhaPesquisa: Int!) {
      obtemQuantidadeTrabalhosLinhaPesquisa(idLinhaPesquisa: $idLinhaPesquisa)
    }`, "obtemQuantidadeTrabalhosLinhaPesquisa", {
      idLinhaPesquisa: Number(idLinhaPesquisa)
    })
  }

  public obtemTrabalhosEventoPoster(idEvento: string): Observable<Trabalho[]> {
    return this.watchQuery(`query trabalho($idEvento: Int!){
      trabalho(idEvento:$idEvento, tipoTrabalho:POSTER) {
        id
        titulo 
        tipoTrabalho
        orientador {
          id
          nome
          email
          inscrito
          skPessoa
        }
        autor {
          id
          nome
          email
          inscrito
          skPessoa
        }
        linhaPesquisa {
          id
          descricao
          numeroGtp
        }
        grupoTrabalhoPoster {
          id
          numeroGrupo
          ativo      
          linhaPesquisa {
            id
            descricao
          }
        }
      }
     }`, "trabalho", {
      idEvento: Number(idEvento)
    })
  }

  public obtemTrabalhosEventoArtigo(idEvento: string): Observable<Trabalho[]> {
    return this.watchQuery(`query trabalho($idEvento: Int!){
      trabalho(idEvento:$idEvento, tipoTrabalho:ARTIGO) {
        id
        titulo 
        tipoTrabalho
        orientador {
          id
          nome
          email
          inscrito
          skPessoa
        }
        autor {
          id
          nome
          email
          inscrito
          skPessoa
        }
        linhaPesquisa {
          id
          descricao
          numeroGtp
        }
        grupoTrabalhoPoster {
          id
          numeroGrupo
          ativo      
          linhaPesquisa {
            id
            descricao
          }
        }
      }
     }`, "trabalho", {
      idEvento: Number(idEvento)
    })
  }

  public obtemCoordenadores(idGrupoTrabalhoPoster: string): Observable<Pessoa[]> {
    return this.watchQuery(`query coordenador($idGrupoTrabalhoPoster: Int!) {
      coordenador(idGrupoTrabalhoPoster: $idGrupoTrabalhoPoster) {
        id
        nome
      }
    }`, "coordenador", {
      idGrupoTrabalhoPoster: Number(idGrupoTrabalhoPoster)
    })
  }

  public insereCoordenador(idGrupoTrabalhoPoster: string, idPessoa: string): Observable<void> {
    return this.watchMutation(`mutation insereCoordenador($idGrupoTrabalhoPoster: Int!, $idPessoa: Int!) {
      insereCoordenador(idGrupoTrabalhoPoster: $idGrupoTrabalhoPoster, idPessoa: $idPessoa) {
        ok
        msg
      }
    }`, undefined, {
      idGrupoTrabalhoPoster: Number(idGrupoTrabalhoPoster),
      idPessoa: Number(idPessoa)
    })
  }

  public excluiCoordenador(idGrupoTrabalhoPoster: string, idPessoa: string): Observable<void> {
    return this.watchMutation(`mutation excluiCoordenador($idGrupoTrabalhoPoster: Int!, $idPessoa: Int!) {
      excluiCoordenador(idGrupoTrabalhoPoster: $idGrupoTrabalhoPoster, idPessoa: $idPessoa) {
        ok
        msg
      }
    }`, undefined, {
      idGrupoTrabalhoPoster: Number(idGrupoTrabalhoPoster),
      idPessoa: Number(idPessoa)
    })
  }

  public contato(dados: Contato): Observable<void> {
    return this.watchMutation(`mutation contato($nome: String!, $email: String!, $area: String!, $telefone: String, $mensagem: String!) {
      contato(nome: $nome, email: $email, area: $area, telefone: $telefone, mensagem: $mensagem) {
        ok
        msg
      }
    }`, undefined, dados)
  }

  public obtemMemoria(): Observable<string> {
    return this.watchQuery(`{
      memoria
    }`, "memoria")
  }

  public atualizaMemoria(descricao: string): Observable<void> {
    return this.watchMutation(`mutation atualizaDescricaoMemoria($descricao: String!) {
      atualizaDescricaoMemoria  (descricao: $descricao) {
        ok
        msg
      }
    }`, undefined, {
      descricao: descricao
    })
  }

  public obtemDescricaoFaleConosco(): Observable<string> {
    return this.watchQuery(`{
      descricaoFaleConosco
    }`, "descricaoFaleConosco")
  }

  public atualizaDescricaoFaleConosco(descricao: string, idPessoa: string): Observable<void> {
    return this.watchMutation(`mutation atualizaDescricaoFaleConosco($descricao: String!, $idPessoa: Int!) {
      atualizaDescricaoFaleConosco(descricao: $descricao, idPessoa: $idPessoa) {
        ok
        msg
      }
    }`, undefined, {
      idPessoa: Number(idPessoa),
      descricao: descricao
    })
  }

  public obtemQuemSomos(): Observable<QuemSomos> {
    return this.watchQuery(`{
      quemSomos {
        sobre {
          descricao
          imagem {
            id
            url
          }
        }
        missao {
          descricao
          imagem {
            id
            url
          }
        }
        valores {
          descricao
          imagem {
            id
            url
          }
        }
      }
    }`, "quemSomos")
  }

  public atualizaDescricaoSobre(descricao: string): Observable<void> {
    return this.watchMutation(`mutation atualizaDescricaoSobre($descricao: String!) {
      atualizaDescricaoSobre(descricao: $descricao) {
        ok
        msg
      }
    }`, undefined, {
      descricao: descricao
    })
  }

  public atualizaDescricaoDocumento(descricao: string): Observable<void> {
    return this.watchMutation(`mutation atualizaDescricaoDocumento($descricao: String!) {
      atualizaDescricaoDocumento(descricao: $descricao) {
        ok
        msg
      }
    }`, undefined, {
      descricao: descricao
    })
  }

  public atualizaDescricaoMissao(descricao: string): Observable<void> {
    return this.watchMutation(`mutation atualizaDescricaoMissao($descricao: String!) {
      atualizaDescricaoMissao(descricao: $descricao) {
        ok
        msg
      }
    }`, undefined, {
      descricao: descricao
    })
  }

  public atualizaDescricaoValores(descricao: string): Observable<void> {
    return this.watchMutation(`mutation atualizaDescricaoValores($descricao: String!) {
      atualizaDescricaoValores(descricao: $descricao) {
        ok
        msg
      }
    }`, undefined, {
      descricao: descricao
    })
  }

  public geraPdfPublicacaoGrupoTrabalhoPoster(idGrupoTrabalhoPoster: string): Observable<string> {
    return this.watchMutation(`mutation geraPdfPublicacaoGrupoTrabalhoPoster($idGrupoTrabalhoPoster: Int!) {
      geraPdfPublicacaoGrupoTrabalhoPoster(idGrupoTrabalhoPoster: $idGrupoTrabalhoPoster) {
        ok
        url
        msg
      }
    }`, "url", {
      idGrupoTrabalhoPoster: Number(idGrupoTrabalhoPoster)
    })
  }

  public geraPdfPublicacao(idTrabalho: string): Observable<string> {
    return this.watchMutation(`mutation geraPdfPublicacao($idTrabalho: Int!) {
      geraPdfPublicacao(idTrabalho: $idTrabalho) {
        ok
        url
        msg
      }
    }`, "url", {
      idTrabalho: Number(idTrabalho)
    })
  }

  public listaTrabalhos(idEvento: string, idLinhaPesquisa: string, tipoTrabalho: TipoTrabalho, titulo: string, aprovadoParaEvento: FiltroAprovacaoTrabalho, autores: FiltroAutor,
    ordenarPor: OrdenacaoTrabalho): Observable<Trabalho[]> {
    return this.watchQuery(`query trabalho($idEvento: Int, $idLinhaPesquisa: Int, $tipoTrabalho: TipoTrabalho, $titulo: String, $aprovadoParaEvento: FiltroAprovacaoTrabalho, $autores: FiltroAutor, $ordenarPor: OrdenacaoTrabalho) {
        trabalho(idEvento: $idEvento, idLinhaPesquisa: $idLinhaPesquisa, tipoTrabalho: $tipoTrabalho, titulo: $titulo, aprovadoParaEvento: $aprovadoParaEvento, autores: $autores, ordenarPor: $ordenarPor) {
          id    
          titulo
          skTrabalho
          linhaPesquisa {
            id
            descricao
          }
          tipoTrabalho
          autor {
            id
            nome
            skPessoa
            confirmacaoAutoria
          }
          orientador {
            id
            nome
            skPessoa
          }
          dataHoraRecebimento
          analiseEditorial
          nota
        }
      }`, "trabalho", {
      idEvento: Number(idEvento),
      idLinhaPesquisa: Number(idLinhaPesquisa),
      tipoTrabalho: tipoTrabalho,
      titulo: titulo,
      aprovadoParaEvento: aprovadoParaEvento,
      autores: autores,
      ordenarPor: ordenarPor
    });
  }

  public trabalhoPessoaAutorOrientador(idPessoa: string, idEvento: string): Observable<Trabalho[]> {
    return this.watchQuery(`query trabalhoPessoaAutorOrientador($idPessoa: Int!, $idEvento: Int!) {
      trabalhoPessoaAutorOrientador(idPessoa: $idPessoa, idEvento: $idEvento) {
        id
        tipoTrabalho
        grupoTrabalhoPoster {
          id
          linhaPesquisa {
            id
            descricao
          }
          numeroGrupo
          linkApresentacao
        }
        titulo
        orientador {
          id
          nome
          skPessoa
        }
        grupoTrabalho {
          id
          linhaPesquisa {
            id
            descricao
          }
          numeroGrupo
          linkApresentacao
        }
      }
    }`, "trabalhoPessoaAutorOrientador", {
      idPessoa: Number(idPessoa),
      idEvento: Number(idEvento)
    })
  }

  public atualizaGrupoTrabalho(grupoTrabalho: GrupoTrabalho): Observable<void> {
    return this.watchMutation(`mutation atualizaGrupoTrabalho($idGrupoTrabalho: Int!, $linkApresentacao: String) {
      atualizaGrupoTrabalho(idGrupoTrabalho: $idGrupoTrabalho, linkApresentacao: $linkApresentacao) {
        ok
        msg
      }
    }`, undefined, {
      idGrupoTrabalho: Number(grupoTrabalho.id),
      linkApresentacao: grupoTrabalho.linkApresentacao
    })
  }

  public obtemPaineis(idEvento: string): Observable<Painel[]> {
    return this.watchQuery(`query paineis($idEvento: Int!){
      paineis(idEvento: $idEvento) {
        id
        tipo
        titulo
        link
        data
      }
    }`, "paineis", {
      idEvento: Number(idEvento)
    })
  }

  public obtemPainel(idPainel: string): Observable<Painel> {
    return this.watchQuery(`query painel($idPainel: Int!){
      painel(idPainel: $idPainel) {
        id
        tipo
        titulo
        link
        data
      }
    }`, "painel", {
      idPainel: Number(idPainel)
    })
  }

  public obtemGrupoTrabalhoPosterCoordenador(idPessoa: string, idEvento: string): Observable<GrupoTrabalhoPoster[]> {
    return this.watchQuery(`query grupoTrabalhoPosterCoordenador($idPessoa: Int!, $idEvento: Int!) {
      grupoTrabalhoPosterCoordenador(idPessoa:$idPessoa, idEvento:$idEvento) {
        id
        numeroGrupo
        tipoPublicacao
        isbnIssn
        textoApresentacao
        versaoFinalTextoApresentacao
        dtTextoApresentacao
        linhaPesquisa {
          id
          descricao
        }
        fichaCatalografica {
          id
          hash
          url
        }
        linkApresentacao
      }
    }`, "grupoTrabalhoPosterCoordenador", {
      idPessoa: Number(idPessoa),
      idEvento: Number(idEvento)
    })
  }

  public obtemGrupoTrabalhoCoordenador(idPessoa: string, idEvento: string): Observable<GrupoTrabalho[]> {
    return this.watchQuery(`query grupoTrabalhoCoordenador($idPessoa: Int!, $idEvento: Int!) {
      grupoTrabalhoCoordenador(idPessoa: $idPessoa, idEvento: $idEvento) {
        id
        numeroGrupo
        tipoPublicacao
        isbnIssn
        textoApresentacao
        versaoFinalTextoApresentacao
        dtTextoApresentacao
        linhaPesquisa {
          id
          descricao
        }
        fichaCatalografica {
          id
          hash
          url
        }
        linkApresentacao
      }
    }`, "grupoTrabalhoCoordenador", {
      idPessoa: Number(idPessoa),
      idEvento: Number(idEvento)
    })
  }

  public excluirPainel(idPainel: string): Observable<void> {
    return this.watchMutation(`mutation excluiPainel($id: Int!) {
      excluiPainel(id: $id) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(idPainel)
    })
  }

  public inserirPainel(painel: Painel): Observable<number> {
    return this.watchMutation(`mutation inserePainel($idEvento: Int!, $tipo: String!, $titulo: String!, $link: String!, $data: String!){
      inserePainel(idEvento: $idEvento, tipo: $tipo, titulo: $titulo, link: $link, data: $data) {
        ok
        id
        msg
      }
    }`, "id", {
      idEvento: Number(painel.evento.id),
      tipo: painel.tipo,
      titulo: painel.titulo,
      link: painel.link,
      data: painel.data
    })
  }


  public atualizarPainel(painel: Painel): Observable<number> {
    return this.watchMutation(`mutation atualizaPainel($idPainel: Int!, $tipo: String!, $titulo: String!, $link: String!, $data: String!){
      atualizaPainel(idPainel: $idPainel, tipo: $tipo, titulo: $titulo, link: $link, data: $data) {
        ok
        msg
      }
    }`, undefined, {
      idPainel: Number(painel.id),
      tipo: painel.tipo,
      titulo: painel.titulo,
      link: painel.link,
      data: painel.data
    })
  }

  public obtemDiretoriaGrupos(): Observable<DiretoriaGrupo[]> {
    return this.watchQuery(`{
      diretoriaGrupos {
        id
        nomeGrupo
        diretores {
          id
          foto {
            id
            url
          }
          titulo
          qualificacao
          nomeCompleto
          instituicao
          urlLattes
          rodape
          ordem
          ativo
        }
        ordem
        ativo
      }
    }`, "diretoriaGrupos");
  }

  public obtemDiretoriaGrupo(id: string): Observable<DiretoriaGrupo> {
    return this.watchQuery(`query diretoriaGrupo($idDiretoriaGrupo: Int!){
      diretoriaGrupo(idDiretoriaGrupo: $idDiretoriaGrupo) {
        id
        nomeGrupo
        diretores {
          id
          foto {
            id
            url
          }
          titulo
          qualificacao
          nomeCompleto
          instituicao
          urlLattes
          rodape
          ordem
          ativo
        }
        ordem
        ativo
      }
    }`, "diretoriaGrupo", {
      idDiretoriaGrupo: Number(id)
    });
  }

  public insereDiretoriaGrupo(nome: string): Observable<number> {
    return this.watchMutation(`mutation insereDiretoriaGrupo($nome: String!){
      insereDiretoriaGrupo(nome: $nome) {
        ok
        id
        msg
      }
    }`, "id", {
      nome: nome
    })
  }


  public atualizaDiretoriaGrupo(id: string, nome: string): Observable<number> {
    return this.watchMutation(`mutation atualizaDiretoriaGrupo($id: Int!, $nome: String!){
      atualizaDiretoriaGrupo(id: $id, nome: $nome) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(id),
      nome: nome
    })
  }

  public excluiDiretoriaGrupo(id: string): Observable<void> {
    return this.watchMutation(`mutation excluiDiretoriaGrupo($id: Int!) {
      excluiDiretoriaGrupo(id: $id) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(id)
    })
  }

  public obtemDiretor(id: string): Observable<Diretor> {
    return this.watchQuery(`query diretor($id: Int!){
      diretor(id: $id) {
        id
        foto {
          id
          url
        }
        diretoriaGrupo {
          id
        }
        titulo
        qualificacao
        nomeCompleto
        instituicao
        urlLattes
        rodape
        ordem
        ativo
      }
    }`, "diretor", {
      id: Number(id)
    });
  }

  public insereDiretor(diretor: Diretor, idDiretoriaGrupo: string): Observable<number> {
    return this.watchMutation(`mutation insereDiretor($idDiretoriaGrupo: Int!, $ordem: Int!, $titulo: String!, $qualificacao: String!, $nomeCompleto: String!, $instituicao: String!, $urlLattes: String!, $rodape: String){
      insereDiretor(idDiretoriaGrupo: $idDiretoriaGrupo, ordem: $ordem, titulo: $titulo, qualificacao: $qualificacao, nomeCompleto: $nomeCompleto, instituicao: $instituicao, urlLattes: $urlLattes, rodape: $rodape) {
        ok
        id
        msg
      }
    }`, "id", {
      idDiretoriaGrupo: Number(idDiretoriaGrupo),
      ordem: diretor.ordem,
      titulo: diretor.titulo,
      qualificacao: diretor.qualificacao,
      nomeCompleto: diretor.nomeCompleto,
      instituicao: diretor.instituicao,
      urlLattes: diretor.urlLattes,
      rodape: diretor.rodape
    })
  }

  public atualizaDiretor(diretor: Diretor): Observable<number> {
    return this.watchMutation(`mutation atualizaDiretor($id: Int!, $ordem: Int!, $titulo: String!, $qualificacao: String!, $nomeCompleto: String!, $instituicao: String!, $urlLattes: String!, $rodape: String){
      atualizaDiretor(id: $id, ordem: $ordem, titulo: $titulo, qualificacao: $qualificacao, nomeCompleto: $nomeCompleto, instituicao: $instituicao, urlLattes: $urlLattes, rodape: $rodape) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(diretor.id),
      ordem: diretor.ordem,
      titulo: diretor.titulo,
      qualificacao: diretor.qualificacao,
      nomeCompleto: diretor.nomeCompleto,
      instituicao: diretor.instituicao,
      urlLattes: diretor.urlLattes,
      rodape: diretor.rodape
    })
  }

  public excluiDiretor(id: string): Observable<void> {
    return this.watchMutation(`mutation excluiDiretor($id: Int!) {
      excluiDiretor(id: $id) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(id)
    })
  }

  public obtemBanners(): Observable<Banner[]> {
    return this.watchQuery(`{
      banner {
        id
        link
        imagem {
          id
          nome
          url
        }
      }
    }`, "banner");
  }

  public insereBanner(idFile: string, link: string): Observable<number> {
    return this.watchMutation(`mutation insereBanner($idFile: Int!, $link: String!){
      insereBanner(idFile: $idFile, link: $link) {
        ok
        id
        msg
      }
    }`, "id", {
      idFile: Number(idFile),
      link: link
    })
  }

  public atualizaBanner(b: Banner): Observable<number> {
    return this.watchMutation(`mutation atualizaBanner($id: Int!, $idFile: Int!, $link: String!){
      atualizaBanner(id: $id, idFile: $idFile, link: $link) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(b.id),
      link: b.link,
      idFile: Number(b.imagem.id)
    })
  }

  public excluiBanner(id: string): Observable<void> {
    return this.watchMutation(`mutation excluiBanner($id: Int!) {
      excluiBanner(id: $id) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(id)
    })
  }

  public obtemTextoIntrodutorioDocumeto(): Observable<string> {
    return this.watchQuery(`{
      textoIntrodutorioDocumeto
    }`, "textoIntrodutorioDocumeto")
  }

  public obtemDocumentos(): Observable<Documento[]> {
    return this.watchQuery(`{
      documento {
        id
        descDocumento
        grupo
        publicado
        arquivo {
          id
          nome
          url
        }
      }
    }`, "documento");
  }

  public obtemDocumento(id: String): Observable<Documento> {
    return this.watchQuery(`query documento($id: Int){
      documento(id: $id) {
        id
        descDocumento
        grupo
        publicado
        arquivo {
          id
          nome
          url
        }
      }
    }`, "documento[0]", {
      id: Number(id)
    });
  }

  public insereDocumento(d: Documento): Observable<number> {
    return this.watchMutation(`mutation insereDocumento($descDocumento: String!, $grupo: GrupoDocumento!, $idFile: Int!, $publicado: Boolean!){
      insereDocumento(descDocumento: $descDocumento, grupo: $grupo, idFile: $idFile, publicado: $publicado) {
        ok
        id
        msg
      }
    }`, "id", {
      descDocumento: d.descDocumento,
      grupo: d.grupo,
      idFile: Number(d.arquivo.id),
      publicado: d.publicado
    })
  }

  public atualizaDocumento(d: Documento): Observable<number> {
    return this.watchMutation(`mutation atualizaDocumento($id: Int!, $descDocumento: String!, $grupo: GrupoDocumento!, $idFile: Int!, $publicado: Boolean!){
      atualizaDocumento(id: $id, descDocumento: $descDocumento, grupo: $grupo, idFile: $idFile, publicado: $publicado) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(d.id),
      descDocumento: d.descDocumento,
      grupo: d.grupo,
      idFile: Number(d.arquivo.id),
      publicado: d.publicado
    })
  }

  public excluiDocumento(id: string): Observable<void> {
    return this.watchMutation(`mutation excluiDocumento($id: Int!) {
      excluiDocumento(id: $id) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(id)
    })
  }

  public atualizaEvento(id: string, idHotsite: string, linkEventoLiberado: boolean, publicacaoLiberadaPoster: boolean, publicacaoLiberadaArtigo: boolean,): Observable<void> {
    return this.watchMutation(`
    mutation atualizaEvento($id: Int!, $idHotsite: Int!, $linkEventoLiberado: Boolean!, $publicacaoLiberadaPoster: Boolean!, $publicacaoLiberadaArtigo: Boolean!) {
      atualizaEvento(id: $id, idHotsite: $idHotsite, linkEventoLiberado: $linkEventoLiberado, publicacaoLiberadaPoster: $publicacaoLiberadaPoster, publicacaoLiberadaArtigo: $publicacaoLiberadaArtigo) {
        ok
        msg
      }
    }`, undefined, {
      id: Number(id),
      idHotsite: Number(idHotsite),
      linkEventoLiberado: linkEventoLiberado,
      publicacaoLiberadaPoster: publicacaoLiberadaPoster,
      publicacaoLiberadaArtigo: publicacaoLiberadaArtigo
    });
  }

  public defineFormacoesExigidasEvento(idEvento: string, tipoTrabalho: string, titulos: TituloEventoInput): Observable<void> {
    return this.watchMutation(`mutation defineFormacoesExigidasEvento($idEvento: Int!, $tipoTrabalho: String!, $titulos: [TituloEventoInput]!) {
      defineFormacoesExigidasEvento(idEvento: $idEvento, tipoTrabalho: $tipoTrabalho, titulos: $titulos) {
        ok
        msg
      }
    }`, undefined, {
      idEvento: Number(idEvento),
      tipoTrabalho: tipoTrabalho,
      titulos: titulos
    })
  }

  public obtemEventoFormacaoExigidaAutor(idEvento: string, tipoTrabalho: string): Observable<EventoFormacaoExigidaAutor[]> {
    return this.watchQuery(`query eventoFormacaoExigidaAutor($idEvento: Int!, $tipoTrabalho: String!){
      eventoFormacaoExigidaAutor(idEvento: $idEvento, tipoTrabalho: $tipoTrabalho) {
        id
        formacao
        eventoFormacaoExigida {
          id
          formacaoExigida
        }
      }
     }`, "eventoFormacaoExigidaAutor", {
      idEvento: Number(idEvento),
      tipoTrabalho: tipoTrabalho
    });
  }


  public obtemEventoValorInscricao(idEvento: string): Observable<EventoValorInscricao[]> {
    return this.watchQuery(`query eventoValorInscricao($idEvento: Int!){
      eventoValorInscricao(idEvento: $idEvento) {
        idEvento
        dataLimite
        tipoInscricao
        valor
        tipoAssociado
        disponivel
      }
     }`, "eventoValorInscricao", {
      idEvento: Number(idEvento),
    });
  }

  public insereEventoValorInscricao(idEvento: string, dataLimite: string, tipoInscricao: string, valor: number, tipoAssociado: string): Observable<void> {
    return this.watchMutation(`mutation insereEventoValorInscricao($idEvento: Int!, $dataLimite: String!, $tipoInscricao: String!, $valor: Float!, $tipoAssociado: String) {
      insereEventoValorInscricao(idEvento: $idEvento, dataLimite: $dataLimite, tipoInscricao: $tipoInscricao, valor: $valor, tipoAssociado:$tipoAssociado) {
        ok
        msg
      }
    }`, undefined, {
      idEvento: Number(idEvento),
      dataLimite: dataLimite,
      tipoInscricao: tipoInscricao,
      valor: valor,
      tipoAssociado: tipoAssociado
    })
  }

  public excluiEventoValorInscricao(idEvento: string, dataLimite: string, tipoInscricao: string): Observable<void> {
    return this.watchMutation(`mutation excluiEventoValorInscricao($idEvento: Int!, $dataLimite: String!, $tipoInscricao: String!) {
      excluiEventoValorInscricao(idEvento: $idEvento, dataLimite: $dataLimite, tipoInscricao: $tipoInscricao) {
        ok
        msg
      }
    }`, undefined, {
      idEvento: Number(idEvento),
      dataLimite: dataLimite,
      tipoInscricao: tipoInscricao,
    })
  }

  public query<T>(query: string, params: any): Promise<T> {
    const obs = new Observable(
      o => {
        this.apollo.query({
          query: gql(query),
          variables: params
        }).subscribe(
          res => {
            const dados = res.data[Object.keys(res.data)[0]] as any;
            o.next(dados as any);
          }, error => {
            o.error(error);
            o.complete();
          }
        )
      }
    )
    return new Promise<T>((resolve, reject) => {
      obs.subscribe((data: T) => {
        resolve(data)
      })
    })
  }
}
