import { Component, OnInit } from '@angular/core';
import { GraphqlService, Evento } from '../../service/graphql/graphql.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-evento',
  templateUrl: './evento.component.html',
  styleUrls: ['./evento.component.css']
})
export class EventoComponent implements OnInit {

  public eventos: Evento[];
  public cols: any[];

  constructor(private graphqlService: GraphqlService, private router: Router) {
    this.graphqlService.obtemEventos().subscribe(
      eventos => {
        this.eventos = eventos;
      }
    );
  }

  ngOnInit() {
    this.cols = [
      { field: 'titulo', header: 'Titulo' },
      { field: 'tema', header: 'Tema' },
      { field: 'tipoEvento', header: 'Tipo' },
    ];
  }

  editaHotsite(data) {
    alert(JSON.stringify(data));
    this.router.navigateByUrl('/');
  }

}
