import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-botao-direita-crud',
  templateUrl: './botao-direita-crud.component.html',
  styleUrls: ['./botao-direita-crud.component.css']
})
export class BotaoDireitaCrudComponent implements OnInit {
  
  @Input() path: string;
  @Input() label: string;

  constructor() { }

  ngOnInit() {
  }

}
