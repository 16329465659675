import { Component, OnInit } from '@angular/core';
import { MapaService } from 'src/app/service/mapa/mapa.service';

@Component({
  selector: 'app-inicial-mapa-pddg',
  templateUrl: './inicial-mapa-pddg.component.html',
  styleUrls: ['./inicial-mapa-pddg.component.css']
})
export class InicialMapaPddgComponent implements OnInit {

  constructor(private mapaService: MapaService) { }

  ngOnInit() {
  }

  mostraMapa() {
    this.mapaService.mostraMapa();

  }

}
