import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AutenticacaoService } from 'src/app/service/autenticacao/autenticacao.service';

@Directive({
  selector: '[roles]'
})
export class RoleDirective implements OnInit {

  @Input() roles: string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private userService: AutenticacaoService,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit() {
    this.userService.bsAutenticacao.subscribe(
      (u) => {
        if (u && this.roles.some(r => u.roles.includes(r))) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      } 
    );
  }
}
