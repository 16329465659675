import { Component, OnInit, Input } from '@angular/core';
import { DiretoriaGrupo, Diretor } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-diretoria-grupo',
  templateUrl: './diretoria-grupo.component.html',
  styleUrls: ['./diretoria-grupo.component.css']
})
export class DiretoriaGrupoComponent implements OnInit {

  @Input() diretoriaGrupo: DiretoriaGrupo;
  public diretoresOrdenados: Diretor[];

  constructor() { }

  ngOnInit() {
    this.diretoresOrdenados = this.diretoriaGrupo.diretores.sort(
      (a, b) => {
        return a.ordem - b.ordem;
      }
    )
  }

}
