import { Component, OnInit, Input } from '@angular/core';
import { GraphqlService, LinhaPesquisa, Evento, Trabalho } from 'src/app/service/graphql/graphql.service';
import { forkJoin } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ExcelService } from 'src/app/service/excel/excel.service';


@Component({
  selector: 'app-gtp-definir',
  templateUrl: './gtp-definir.component.html',
  styleUrls: ['./gtp-definir.component.css'],
  providers: [MessageService]
})
export class GtpDefinirComponent implements OnInit {

  @Input() idEvento: string;
  public linhasDePesquisa: LinhaPesquisa[];
  private evento: Evento;

  constructor(private graphqlService: GraphqlService,
    private messageService: MessageService,
    private excel: ExcelService) {

  }

  ngOnInit() {
    this.graphqlService.obtemLinhaDePesquisaEnvento(this.idEvento, false, true).subscribe(
      linhasDePesquisa => {
        this.linhasDePesquisa = linhasDePesquisa.map(l => {
          if (l.numeroGtp === 0) {
            l.numeroGtp = 1
          }
          return l;
        });
      }
    );
    this.graphqlService.obtemEvento(this.idEvento).subscribe(
      evento => {
        this.evento = evento;
      }
    )
  }

  public criarGruposDeTrabalho(): void {
    const observables = [];
    for (const l of this.linhasDePesquisa) {
      observables.push(this.graphqlService.atualizaNumeroGrupoTrabalhoPoster(l.id, l.numeroGtp));
    }
    forkJoin(observables).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'GTPs salvos' });
      }
    )
  }


  public criarExcelInscritos() {
    const numRomano = ["I", "II", "III", "IV", "V"]
    this.graphqlService.obtemTrabalhosEventoPoster(this.idEvento).subscribe(
      trabalhos => {
        this.excel.exportAsExcelFile([{
          1: "Evento",
          2: this.evento.titulo
        }, {
          // vazio
        }, {
          1: "Grupo de Trabalho",
          2: "Titulo",
          3: "Orientador",
          4: "",
          5: "Autores"
        }, ...trabalhos.filter(t => {
          return (t.orientador.length > 0 && t.orientador[0].inscrito) || (t.autor.length > 0 && t.autor.find(a => a.inscrito))
        }).map(t => {
          const data = {
            1: t.linhaPesquisa ? `${t.linhaPesquisa.descricao} ${numRomano[t.grupoTrabalhoPoster ? t.grupoTrabalhoPoster.numeroGrupo - 1 : 0]}` : "",
            2: t.titulo,
            3: t.orientador.length > 0 && t.orientador[0].inscrito ? t.orientador[0].nome : "",
            4: t.orientador.length > 0 && t.orientador[0].inscrito ? t.orientador[0].email : "",
          }
          let numAutor = 0;
          for (let i = 0; i < t.autor.length; i++) {
            if (!t.autor[i].inscrito) {
              continue
            }
            data[2 * numAutor + 5] = t.autor[i].nome;
            data[2 * numAutor + 6] = t.autor[i].email;
            numAutor++;
          }
          return data
        })], 'sample', {
          skipHeader: true
        })
      }
    )
  }

  public criarExcelGeral() {
    const numRomano = ["I", "II", "III", "IV", "V"]
    this.graphqlService.obtemTrabalhosEventoPoster(this.idEvento).subscribe(
      trabalhos => {
        this.excel.exportAsExcelFile([{
          1: "Evento",
          2: this.evento.titulo
        }, {
          // vazio
        }, {
          1: "Grupo de Trabalho",
          2: "Titulo",
          3: "Orientador",
          4: "",
          5: "Autores"
        }, ...trabalhos.map(t => {
          const data = {
            1: t.linhaPesquisa ? `${t.linhaPesquisa.descricao} ${numRomano[t.grupoTrabalhoPoster ? t.grupoTrabalhoPoster.numeroGrupo - 1 : 0]}` : "",
            2: t.titulo,
            3: t.orientador.length > 0 ? t.orientador[0].nome : "",
            4: t.orientador.length > 0 ? t.orientador[0].email : "",
          }
          for (let i = 0; i < t.autor.length; i++) {
            data[2 * i + 5] = t.autor[i].nome;
            data[2 * i + 6] = t.autor[i].email;
          }
          return data
        })], 'sample', {
          skipHeader: true
        })
      }
    )
  }
}
