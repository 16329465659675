import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuracao-geral',
  templateUrl: './configuracao-geral.component.html',
  styleUrls: ['./configuracao-geral.component.css']
})
export class ConfiguracaoGeralComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
