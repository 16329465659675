import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GraphqlService, StatusNoticia } from '../../service/graphql/graphql.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { UploadAdapterHotsite, TipoImagemCkeditor,  } from '../../service/upload-adapter/upload-adapter-hotsite';

@Component({
  selector: 'app-noticias-insert',
  templateUrl: './noticias-insert.component.html',
  styleUrls: ['./noticias-insert.component.css']
})
export class NoticiasInsertComponent implements OnInit {

  public form: FormGroup;
  public tiposPublicacao: any[];
  private proprietario: StatusNoticia;

  constructor(private formBuilder: FormBuilder, 
    private graphqlService: GraphqlService,
    private router: Router) {
      
    this.form = this.formBuilder.group({
      titulo: ['', Validators.required],
      linhaFina: [''],
      conteudo: ['', Validators.required],
      data: [new Date()],
      publicacao: [StatusNoticia.RASCUNHO, Validators.required],
      horaPublicacao: [new Date()]
    });
    this.tiposPublicacao = [
      { label: "Rascunho (não fica visivel no portal)", value: StatusNoticia.RASCUNHO },
      { label: "Publicado (entra no ar imediatamente)", value: StatusNoticia.PUBLICADO },
      { label: "Agendado para publicar automaticamente (a data de publicação deve estar no futuro)", value: StatusNoticia.AGENDADO },
    ];
    this.proprietario = this.router.getCurrentNavigation().extras.state.proprietario;
  }

  ngOnInit() {

  }

  public criarNoticia() {
    const data = moment(this.form.get('horaPublicacao').value).format('DD/MM/YYYY');
    const tempo = moment(this.form.get('data').value).format("HH:mm:ss");
    const dataHora = `${data} ${tempo}`;
    const noticia: any = {
      descricao: this.form.get('linhaFina').value,
      status: this.form.get('publicacao').value,
      texto: this.form.get('conteudo').value,
      titulo: this.form.get('titulo').value,
      dataNoticia: dataHora,
      proprietario: this.proprietario
    }
    this.graphqlService.inserirNoticia(noticia).subscribe(
      () => {
        this.router.navigateByUrl('/adm/noticias-list');
      }
    )
  }

  onReady(event) {
    event.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new UploadAdapterHotsite(loader, TipoImagemCkeditor.NOTICIA);
    }
    event.plugins.get('InserirImagem').getImagensGaleria = () => {
      return this.graphqlService.obtemArquivosGaleria().toPromise();
    }
  }


}
