import { Component, OnInit } from '@angular/core';
import { GraphqlService, DescricaoQuemSomos } from 'src/app/service/graphql/graphql.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-quem-somos-missao-edit',
  templateUrl: './quem-somos-missao-edit.component.html',
  styleUrls: ['./quem-somos-missao-edit.component.css'],
  providers: [MessageService]
})
export class QuemSomosMissaoEditComponent implements OnInit {

  public conteudo: DescricaoQuemSomos;

  constructor(private graphqlService: GraphqlService,
    private router: Router,
    private messageService: MessageService) {
      this.conteudo = {
        descricao: "",
        imagem: undefined
      }
    this.graphqlService.obtemQuemSomos().subscribe(
      q => {
        this.conteudo = q.missao
      }
    )
  }

  ngOnInit() {
  }

  public salvar(): void  {
    this.graphqlService.atualizaDescricaoMissao(this.conteudo.descricao).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'Descrição atualizada.' });
        this.router.navigateByUrl('/adm/quem-somos-missao')
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.'});
      }
    )
  }

  public cancelar(): void {
    this.router.navigateByUrl('/adm/quem-somos-missao')
  }

}
