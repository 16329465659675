import { Component, OnInit } from '@angular/core';
import { GraphqlService, Evento } from 'src/app/service/graphql/graphql.service';
import { AutenticacaoService } from 'src/app/service/autenticacao/autenticacao.service';

@Component({
  selector: 'app-area-do-associado',
  templateUrl: './area-do-associado.component.html',
  styleUrls: ['./area-do-associado.component.css']
})
export class AreaDoAssociadoComponent implements OnInit {

  public eventos: Evento[];
  public idUsuario: number;

  constructor(private graphqlService: GraphqlService,
    private authService: AutenticacaoService) {
    this.idUsuario = this.authService.obtemUsuario().id;
    this.graphqlService.obtemEventosAcessoEvento(String(this.idUsuario)).subscribe(
      eventos => {
        this.eventos = eventos;
      }
    );
  }

  ngOnInit() {
  }

  public isEventoConpedi(e: Evento): boolean {
    return e.tipoEvento === "CONPEDI"
  }

}
