import { Component, OnInit } from '@angular/core';
import { GraphqlService, Pessoa, Colunista } from '../../service/graphql/graphql.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-colunista-list',
  templateUrl: './colunista-list.component.html',
  styleUrls: ['./colunista-list.component.css']
})
export class ColunistaListComponent implements OnInit {

  public cols: any[];
  public dados: any[];
  public displayInsercao: boolean;
  public displayExclusao: boolean;
  public colunistaSelecionado: Colunista;

  constructor(private graphqlService: GraphqlService,
    private router: Router) {
    this.atualizarLista();
  }

  private atualizarLista() {
    this.graphqlService.obtemColunistas().subscribe(
      colunistas => {
        this.dados = [];
        for (const col of colunistas) {
          this.dados.push({
            id: col.id,
            nome: col.pessoa.nome,
            nomePublico: col.nomePublico,
            publicado: col.publicado ? "Publicado" : "Não publicado"
          })
        }
      }
    )
  }

  ngOnInit() {
    this.cols = [
      { field: 'nome', header: 'Colunista' },
      { field: 'nomePublico', header: 'Nome de exibição' },
      { field: 'publicado', header: 'Situação' },
    ];
  }

  public abrirExclusao(colunista: Colunista) {
    this.colunistaSelecionado = colunista;
    this.displayExclusao = true;
  }

  public excluirColunista() {
    this.graphqlService.excluirColunista(this.colunistaSelecionado.id).subscribe(
      () => {
        this.displayExclusao = false;
        this.atualizarLista();
      }
    )
  }

  public inserirColunista(pessoa: Pessoa) {
    this.graphqlService.inserirColunista(pessoa.id).subscribe(
      (id) => {
        this.router.navigateByUrl(`adm/colunistas-editor/${id}`);
      }
    )
  }

}
