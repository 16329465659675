import { Component, OnInit } from '@angular/core';
import { GraphqlService, DiretoriaGrupo } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-diretoria',
  templateUrl: './diretoria.component.html',
  styleUrls: ['./diretoria.component.css']
})
export class DiretoriaComponent implements OnInit {

  public diretoriaGrupos: DiretoriaGrupo[];

  constructor(private graphqlService: GraphqlService) {
    this.graphqlService.obtemDiretoriaGrupos().subscribe(
      dg => {
        this.diretoriaGrupos = dg.filter(d => d.diretores && d.diretores.length > 0);
      }
    )
  }

  ngOnInit() {
  }

}
