import { Component, OnInit } from '@angular/core';
import { GraphqlService, Evento, TipoEvento } from 'src/app/service/graphql/graphql.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-publicacao-lista-evento',
  templateUrl: './publicacao-lista-evento.component.html',
  styleUrls: ['./publicacao-lista-evento.component.css']
})
export class PublicacaoListaEventoComponent implements OnInit {

  public eventos: Evento[];
  public tipo: string;
  public descTipo: String = "";

  constructor(private graphqlService: GraphqlService,
    private route: ActivatedRoute) {

    }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.tipo = routeParams['tipo'];
      this.graphqlService.obtemEventosPorTipo(this.tipo === "parceiro" ? TipoEvento.PARCEIRO : TipoEvento.CONPEDI).subscribe(
        (e) => {
          if (this.tipo === "artigo") {
            this.eventos = e.filter(e => e.eventoEncerrado && e.publicacaoLiberadaArtigo);
            this.descTipo = "Artigo Conpedi";
          } else if (this.tipo === "poster") {
            this.eventos = e.filter(e => e.eventoEncerrado && e.publicacaoLiberadaPoster);
            this.descTipo = "Pôster Conpedi";
          } else if (this.tipo === "parceiro") {
            this.eventos = e.filter(e => e.eventoEncerrado && (e.publicacaoLiberadaArtigo || e.publicacaoLiberadaPoster));
            this.descTipo = "Parceiros";
          }
        }
      )
    });
  }

}
