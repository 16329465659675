import { Component, OnInit } from '@angular/core';
import { GraphqlService, Usuario } from 'src/app/service/graphql/graphql.service';
import { AutenticacaoService } from 'src/app/service/autenticacao/autenticacao.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-fale-conosco-edit',
  templateUrl: './fale-conosco-edit.component.html',
  styleUrls: ['./fale-conosco-edit.component.css'],
  providers: [MessageService]
})
export class FaleConoscoEditComponent implements OnInit {

  public descricaoFaleConosco: string;
  private usuario: Usuario;
  private sub: Subscription;

  constructor(private graphqlService: GraphqlService,
    private authService: AutenticacaoService,
    private messageService: MessageService,
    private router: Router) {
    this.descricaoFaleConosco = "";
    this.sub = this.authService.bsAutenticacao.subscribe(
      u => {
        this.usuario = u;
        if (u) {
          this.graphqlService.obtemDescricaoFaleConosco().subscribe(
            d => {
              this.descricaoFaleConosco = d;
            }
          )
        } else {
          this.descricaoFaleConosco = "";
        }
      }
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  ngOnInit() {
  }

  public salvar(): void {
    this.graphqlService.atualizaDescricaoFaleConosco(this.descricaoFaleConosco, String(this.usuario.id)).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'Descrição atualizada.' });
        this.router.navigateByUrl('/fale-conosco');
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.'});
      }
    )
  }

  public cancelar(): void {
    this.router.navigateByUrl('/fale-conosco');
  }
}
