import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GraphqlService, Evento, Trabalho } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-evento-trabalho',
  templateUrl: './evento-trabalho.component.html',
  styleUrls: ['./evento-trabalho.component.css']
})
export class EventoTrabalhoComponent implements OnInit {

  public evento: Evento;
  public trabalho: Trabalho;

  constructor(private route: ActivatedRoute,
    private graphqlService: GraphqlService) { }

  ngOnInit() {
    const idEvento = this.route.snapshot.params['idEvento'];
    const idTrabalho = this.route.snapshot.params['idTrabalho'];
    this.graphqlService.obtemEvento(idEvento).subscribe(
      e => {
        this.evento = e;
      }
    )
    this.graphqlService.obtemTrabalho(idTrabalho).subscribe(
      t => {
        this.trabalho = t;
      }
    )

  }

}
