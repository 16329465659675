import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { AutenticacaoService } from '../../service/autenticacao/autenticacao.service'; 
import { Usuario, GraphqlService } from '../../service/graphql/graphql.service';
import { Subscription } from 'rxjs';
import { UploadAdapter } from 'src/app/service/upload-adapter/upload-adapter';

@Component({
  selector: 'app-editar-conteudo',
  templateUrl: './editar-conteudo.component.html',
  styleUrls: ['./editar-conteudo.component.css']
})
export class EditarConteudoComponent implements OnInit {

  public usuario: Usuario;
  @Input() conteudo: string;
  @Output() conteudoChange: EventEmitter<string>;
  @Output() salvar: EventEmitter<string>;
  @Output() cancelar: EventEmitter<void>;
  private sub: Subscription;

  constructor(private authService: AutenticacaoService,
    private graphqlService: GraphqlService) {
    this.conteudoChange = new EventEmitter();
    this.salvar = new EventEmitter();
    this.cancelar = new EventEmitter();
    this.sub = this.authService.bsAutenticacao.subscribe(
      u => {
        this.usuario = u;
      }
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public confirmar(): void {
    this.salvar.emit(this.conteudo);
  }

  onReady(event) {
    event.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new UploadAdapter(loader);
    }
    event.plugins.get('InserirImagem').getImagensGaleria = () => {
      return this.graphqlService.obtemArquivosGaleria().toPromise();
    }
  }

  public conteudoAlterado(conteudo: string): void {
    this.conteudoChange.emit(conteudo);
  }

  public voltar(): void {
    this.cancelar.emit();
  }

  ngOnInit() {
  }

}
