import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GraphqlService, Pessoa } from '../../service/graphql/graphql.service';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-selecao-pessoa',
  templateUrl: './selecao-pessoa.component.html',
  styleUrls: ['./selecao-pessoa.component.css']
})
export class SelecaoPessoaComponent implements OnInit {

  @Input() visible: boolean;
  @Input() titulo: string;
  @Input() label: string;
  @Output() pessoa: EventEmitter<Pessoa> = new EventEmitter();
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter();
  public pessoaNaoEncontrada: boolean;

  public resultadosPesquisaPessoa: Pessoa[];
  public form: FormGroup;

  constructor(private graphqlService: GraphqlService,
    private formBuilder: FormBuilder,) {
    this.form = this.formBuilder.group({
      pessoa: [null, Validators.compose([Validators.required, this.validarPessoa])],
    });
  }

  ngOnInit() {
  }

  ngOnChanges() {
      this.form.get("pessoa").setValue(null);
      this.resultadosPesquisaPessoa = [];
  }

  public pesquisarPessoas(event) {
    this.graphqlService.obtemPessoasPorNome(event.query, 10).subscribe(
      pessoas => {
        this.resultadosPesquisaPessoa = pessoas;
        if (pessoas.length === 0) {
          this.pessoaNaoEncontrada = true;
        } else {
          this.pessoaNaoEncontrada = false;
        }
      }
    )
  }

  public validarPessoa(c: AbstractControl) {
    if (c.value && c.value.hasOwnProperty('id') && c.value.hasOwnProperty('nome')) {
      return null;
    } else {
      return {
        objetoInvalido: true
      }
    }
  }

  public selecionarPessoa() {
    this.pessoa.emit(this.form.get("pessoa").value);
  }

  public fecharModal() {
    this.visible = false;
    this.visibleChange.emit(false);
  }

}
