import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { GraphqlService, SecaoEdital, TipoEvento, Evento } from 'src/app/service/graphql/graphql.service';
import * as moment from 'moment-timezone';

declare var $: any;

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EventosComponent implements OnInit {

  public tipoEvento: TipoEvento;
  public eventos: Evento[];
  public eventosDestaque: Evento[];
  private jqueryIniciado: boolean;

  constructor(private router: Router,
    private graphqlService: GraphqlService) { }

  ngOnInit() {
    const tipo = this.router.url.split('/')[2].toUpperCase();
    this.tipoEvento = tipo === TipoEvento.CONPEDI ? TipoEvento.CONPEDI : TipoEvento.PARCEIRO;
    this.graphqlService.obtemEventosPorTipo(this.tipoEvento).subscribe(
      ee => {
        this.eventos = ee;
        this.eventosDestaque = ee.filter((e) => {
          const dataFinal = this.converterStringParaData(e.dataFinal);
          return moment(dataFinal).isAfter();
        })
        console.log(this.eventosDestaque);
      }
    )
  }

  iniciarSlider() {
    if (!this.jqueryIniciado) {
      $('.eventos-atuais > div').bxSlider({
        slideWidth: 450,
        minSlides: 2,
        maxSlides: 2,
        moveSlides: 2,
        touchEnabled:false,
      });
      this.jqueryIniciado = true;
    }
  }

  private converterStringParaData(data: string): Date {
    return moment(data, 'DD/MM/YYYY').toDate();
  }

}
