import { Component, OnInit } from '@angular/core';
import { GraphqlService, GrupoTrabalhoPoster } from 'src/app/service/graphql/graphql.service';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-gtp-publicacao',
  templateUrl: './gtp-publicacao.component.html',
  styleUrls: ['./gtp-publicacao.component.css']
})
export class GtpPublicacaoComponent implements OnInit {

  public tiposPublicacao: any[];
  public form: FormGroup;
  public formFicha: FormGroup;
  public formLinkApresentacao: FormGroup;
  public grupoTrabalho: GrupoTrabalhoPoster;
  private idGrupoTrabalho: string;
  public atualizandoPublicacao: boolean;

  constructor(private graphqlService: GraphqlService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private messageService: MessageService) {
    this.tiposPublicacao = [
      { label: "ISBN", value: "ISBN" },
      { label: "ISSN", value: "ISSN" }
    ];
    this.form = this.formBuilder.group({
      tipoPublicacao: [this.tiposPublicacao[0].value, Validators.required],
      isbnIssn: ['', Validators.required]
    });
    this.formFicha = this.formBuilder.group({
      ficha: ['', Validators.required]
    });
    this.formLinkApresentacao = this.formBuilder.group({
      link: ['', Validators.required]
    })
  }

  ngOnInit() {
    this.idGrupoTrabalho = this.route.snapshot.params['id'];
    this.atualizarDadosGrupoTrabalho();
  }

  private atualizarDadosGrupoTrabalho(): void {
    this.graphqlService.obtemGrupoDeTrabalhoPoster(this.idGrupoTrabalho).subscribe(
      g => {
        this.grupoTrabalho = g;
        this.form = this.formBuilder.group({
          tipoPublicacao: [g.tipoPublicacao ? g.tipoPublicacao : this.tiposPublicacao[0].value, Validators.required],
          isbnIssn: [g.isbnIssn ? g.isbnIssn : ""]
        });
        this.formLinkApresentacao = this.formBuilder.group({
          link: [g.linkApresentacao || "", Validators.required]
        })
      }
    );
  }

  public atualizaLinkApresentacao(): void {
    this.grupoTrabalho.tipoPublicacao = this.form.get('tipoPublicacao').value;
    if (this.grupoTrabalho.tipoPublicacao == "" || this.grupoTrabalho.tipoPublicacao == null) {
      this.grupoTrabalho.tipoPublicacao = "ISBN"
    }
    this.grupoTrabalho.isbnIssn = this.form.get('isbnIssn').value;
    this.grupoTrabalho.linkApresentacao = this.formLinkApresentacao.get('link').value;
    this.graphqlService.atualizaGrupoTrabalhoPoster(this.grupoTrabalho).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: 'O link da apresentação do grupo de trabalho foi atualizado com sucesso.' });
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Erro!', detail: error });
      }
    )
  }

  public atualizarPublicacao(): void {
    this.grupoTrabalho.tipoPublicacao = this.form.get('tipoPublicacao').value;
    if (this.grupoTrabalho.tipoPublicacao == "" || this.grupoTrabalho.tipoPublicacao == null) {
      this.grupoTrabalho.tipoPublicacao = "ISBN"
    }
    this.grupoTrabalho.isbnIssn = this.form.get('isbnIssn').value;
    this.graphqlService.atualizaGrupoTrabalhoPoster(this.grupoTrabalho).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: 'A publicação do grupo de trabalho foi atualizada com sucesso.' });
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Erro!', detail: error });
      }
    )
  }

  public atualizarPDFsPublicacao(): void {
    if (!this.grupoTrabalho.fichaCatalografica) {
      this.messageService.add({ severity: 'error', summary: 'Erro!', detail: "Este grupo de trabalho não possui uma ficha catalográfica associada." });
    } else {
      this.atualizandoPublicacao = true;
      this.graphqlService.geraPdfPublicacaoGrupoTrabalhoPoster(this.grupoTrabalho.id).subscribe(
        url => {
          this.atualizandoPublicacao = false;
          window.open(url, "_blank");
          this.atualizarDadosGrupoTrabalho();
        }, (error) => {
          this.atualizandoPublicacao = false;
          this.messageService.add({ severity: 'error', summary: 'Erro!', detail: error });
        }
      )
    }
  }

  public onFileChange(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const count = (fileReader.result as any).match(/\/Type[\s]*\/Page[^s]/g).length;
        if (count === 1) {
          this.formFicha.patchValue({
            ficha: file
          });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'A ficha catalográfica deve ter uma página.' });
          this.formFicha.patchValue({
            ficha: null
          });
        }
      };
      fileReader.readAsBinaryString(file);
    } else {
      this.formFicha.patchValue({
        ficha: null
      })
    }
  }

  public atualizarFichaCatalografica(): void {
    this.graphqlService.uploadFile(this.formFicha.get('ficha').value, "pdf_ficha_catalografica", this.idGrupoTrabalho).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: 'A ficha catalográfica foi atualizada com sucesso.' });
        this.atualizarDadosGrupoTrabalho();
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Erro!', detail: error });
      }
    )
  }

  public visualizarFichaCatalografica(): void {
    const fichaCatalografica = this.grupoTrabalho.fichaCatalografica;
    if (fichaCatalografica) {
      window.open(fichaCatalografica.url, "_blank");
    } else {
      this.messageService.add({ severity: 'error', summary: 'Erro!', detail: "Este grupo de trabalho não possui uma ficha catalográfica associada." });
    }
  }

  public removerFichaCatalografica(): void {
    this.grupoTrabalho.fichaCatalografica = null;
    this.grupoTrabalho.tipoPublicacao = this.form.get('tipoPublicacao').value;
    this.grupoTrabalho.isbnIssn = this.form.get('isbnIssn').value;
    this.graphqlService.atualizaGrupoTrabalhoPoster(this.grupoTrabalho).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: 'A ficha catalográfica foi removida com sucesso.' });
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro!', detail: "Ocorreu um erro inesperado, por favor tente novamente mais tarde." });
      }
    )
  }

  public downloadPdfPublicacao(): void {
    window.open(this.grupoTrabalho.filePublicacao.url, "_blank");
  }
}
