import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-imagem',
  templateUrl: './upload-imagem.component.html',
  styleUrls: ['./upload-imagem.component.css']
})
export class UploadImagemComponent implements OnInit {

  @Input() titulo = ""
  @Input() tipo = ""

  constructor() { }

  ngOnInit() {
  }

  trocarImagem() {

  }

}
