import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GraphqlService, Documento, Arquivo, GrupoDocumento } from 'src/app/service/graphql/graphql.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { ArquivoService } from 'src/app/service/arquivo/arquivo.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-documento-upsert',
  templateUrl: './documento-upsert.component.html',
  styleUrls: ['./documento-upsert.component.css']
})
export class DocumentoUpsertComponent implements OnInit {

  public documento: Documento;
  public form: FormGroup;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private graphqlService: GraphqlService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private arquivoService: ArquivoService,
    private messageService: MessageService) { }

  ngOnInit() {
    const idDocumento = this.route.snapshot.params['idDocumento'];
    if (idDocumento) {
      this.graphqlService.obtemDocumento(idDocumento).subscribe(
        d => {
          this.documento = d;
          this.form = this.formBuilder.group({
            descDocumento: [d.descDocumento, Validators.required],
            grupo: [d.grupo, Validators.required],
            arquivo: [d.arquivo, Validators.required],
            publicado: [d.publicado]
          })
        }
      )
    } else {
      this.form = this.formBuilder.group({
        descDocumento: ['', Validators.required],
        grupo: [GrupoDocumento.ESTATUTO, Validators.required],
        arquivo: [null, Validators.required],
        publicado: [false]
      })
    }
  }

  public uploadArquivo(): void {
    this.dialogService.uploadArquivo({
      header: "Upload Arquivo",
      data: {
        mimeType: '.pdf',
        accept: (a: File) => {
          return new Promise<any>((resolve, reject) => {
            this.arquivoService.upload(a).then(
              res => {
                this.form.get("arquivo").setValue({
                  id: res.id,
                  nome: a.name,
                  url: res.url
                });
                resolve()
              }, err => {
                reject(err)
              }
            )
          })
        }
      }
    })
  }

  public insereEditaDocumento(): void {
    if (this.documento) {
      return this.editarDocumento();
    }
    return this.inserirDocumento();
  }

  private editarDocumento(): void {
    this.graphqlService.atualizaDocumento({
      id: this.documento.id,
      ... this.form.value
    }).subscribe(
      () => {
        this.router.navigateByUrl('/adm/documentos-list');
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.' });
      }
    )
  }

  private inserirDocumento(): void {
    this.graphqlService.insereDocumento({... this.form.value}).subscribe(
      () => {
        this.router.navigateByUrl('/adm/documentos-list');
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.' });
      }
    )
  }

}
