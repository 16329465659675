import { Component, OnInit, Input } from '@angular/core';
import {Location} from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-portal-titulo-pagina',
  templateUrl: './portal-titulo-pagina.component.html',
  styleUrls: ['./portal-titulo-pagina.component.css']
})
export class PortalTituloPaginaComponent implements OnInit {

  @Input() titulo: string;
  @Input() route: string;
  @Input() mostrarBotaoVoltar: boolean;

  constructor(private location: Location,
    private router: Router) {
      this.mostrarBotaoVoltar = true;
    }

  ngOnInit() {
  }

  public voltar() {
    if (this.route) {
      return this.router.navigateByUrl(this.route);
    }
    this.location.back();
  }

}
