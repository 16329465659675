import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GraphqlService, Diretor } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-diretor-foto',
  templateUrl: './diretor-foto.component.html',
  styleUrls: ['./diretor-foto.component.css']
})
export class DiretorFotoComponent implements OnInit {

  public diretor: Diretor;
  public processandoImagem: boolean;
  @ViewChild('input', { static: true}) input: any;

  constructor(private route: ActivatedRoute,
    private graphqlService: GraphqlService) { }

  ngOnInit() {
    const idDiretor = this.route.snapshot.params['idDiretor'];
    this.graphqlService.obtemDiretor(idDiretor).subscribe(
      d => {
        this.diretor = d;
      }
    )
  }

  public alterarImagem(): void {
    this.processandoImagem = true;
    const file = this.input.nativeElement.files[0];
    this.graphqlService.uploadFile(file, "foto_diretor", this.diretor.id).subscribe(
      (url) => {
        this.processandoImagem = false;
        this.diretor.foto = {
          id: "",
          url: url
        }
      }
    )
  }
}
