import { Injectable } from '@angular/core';
import { GraphqlService, Usuario } from '../graphql/graphql.service';
import { BehaviorSubject } from 'rxjs';
const jwt_decode = require('jwt-decode');

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoService {

  public bsAutenticacao: BehaviorSubject<Usuario>;
  public bsFazendoLogin: BehaviorSubject<boolean>;
  public email: String;
  public senha: String;

  constructor(private graphqlService: GraphqlService) {
    this.bsAutenticacao = new BehaviorSubject(null);
    this.bsFazendoLogin = new BehaviorSubject(false);
  }

  public async entrarComEmailESenha(email: string, senha: string) {
    this.email = email;
    this.senha = senha;
    this.graphqlService.login(email, senha).subscribe((usuario) => {
      if (usuario.ok) {
        this.decodificarToken(usuario.token)
      } else {
        alert('Usuário/senha incorreta');
      }
    });
  }

  public async loginExterno(token: string) {
    this.decodificarToken(token)
  }

  private decodificarToken(token: string) {
    const decoded = jwt_decode(token);
    this.bsAutenticacao.next({ id: decoded.idPessoa, nome: decoded.nome, token: token, roles: decoded.roles });
    this.bsFazendoLogin.next(false);
  }

  public logout() {
    this.bsAutenticacao.next(null);
    this.bsFazendoLogin.next(false);
  }

  public obtemUsuario() {
    return this.bsAutenticacao.value;
  }

  verificaUsuarioSemAcesso(usuario: Usuario): any {
    if (usuario.roles.indexOf('"FAZ_LOGIN_COMO"') == -1) {
      return null;
    }
    return { email: this.email, senha: this.senha };
  }

}
