import { Component } from '@angular/core';
import { TemlateSiteComponent } from './pages/temlate-site/temlate-site.component';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

  constructor(public app: TemlateSiteComponent) { }
}
