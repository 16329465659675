import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Evento, GraphqlService, InscricaoEvento, TipoInscricaoEvento } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-evento-inscricao',
  templateUrl: './evento-inscricao.component.html',
  styleUrls: ['./evento-inscricao.component.css']
})
export class EventoInscricaoComponent implements OnInit {

  public evento: Evento;
  public inscricaoArtigo: InscricaoEvento;
  public inscricaoPoster: InscricaoEvento;
  public inscricaoOuvinte: InscricaoEvento;


  constructor(public route: ActivatedRoute,
    public graphqlService: GraphqlService) { }

  ngOnInit() {
    const idEvento = this.route.snapshot.params['idEvento'];
    this.graphqlService.obtemEvento(idEvento).subscribe(
      e => {
        this.evento = e;
      }
    );
    this.graphqlService.obtemInscricoesEvento(idEvento).subscribe(
      ii => {
        for (const i of ii) {
          if (i.tipo === TipoInscricaoEvento.ARTIGO) {
            this.inscricaoArtigo = i;
          } else if (i.tipo === TipoInscricaoEvento.POSTER) {
            this.inscricaoPoster = i;
          } else if (i.tipo === TipoInscricaoEvento.OUVINTE) {
            this.inscricaoOuvinte = i;
          }
        }
      }
    )
  }

}
