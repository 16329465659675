import { Injectable } from '@angular/core';
import { DialogService as PDialogService, DynamicDialogRef } from 'primeng/api'
import { UploadArquivoComponent } from '../../components/upload-arquivo/upload-arquivo.component';

interface InputUploadArquivo {
  header: string
  data: {
    mimeType: string
    accept: Function
  }
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {

  constructor(private dialog: PDialogService) { }

  public uploadArquivo(dados: InputUploadArquivo): DynamicDialogRef {
    return this.dialog.open(UploadArquivoComponent, {
      header: dados.header,
      width: '500px',
      height: '385px',
      data: dados.data,
      style: {
        "display": "flex",
        "flex-direction": "column"
      }
    });
  }
}