import { Component, OnInit, ViewChild } from '@angular/core';
import { GraphqlService, MenuLateral } from '../../service/graphql/graphql.service';

@Component({
  selector: 'app-menu-lateral-list',
  templateUrl: './menu-lateral-list.component.html',
  styleUrls: ['./menu-lateral-list.component.css']
})
export class MenuLateralListComponent implements OnInit {

  public cols: any[];
  public menus: MenuLateral[];
  private menuSelecionado: MenuLateral;
  public displayInsercao: boolean;
  public displayAtualizacao: boolean;
  @ViewChild("descricaoInsercao", { static: true }) descricaoInsercao: any;
  @ViewChild("linkInsercao", { static: true }) linkInsercao: any;
  @ViewChild("inputInsercao", { static: true }) inputInsercao: any;
  @ViewChild("descricaoAtualizacao", { static: true }) descricaoAtualizacao: any;
  @ViewChild("linkAtualizacao", { static: true }) linkAtualizacao: any;
  @ViewChild("inputAtualizacao", { static: true }) inputAtualizacao: any;

  constructor(private graphqlService: GraphqlService) { 
    this.atualizarLista();
  }

  private atualizarLista() {
    this.graphqlService.obtemMenusLaterais().subscribe(
      menus => {
        this.menus = menus;
      }
    )
  }

  ngOnInit() {
    this.cols = [
      { field: 'descricao', header: 'Descrição' },
      { field: 'link', header: 'Link' },
    ];
  }

  public isInsercaoHabilitado(): boolean {
    return this.descricaoInsercao.nativeElement.value.length > 0 && this.linkInsercao.nativeElement.value.length > 0 && this.inputInsercao.nativeElement.files.length > 0;
  }

  public isAtualizacaoHabilitado(): boolean {
    return this.descricaoAtualizacao.nativeElement.value.length > 0 && this.linkInsercao.nativeElement.value.length > 0;
  }

  public abrirInsercao() {
    this.descricaoInsercao.nativeElement.value = "";
    this.linkInsercao.nativeElement.value = "";
    this.inputInsercao.nativeElement.value = "";
    this.displayInsercao = true;
  }

  public abrirAtualizacao(menu: MenuLateral) {
    this.descricaoAtualizacao.nativeElement.value = menu.descricao;
    this.linkAtualizacao.nativeElement.value = menu.link;
    this.inputInsercao.nativeElement.value = "";
    this.menuSelecionado = menu;
    this.displayAtualizacao = true;
  }

  public excluir(menu: MenuLateral) {
    this.graphqlService.excluirMenuLateral(menu.id).subscribe(
      () => {
        this.atualizarLista();
      }
    )
  }

  public abrirSelecaoDeImagens(id) {
    document.getElementById(id).click();
  }

  public inserirMenu() {
    const descricao = this.descricaoInsercao.nativeElement.value;
    const link = this.linkInsercao.nativeElement.value;
    const arquivo = this.inputInsercao.nativeElement.files[0];
    if (descricao && link && arquivo) {
      this.graphqlService.inserirMenuLateral(descricao, link).subscribe(
        id => {
          this.graphqlService.uploadFile(arquivo, "imagem_menu_lateral", id).subscribe();
          this.atualizarLista();
        }, 
        () => {}, 
        () => {
          this.displayInsercao = false;
        }
      )
    }
  }

  public atualizarMenu() {
    const descricao = this.descricaoAtualizacao.nativeElement.value;
    const link = this.linkAtualizacao.nativeElement.value;
    if (descricao && link) {
      const arquivo = this.inputAtualizacao.nativeElement.files[0];
      this.graphqlService.atualizarMenuLateral(this.menuSelecionado.id, descricao, link).subscribe(
        () => {
          if (arquivo) {
            this.graphqlService.uploadFile(arquivo, "imagem_menu_lateral", this.menuSelecionado.id).subscribe();
          }
          this.atualizarLista();
        }, 
        () => {},
        () => {
          this.displayAtualizacao = false;
        }
      )
    }
  }


}
