import { Component, OnInit, ViewChild } from '@angular/core';
import { GraphqlService, Noticia } from '../../service/graphql/graphql.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-noticias-view',
  templateUrl: './noticias-view.component.html',
  styleUrls: ['./noticias-view.component.css']
})
export class NoticiasViewComponent implements OnInit {

  public idNoticia: number;
  public noticia: Noticia;
  @ViewChild('inputChamada', { static: true }) inputChamada: any;
  @ViewChild('inputNoticia', { static: true }) inputNoticia: any;
  private proprietario: any;

  constructor(private graphqlService: GraphqlService, private route: ActivatedRoute, private router: Router) {
    this.proprietario = this.router.getCurrentNavigation().extras.state.proprietario;
  }

  ngOnInit() {
    this.atualizarPagina();
  }

  public atualizarPagina() {
    this.idNoticia = Number(this.route.snapshot.params['id']);
    this.graphqlService.obtemNoticia(this.idNoticia).subscribe(
      noticia => {
        this.noticia = noticia;
      }
    )
  }

  public alterarFotoChamada() {
    const file = this.inputChamada.nativeElement.files[0];
    if (this.isTipoArquivoValido(file.type)) {
      this.graphqlService.uploadFile(file, "imagem_chamada", this.noticia.id).subscribe(
        () => {
          this.atualizarPagina();
        }
      )
    }
    this.inputChamada.nativeElement.value = "";
  }

  public alterarFotoNoticia() {
    const file = this.inputNoticia.nativeElement.files[0];
    if (this.isTipoArquivoValido(file.type)) {
      this.graphqlService.uploadFile(file, "imagem_noticia", this.noticia.id).subscribe(
        () => {
          this.atualizarPagina();
        }
      )
    }
    this.inputNoticia.nativeElement.value = "";
  }

  private isTipoArquivoValido(tipo: string): boolean {
    return tipo == "image/png" || tipo == "image/jpg" || tipo == "image/jpeg";
  }

  public editarNoticia() {
    this.router.navigateByUrl(`adm/noticias-edit/${this.idNoticia}`, { state: { proprietario: this.proprietario } });
  }

}
