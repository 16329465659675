import { Component, OnInit, Input, Output } from '@angular/core';
import { GraphqlService, ProprietarioNoticia } from 'src/app/service/graphql/graphql.service';
import { Imagem } from '../inicial-mostra-imagens/inicial-mostra-imagens.component';
import { Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-inicial-noticia',
  templateUrl: './inicial-noticia.component.html',
  styleUrls: ['./inicial-noticia.component.css']
})
export class InicialNoticiaComponent implements OnInit {

  @Input() proprietario: string;
  public imagens: Imagem[] = [];
  public descricaoTipo = "";
  private _imagensFiltradas: Imagem[];

  constructor(private graphqlService: GraphqlService, private router: Router, private breakpointObserver: BreakpointObserver) { }

  ngOnInit() {
    if (this.proprietario ===  ProprietarioNoticia.CONPEDI) {
      this.descricaoTipo = "Notícias CONPEDI";
    } else {
      this.descricaoTipo = "Notícias PPGDs";
    }
    this.graphqlService.obtemNoticiasPublicadas().subscribe(
      noticias => {
        const recs = noticias.filter(n => n.proprietario === this.proprietario);
        this.imagens = [];
        for (const rec of recs) {
          if (rec.arquivoFotoChamada != null && rec.arquivoFotoChamada.url != null) {
            this.imagens.push(new Imagem(rec.id, rec.arquivoFotoChamada.url, rec.titulo, rec.dataNoticia));
          }
        }
      //  this.criarListeners();
      });
  }

  private criarListeners() {
    this.breakpointObserver.observe(['(min-width: 1px)','(max-width: 576px)']).subscribe(
      state => {
        if (state.matches) {
          this.atualizarLista(1);
        }
      }
    );
    // sm
    this.breakpointObserver.observe(['(min-width: 576px)', '(max-width: 767px)']).subscribe(
      state => {
        if (state.matches) {
          this.atualizarLista(1);
        }
      }
    );
    // md
    this.breakpointObserver.observe(['(min-width: 768px)', '(max-width: 991px)']).subscribe(
      state => {
        if (state.matches) {
          this.atualizarLista(3);
        }
      }
    );
    //lg
    this.breakpointObserver.observe(['(min-width: 992px)', '(max-width: 1199px)']).subscribe(
      state => {
        if (state.matches) {
          this.atualizarLista(4);
        }
      }
    );
    // xl
    this.breakpointObserver.observe(['(min-width: 1200px)']).subscribe(
      state => {
        if (state.matches) {
          this.atualizarLista(6);
        }
      }
    );
  }

  private atualizarLista(numItens): void {
    if (this._imagensFiltradas) {
      this.imagens = this._imagensFiltradas.slice(0, numItens);
    }
  }

  onClick(imagem) {
    this.router.navigateByUrl(`/noticia-view/${imagem.id}`);
  }
}
