import { Component, OnInit, ViewChild } from '@angular/core';
import { GraphqlService, Arquivo } from '../../service/graphql/graphql.service';

@Component({
  selector: 'app-espaco-do-autor-list',
  templateUrl: './espaco-do-autor-list.component.html',
  styleUrls: ['./espaco-do-autor-list.component.css']
})
export class EspacoDoAutorListComponent implements OnInit {

  constructor(private graphqlService: GraphqlService) { 

  }


  ngOnInit() {
  }

}
