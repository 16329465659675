import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GraphqlService, Painel } from 'src/app/service/graphql/graphql.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ptBr } from 'src/app/shared/calendar.locale';
import * as moment from 'moment';

@Component({
  selector: 'app-painel-evento-edit',
  templateUrl: './painel-evento-edit.component.html',
  styleUrls: ['./painel-evento-edit.component.css']
})
export class PainelEventoEditComponent implements OnInit {

  public form: FormGroup;
  public ptBr: any;
  private idEvento: string;
  private painel: Painel;

  constructor(private formBuilder: FormBuilder,
    private graphqlService: GraphqlService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private router: Router) {
    this.ptBr = ptBr;
    this.form  = this.formBuilder.group({
      titulo: ['', Validators.required],
      tipo: ['', Validators.required],
      link: ['', Validators.required],
      data: [new Date(), Validators.required]
    });
  }

  public atualizarPainel() {
    this.painel.titulo = this.form.get('titulo').value;
    this.painel.tipo = this.form.get('tipo').value;
    this.painel.link = this.form.get('link').value;
    this.painel.data = this.formatarDateParaString(this.form.get('data').value);;
    this.graphqlService.atualizarPainel(this.painel).subscribe(
      () => {
        this.router.navigateByUrl(`/adm/evento-editor/${this.idEvento}`);
        this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'O painel foi criado com sucesso.' });
      }, error => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde. ' + error });
      }
    )
  }

  private iniciarForm(p: Painel): void {
    this.form  = this.formBuilder.group({
      titulo: [p.titulo, Validators.required],
      tipo: [p.tipo, Validators.required],
      link: [p.link, Validators.required],
      data: [this.formatarStringParaDate(p.data), Validators.required]
    });
  }

  public voltar(): void {
    this.router.navigateByUrl(`/adm/evento-editor/${this.idEvento}`);
  }

  public formatarStringParaDate(s: string): Date {
    return moment(s, 'DD/MM/YYYY').toDate()
  }

  public formatarDateParaString(data: Date): string {
    return moment(data).format('DD/MM/YYYY');
  }

  ngOnInit() {
    this.idEvento = this.route.snapshot.params['idEvento'];
    const idPainel = this.route.snapshot.params['idPainel'];
    this.graphqlService.obtemPainel(idPainel).subscribe(
      p => {
        this.painel = p;
        this.iniciarForm(p);
      }
    )
  }


}
