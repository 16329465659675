import { Component, OnInit } from '@angular/core';
import { GraphqlService, PessoaFisica, TipoDocumento } from 'src/app/service/graphql/graphql.service';
import { AutenticacaoService } from 'src/app/service/autenticacao/autenticacao.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-atualiza-dados',
  templateUrl: './atualiza-dados.component.html',
  styleUrls: ['./atualiza-dados.component.css']
})
export class AtualizaDadosComponent implements OnInit {

  public pessoaFisica: PessoaFisica;
  public form: FormGroup;
  public aceitaTermos: boolean;
  public idUsuario: any;
  private readonly regexUrl = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  constructor(private graphqlService: GraphqlService,
    private authService: AutenticacaoService,
    private formBuilder: FormBuilder,
    private messageService: MessageService) {
    this.idUsuario = this.authService.obtemUsuario().id as any;
    this.graphqlService.obtemPessoa(this.idUsuario).subscribe(
      p => {
        this.pessoaFisica = p;
        this.form = this.formBuilder.group({
          nome: [p.nome, Validators.required],
          dataNascimento: [p.dataNascimento, Validators.required],
          sexo: [p.sexo, Validators.required],
          nacionalidade: [p.nacionalidade, Validators.required],
          tipoDocumento: [p.tipoDocumento, Validators.required],
          instituicaoCadastrado: [p.instituicaoCadastrado],
          outroDocumentoTipo: [p.outroDocumentoTipo, p.tipoDocumento === TipoDocumento.OUTRO ? Validators.required : null],
          outroDocumentoValor: [p.outroDocumentoValor, p.tipoDocumento !== TipoDocumento.CPF ? Validators.required : null],
          cpf: [p.cpf, p.tipoDocumento === TipoDocumento.CPF ? Validators.required : null],
          instituicaoNome: [{value: p.instituicaoNome, disabled: !p.instituicaoCadastrado}, Validators.required],
          instituicaoSigla: [{value: p.instituicaoSigla, disabled: !p.instituicaoCadastrado}, Validators.required],
          instituicaoUf: [{value: p.instituicaoUf, disabled: !p.instituicaoCadastrado}, Validators.required],
          formacao: [p.formacao, Validators.required],
          curriculoLattes: [p.urlCurriculoLattes, Validators.pattern(this.regexUrl)],
          email: [p.email, [Validators.required, Validators.email]],
          emailAlt: [p.emailAlt, Validators.email],
          telefone: [p.telefone, Validators.required],
          telefoneAlt: [p.telefoneAlt],
          logradouro: [p.endereco.logradouro, Validators.required],
          numero: [p.endereco.numero, Validators.required],
          complemento: [p.endereco.complemento, Validators.required],
          bairro: [p.endereco.bairro, Validators.required],
          municipio: [p.endereco.municipio, Validators.required],
          uf: [p.endereco.uf, Validators.required],
          cep: [p.endereco.cep, Validators.required],
          pais: [p.endereco.pais, Validators.required]
        });
      }
    );

  }

  ngOnInit() {
  }

  public alteradoTipoDocumento(valor: TipoDocumento): void {
    if (valor === TipoDocumento.CPF) {
      this.form.get('outroDocumentoTipo').setValidators(null);
      this.form.get('outroDocumentoValor').setValidators(null);
      this.form.get('cpf').setValidators(Validators.required);
    } else if (valor === TipoDocumento.PASSAPORTE) {
      this.form.get('outroDocumentoTipo').setValidators(null);
      this.form.get('outroDocumentoValor').setValidators(Validators.required);
      this.form.get('cpf').setValidators(null);
    } else {
      this.form.get('outroDocumentoTipo').setValidators(Validators.required);
      this.form.get('outroDocumentoValor').setValidators(Validators.required);
      this.form.get('cpf').setValidators(null);
    }
    this.form.updateValueAndValidity();
  }

  public temInstituicaoChange(checked: boolean): void {
    if (checked) {
      this.form.get('instituicaoNome').enable();
      this.form.get('instituicaoSigla').enable();
      this.form.get('instituicaoUf').enable();
    } else {
      this.form.get('instituicaoNome').disable();
      this.form.get('instituicaoSigla').disable();
      this.form.get('instituicaoUf').disable();
    }
    this.form.updateValueAndValidity();
  }

  public atualizarDados(): void {
    const data = this.form.value;
    data.id = this.idUsuario;
    data.endereco = {
      logradouro: data.logradouro,
      numero: data.numero,
      complemento: data.complemento,
      bairro: data.bairro,
      municipio: data.municipio,
      uf: data.uf,
      cep: data.cep,
      pais: data.pais,
    }
    this.graphqlService.atualizarPessoaFisica(data).subscribe(
      () => {
        this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'Os dados foram atualizados com sucesso!' });
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.' });
      }
    )
  }

}
