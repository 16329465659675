import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GraphqlService, MapaPpgd } from '../../service/graphql/graphql.service';

@Component({
  selector: 'app-mapa-ppgd-insert',
  templateUrl: './mapa-ppgd-insert.component.html',
  styleUrls: ['./mapa-ppgd-insert.component.css']
})
export class MapaPpgdInsertComponent implements OnInit {

  public form: FormGroup;
  public ufs: any[];
  public msgs: any[];
  private readonly regexUrl = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  constructor(private formBuilder: FormBuilder,
    private graphql: GraphqlService,
    private router: Router) { 

    this.ufs = [
      {label: "Acre", value: "AC"},
      {label: "Alagoas", value: "AL"},
      {label: "Amazonas", value: "AM"},
      {label: "Amapá", value: "AP"},
      {label: "Bahia", value: "BA"},
      {label: "Ceará", value: "CE"},
      {label: "Distrito Federal", value: "DF"},
      {label: "Espírito Santo", value: "ES"},
      {label: "Goiás", value: "GO"},
      {label: "Maranhão", value: "MA"},
      {label: "Minas Gerais", value: "MG"},
      {label: "Mato Grosso do Sul", value: "MS"},
      {label: "Mato Grosso", value: "MT"},
      {label: "Pará", value: "PA"},
      {label: "Paraíba", value: "PB"},
      {label: "Pernambuco", value: "PE"},
      {label: "Piauí", value: "PI"},
      {label: "Paraná", value: "PR"},
      {label: "Rio de Janeiro", value: "RJ"},
      {label: "Rio Grande do Norte", value: "RN"},
      {label: "Rondônia", value: "RO"},
      {label: "Roraima", value: "RR"},
      {label: "Rio Grande do Sul", value: "RS"},
      {label: "Santa Catarina", value: "SC"},
      {label: "Sergipe", value: "SE"},
      {label: "São Paulo", value: "SP"},
      {label: "Tocantins", value: "TO"},
    ];

    this.form = this.formBuilder.group({
      titulo: ['', Validators.required],
      sigla: ['', Validators.required],
      iesNomeCompleto: ['', Validators.required],
      iesSigla: ['', Validators.required],
      cidade: ['', Validators.required],
      uf: ['AC', Validators.required],
      apresentacao: ['', Validators.required],
      telefone: ['', Validators.required],
      email: ['', Validators.required],
      nomeCoordenador: ['', Validators.required],
      urlPrograma: ['', Validators.pattern(this.regexUrl)],
      urlFacebook: ['', Validators.pattern(this.regexUrl)],
      urlTwitter: ['', Validators.pattern(this.regexUrl)],  
      urlOutro: ['', Validators.pattern(this.regexUrl)],
      fgAtivo: [false]
    });
  }

  ngOnInit() {
  }

  public inserirMapaPpgd() {
    const mapa: any = {
      titulo: this.form.get('titulo').value,
      sigla: this.form.get('sigla').value,
      iesNomeCompleto: this.form.get('iesNomeCompleto').value,
      iesSigla: this.form.get('iesSigla').value,
      cidade: this.form.get('cidade').value,
      uf: this.form.get('uf').value,
      apresentacao: this.form.get('apresentacao').value,
      telefone: this.form.get('telefone').value,
      email: this.form.get('email').value,
      nomeCoordenador: this.form.get('nomeCoordenador').value,
      urlPrograma: this.form.get('urlPrograma').value,
      urlFacebook: this.form.get('urlFacebook').value,
      urlTwitter: this.form.get('urlTwitter').value,
      urlOutro: this.form.get('urlOutro').value,
      fgAtivo: this.form.get('fgAtivo').value,
    }

    this.graphql.inserirMapaPpgd(mapa).subscribe(
      () => {
        this.router.navigateByUrl('/mapa-ppgd-list');
      }, () => {
        this.apresentarMensagemDeErro();
      }
    )
  }

  private apresentarMensagemDeErro() {
    this.msgs = [
      {severity:'error', detail:'Ocorreu um erro inesperado'}
    ]
  }

}
