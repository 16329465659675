import { Component, OnInit } from '@angular/core';
import { GraphqlService, QuemSomos } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-quem-somos',
  templateUrl: './quem-somos.component.html',
  styleUrls: ['./quem-somos.component.css']
})
export class QuemSomosComponent implements OnInit {

  public quemSomos: QuemSomos;

  constructor(private graphqlService: GraphqlService) { 
    this.graphqlService.obtemQuemSomos().subscribe(
      q => {
        this.quemSomos = q;
      }
    )
  }

  ngOnInit() {
  }

}
