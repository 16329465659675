import { Component, OnInit, ViewChild } from '@angular/core';
import { GraphqlService, Galeria } from '../../service/graphql/graphql.service';
import { forkJoin } from 'rxjs';

interface FotoArquivo {
  file: File,
  url: string
}

@Component({
  selector: 'app-galeria-list',
  templateUrl: './galeria-list.component.html',
  styleUrls: ['./galeria-list.component.css']
})
export class GaleriaListComponent implements OnInit {

  @ViewChild("descricaoInsercao", { static: true }) descricaoInsercao: any;
  @ViewChild("tituloInsercao", { static: true }) tituloInsercao: any;
  @ViewChild("inputImagemInsercao", { static: true }) inputImagemInsercao: any;
  @ViewChild("descricaoAtualizacao", { static: true }) descricaoAtualizacao: any;
  @ViewChild("tituloAtualizacao", { static: true }) tituloAtualizacao: any;
  public galerias: Galeria[];
  public cols: any[];
  public displayInsercao: boolean;  
  public displayAtualizacao: boolean;
  public displayFotos: boolean;
  public fotos: any[];
  private galeriaSelecionada: Galeria;

  constructor(private graphqlService: GraphqlService) {
    this.atualizarLista();
  }

  private atualizarLista() {
    this.graphqlService.obtemGalerias().subscribe(
      galerias => {
        this.galerias = galerias;
      }
    )
  }

  public abrirInsercao() {
    this.descricaoInsercao.nativeElement.value = "";
    this.tituloInsercao.nativeElement.value = "";
    this.inputImagemInsercao.nativeElement.value = "";
    this.displayInsercao = true;
  }

  public abrirAtualizacao(idGaleria: string) {
    const galeria = this.galerias.find(g => g.id === idGaleria);
    this.descricaoAtualizacao.nativeElement.value = galeria.descricao;
    this.tituloAtualizacao.nativeElement.value = galeria.titulo;
    this.galeriaSelecionada = galeria;
    this.displayAtualizacao = true;
  }

  ngOnInit() {
    this.cols = [
      { field: 'titulo', header: 'Titulo' },
      { field: 'descricao', header: 'Descrição' },
    ];
  }

  public abrirInput(id): void {
    document.getElementById(id).click();
  }

  public isInsercaoHabilitado(): boolean {
    return this.tituloInsercao.nativeElement.value.length > 0 && this.inputImagemInsercao.nativeElement.files.length > 0;
  }

  public isAtualizacaoHabilitado(): boolean {
    return this.tituloAtualizacao.nativeElement.value.length > 0;
  }

  public excluir(idGaleria: string): void {
    this.graphqlService.excluirGaleria(Number(idGaleria)).subscribe(
      () => {
        this.atualizarLista();
      }
    )
  }

  ordenarAcima(galeria: Galeria) {
    this.graphqlService.ordenarGaleria(galeria.id, true).subscribe(
      (galerias) => {
        this.galerias = galerias;
      }
    )
  }

  ordenarAbaixo(galeria: Galeria) {
    this.graphqlService.ordenarGaleria(galeria.id, false).subscribe(
      (galerias) => {
        this.galerias = galerias;
      }
    )
  }

  public abrirEdicaoFoto(idGaleria): void {
    const galeria = this.galerias.find(g => g.id === idGaleria);
    this.galeriaSelecionada = galeria;
    this.fotos = JSON.parse(JSON.stringify(galeria.foto)); // deep clone
    this.displayFotos = true;
  }

  public adicionarFoto(evento): void {
    const arquivo = evento.srcElement.files[0];
    if (arquivo) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const file: any = {
          file: arquivo,
          url: reader.result
        }
        this.fotos.push(file);
      };
      reader.readAsDataURL(arquivo);
    }
    evento.srcElement.value = "";
  }

  public removerFoto(foto) {
    this.fotos = this.fotos.filter(f => f !== foto);
  }

  public inserirGaleria(): void {
    this.graphqlService.inserirGaleria(this.tituloInsercao.nativeElement.value, this.descricaoInsercao.nativeElement.value).subscribe(
      id => {
        const files = this.inputImagemInsercao.nativeElement.files;
        const observables = [];
        for (const file of files) {
          const o = this.graphqlService.uploadFile(file, "imagem_galeria", id);
          observables.push(o);
        }
        forkJoin(observables).subscribe(
          () => {
            this.displayInsercao = false;
            this.atualizarLista();
          }
        )
      }
    )
  }

  public atualizarFotos(): void {
    const novasImagens = this.fotos.filter(f => f.file);
    const imagensRemovidas = this.galeriaSelecionada.foto.filter(f => !this.fotos.find(x => x.url === f.url));
    const observables = [];
    for (const novaImagem of novasImagens) {
      if (novaImagem.file) {
        const o = this.graphqlService.uploadFile(novaImagem.file, "imagem_galeria", this.galeriaSelecionada.id);
        observables.push(o);
      }
    }
    for (const imagemRemovida of imagensRemovidas) {
      const o = this.graphqlService.removerFotoGaleria(imagemRemovida.id);
      observables.push(o);
    }
    forkJoin(observables).subscribe(
      () => {
        this.displayFotos = false;
        this.atualizarLista();
      }
    )
  }

  public atualizarGaleria(): void {
    this.graphqlService.atualizarGaleria(this.galeriaSelecionada.id, this.tituloAtualizacao.nativeElement.value, this.descricaoAtualizacao.nativeElement.value).subscribe(
      () => {
        this.displayAtualizacao = false;
        this.atualizarLista();
      }
    )
  }
}
