import { Component, OnInit } from '@angular/core';
import { ProprietarioNoticia } from '../../service/graphql/graphql.service';

@Component({
  selector: 'app-inicial',
  templateUrl: './inicial.component.html',
  styleUrls: ['./inicial.component.css']
})
export class InicialComponent implements OnInit {

  public proprietarioNoticia: any;

  constructor() { 
    this.proprietarioNoticia = ProprietarioNoticia;
  }

  ngOnInit() {
  }

}
