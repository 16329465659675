import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapaService {


  public bsMostraMapa: BehaviorSubject<boolean>;

  constructor() {
    this.bsMostraMapa = new BehaviorSubject(false);
  }

  mostraMapa() {
    this.bsMostraMapa.next(true);
  }

  escondeMapa() {
    this.bsMostraMapa.next(false);
  }
}
