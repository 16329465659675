import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AutenticacaoService } from '../autenticacao/autenticacao.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(private authService: AutenticacaoService, 
    private router: Router) {}

    canActivate(): boolean {
      if (this.authService.obtemUsuario()) {
        return true;
      }
      this.router.navigateByUrl('');
      return false;
    }

}
