import { Component, OnInit } from '@angular/core';
import { GraphqlService, Galeria } from '../../../service/graphql/graphql.service';

@Component({
  selector: 'app-detalhe-galeria-view',
  templateUrl: './detalhe-galeria-view.component.html',
  styleUrls: ['./detalhe-galeria-view.component.css']
})
export class DetalheGaleriaViewComponent implements OnInit {

  public galerias: Galeria[];

  constructor(private graphqlService: GraphqlService) { 
    this.graphqlService.obtemGalerias().subscribe(
      galerias => {
        this.galerias = galerias;
      }
    )
  }

  ngOnInit() {
  }

}
