import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GraphqlService, Trabalho, Evento, GrupoTrabalhoPoster, GrupoTrabalho } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-publicacao-lista-trabalho',
  templateUrl: './publicacao-lista-trabalho.component.html',
  styleUrls: ['./publicacao-lista-trabalho.component.css']
})
export class PublicacaoListaTrabalhoComponent implements OnInit {

  public trabalhos: Trabalho[];
  public evento: Evento;
  public grupoTrabalho: GrupoTrabalho;
  public idTrabalhoDownload: any;

  constructor(private route: ActivatedRoute,
    private graphqlService: GraphqlService) { }

  ngOnInit() {
    const tipo = this.route.snapshot.params['tipo'];
    const idGrupoTrabalho = this.route.snapshot.params['idGt'];
    const idEvento = this.route.snapshot.params['idEvento'];
    this.graphqlService.obtemEvento(idEvento).subscribe(
      e => {
        this.evento = e;
      }
    );
    if (tipo === "poster") {
      this.graphqlService.obtemGrupoDeTrabalhoPoster(idGrupoTrabalho).subscribe(
        g => {
          this.grupoTrabalho = g;
        }
      );
      this.graphqlService.obtemTrabalhosGrupoTrabalhoPoster(idGrupoTrabalho).subscribe(
        t => {
          this.trabalhos = t;
        }
      )
    } else {
      this.graphqlService.obtemGrupoDeTrabalho(idGrupoTrabalho).subscribe(
        g => {
          this.grupoTrabalho = g;
        }
      )
      this.graphqlService.obtemTrabalhosGrupoTrabalho(idGrupoTrabalho).subscribe(
        t => {
          this.trabalhos = t;
        }
      )
    }
  }

  mapearNomeAutores(trabalho: Trabalho): string {
    return trabalho.orientador.concat(trabalho.autor).map(a => a.nome).join(', ');
  }

  getParagrafos(grupoTrabalho: GrupoTrabalhoPoster) {
    return grupoTrabalho.textoApresentacao.split(/\n/);
  }

  gerarPdfArtigoPublicacao(trabalho: Trabalho) {
    this.idTrabalhoDownload = trabalho.id
    this.graphqlService.geraPdfPublicacao(trabalho.id).subscribe(
      url => {
        window.open(url, "_blank");
        this.idTrabalhoDownload = null;
      }, () => {
        this.idTrabalhoDownload = null;
      }
    )
  }
}
