import { Component, OnInit, Input } from '@angular/core';
import { MapaPpgd, GraphqlService } from 'src/app/service/graphql/graphql.service';
import { MapaService } from 'src/app/service/mapa/mapa.service';

interface Conteudo {
  estado: string,
  mapasPpgd: MapaPpgd[]
}

@Component({
  selector: 'app-mapa-ppgd-base',
  templateUrl: './mapa-ppgd-base.component.html',
  styleUrls: ['./mapa-ppgd-base.component.css']
})
export class MapaPpgdBaseComponent implements OnInit {

  @Input() contrast: boolean;
  private mapasPpgd: MapaPpgd[];
  public conteudo: Conteudo;
  public estados: Map<string, string>;

  constructor(private graphqlService: GraphqlService) {
    this.graphqlService.obtemMapasPpgd().subscribe(
      mapas => {
        this.mapasPpgd = mapas;
      }
    );
    this.criarMapaEstados();
  }

  ngOnInit() {
  }
  
  private criarMapaEstados() {
    this.estados = new Map();
    this.estados.set('TO', 'Tocantins (TO)');
    this.estados.set('BA', 'Bahia (BA)');
    this.estados.set('SE', 'Sergipe (SE)');
    this.estados.set('PE', 'Pernambuco (PE)');
    this.estados.set('AL', 'Alagoas (AL)');
    this.estados.set('RN', 'Rio Grande do Norte (RN)');
    this.estados.set('CE', 'Ceará (CE)');
    this.estados.set('PI', 'Piauí (PI)');
    this.estados.set('MA', 'Maranhão (MA)');
    this.estados.set('AP', 'Amapá (AP)');
    this.estados.set('PA', 'Pará (PA)');2
    this.estados.set('RR', 'Roraima (RR)');
    this.estados.set('AM', 'Amazonas (AM)');
    this.estados.set('AC', 'Acre (AC)');
    this.estados.set('RO', 'Rondônia (RO)');
    this.estados.set('MT', 'Mato Grosso (MT)');
    this.estados.set('MS', 'Mato Grosso do Sul (MS)');
    this.estados.set('GO', 'Goiás (GO)');
    this.estados.set('PR', 'Paraná (PR)');
    this.estados.set('SC', 'Santa Catarina (SC)');
    this.estados.set('RS', 'Rio Grande do Sul (RS)');
    this.estados.set('SP', 'São Paulo (SP)');
    this.estados.set('MG', 'Minas Gerais (MG)');
    this.estados.set('RJ', 'Rio de Janeiro (RJ)');
    this.estados.set('ES', 'Espírito Santo (ES)');
    this.estados.set('DF', 'Distrito Federal (DF)');
    this.estados.set('PB', 'Paraíba (PB)');
  }

  selecionarEstado(estado: string) {
    this.conteudo = {
      estado: this.estados.get(estado),
      mapasPpgd: this.mapasPpgd.filter(m => m.uf === estado)
    }
  }

}
