import { Component, OnInit, ViewChild } from '@angular/core';
import { GraphqlService, Hotsite } from '../../service/graphql/graphql.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-hotsite',
  templateUrl: './hotsite.component.html',
  styleUrls: ['./hotsite.component.css']
})
export class HotsiteComponent implements OnInit {

  public hotsites: Hotsite[];
  public apresentarModalExcluir: boolean;
  public displayModal: boolean;
  public form: FormGroup;
  public hotsiteEditando: Hotsite;
  private readonly regexNoSlash = /^[^\/]+$/

  constructor(private graphqlService: GraphqlService,
    private formBuilder: FormBuilder,
    private messageService: MessageService) {
    this.atualizarListaHotiste();
  }

  ngOnInit() {}

  private atualizarListaHotiste() {
    this.graphqlService.obtemHotsites().subscribe(
      hotsites => {
        this.hotsites = hotsites;
      }
    )
  }

  public abrirInsercao() {
    this.form = this.formBuilder.group({
      descricao: ['', Validators.required],
      caminho: ['', [Validators.required, Validators.pattern(this.regexNoSlash)]],
      habilitado: [false]
    });
    this.hotsiteEditando = null;
    this.displayModal = true;
  }

  public abrirEdicao(h: Hotsite) {
    this.form = this.formBuilder.group({
      descricao: [h.descricao, Validators.required],
      caminho: [h.caminho, [Validators.required, Validators.pattern(this.regexNoSlash)]],
      habilitado: [h.habilitado]
    });
    this.hotsiteEditando = h;
    this.displayModal = true;
  }

  public inserirEditarHotsite(): void {
    if (this.hotsiteEditando) {
      return this.editarHotsite();
    }
    return this.inserirHotsite();
  }

  public inserirHotsite(): void {
    const dados = this.form.value;
    this.graphqlService.inserirHotsite(dados.descricao, dados.caminho, dados.habilitado).subscribe(
      () => {
        this.atualizarListaHotiste();
        this.displayModal = false;
        this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'O Hotsite criado com sucesso!' });
      }, err => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.' +  err });
      }
    )
  }

  public editarHotsite(): void {
    const dados = this.form.value;
    this.graphqlService.atualizarHotsite(this.hotsiteEditando.id, dados.descricao, dados.caminho, dados.habilitado).subscribe(
      () => {
        this.atualizarListaHotiste();
        this.displayModal = false;
        this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'O Hotsite atualizado com sucesso!' });
      }, err => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.' +  err });
      }
    )
  }

  public excluirHotsite(hotsite: Hotsite) {
    const id = Number(hotsite.id);
    if (!isNaN(id)) {
      this.graphqlService.excluirHotsite(id).subscribe(
        () => {
          this.atualizarListaHotiste();
        }
      );
    }
  }

  public abrirSelecaoDeArquivosCapa(idHotsiteInfo): void {
    this.abrirSelecaoArquivos(`capa${idHotsiteInfo}`);
  }

  public abrirSelecaoDeArquivosHotsite(idHotsiteInfo): void {
    this.abrirSelecaoArquivos(`hotsite${idHotsiteInfo}`);
  }

  private abrirSelecaoArquivos(id): void {
    document.getElementById(id).click();
  }

  uploadBanner1(event, idHotsiteInfo) {
    this.uploadBanner(event, "imagem_capa", idHotsiteInfo)
  }

  uploadBanner2(event, idHotsiteInfo) {
    this.uploadBanner(event, "imagem_hotsite", idHotsiteInfo);
  }

  uploadBanner(event, tipo: string, id: string) {
    if (event.srcElement.files.length <= 0) {
      return
    }
    const file = event.srcElement.files[0];
    event.srcElement.value = null;
    const type = file.type;
    // existe um filtro no input, mas ele pode ser modificado no navegador com as ferramentas de desenvolvedor
    if (type == "image/png" || type == "image/jpeg" || type == "image/jpg") {
      this.graphqlService.uploadFile(file, tipo, id).subscribe();
    }
  }

}
