import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GraphqlService, Noticia, StatusNoticia } from '../../service/graphql/graphql.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { UploadAdapterHotsite, TipoImagemCkeditor } from '../../service/upload-adapter/upload-adapter-hotsite';

@Component({
  selector: 'app-noticias-edit',
  templateUrl: './noticias-edit.component.html',
  styleUrls: ['./noticias-edit.component.css']
})
export class NoticiasEditComponent implements OnInit {

  public form: FormGroup;
  public idNoticia: number;
  public tiposPublicacao: any[];
  private proprietario: any;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private graphqlService: GraphqlService,
    private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      titulo: ['', Validators.required],
      linhaFina: [''],
      conteudo: ['', Validators.required],
      data: [new Date()],
      publicacao: [StatusNoticia.RASCUNHO, Validators.required],
      horaPublicacao: [new Date()]
    });
    this.proprietario = this.router.getCurrentNavigation().extras.state.proprietario
  }

  ngOnInit() {
    this.atualizar();
    this.tiposPublicacao = [
      { label: "Rascunho (não fica visivel no portal)", value: StatusNoticia.RASCUNHO },
      { label: "Publicado (entra no ar imediatamente)", value: StatusNoticia.PUBLICADO },
      { label: "Agendado para publicar automaticamente (a data de publicação deve estar no futuro)", value: StatusNoticia.AGENDADO },
    ];
  }

  private atualizar() {
    this.idNoticia = Number(this.route.snapshot.params['id']);
    this.graphqlService.obtemNoticia(this.idNoticia).subscribe(
      noticia => {
        const data = moment(noticia.dataNoticia, "DD/MM/YYYY HH:mm:ss").toDate();
        this.form = this.formBuilder.group({
          titulo: [noticia.titulo, Validators.required],
          linhaFina: [noticia.descricao],
          conteudo: [noticia.texto, Validators.required],
          data: [data],
          publicacao: [noticia.status, Validators.required],
          horaPublicacao: [data]
        })
      }
    );
  }

  public atualizarNoticia() {
    const data = moment(this.form.get('data').value).format('DD/MM/YYYY');
    const tempo = moment(this.form.get('horaPublicacao').value).format("HH:mm");
    const dataHora = `${data} ${tempo}`;
    const noticia: any = {
      id: String(this.idNoticia),
      descricao: this.form.get('linhaFina').value,
      status: this.form.get('publicacao').value,
      texto: this.form.get('conteudo').value,
      titulo: this.form.get('titulo').value,
      dataNoticia: dataHora,
      proprietario: this.proprietario
    }
    this.graphqlService.atualizarNoticia(noticia).subscribe(
      () => {
        this.voltar();
      }, (err) => {
        console.log(err);
        alert('Erro');
      }
    )
  }

  public voltar() {
    this.router.navigateByUrl(`adm/noticias-view/${this.idNoticia}`, { state: { proprietario: this.proprietario } });
  }

  onReady(event) {
    event.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new UploadAdapterHotsite(loader, TipoImagemCkeditor.NOTICIA);
    }
    event.plugins.get('InserirImagem').getImagensGaleria = () => {
      return this.graphqlService.obtemArquivosGaleria().toPromise();
    }
  }

}
