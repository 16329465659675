import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SecaoEdital, GraphqlService, Edital } from 'src/app/service/graphql/graphql.service';
import * as moment from 'moment';

@Component({
  selector: 'app-editais',
  templateUrl: './editais.component.html',
  styleUrls: ['./editais.component.css']
})
export class EditaisComponent implements OnInit {

  public tipoSecaoEdital: SecaoEdital;
  public editais: Edital[];

  constructor(private router: Router,
    private graphqlService: GraphqlService) {
    }

  ngOnInit() {
    const tipo = this.router.url.split('/')[2].toUpperCase();
    if (tipo === "DIVERSOS") {
      this.tipoSecaoEdital = SecaoEdital.OUTROS;
    } else if (tipo === "PARCEIROS") {
      this.tipoSecaoEdital = SecaoEdital.PARCEIRO;
    } else {
      this.tipoSecaoEdital = SecaoEdital.CONPEDI;
    }
    this.graphqlService.obtemEditais(this.tipoSecaoEdital).subscribe(
      ee => {
        ee.sort(
          (a, b) => {
            return this.converterStringParaData(b.dtCadastro).getTime() - this.converterStringParaData(a.dtCadastro).getTime();
          }
        );
        this.editais = ee;
      }
    )
  }

  private converterStringParaData(data: string): Date {
    return moment(data, 'DD/MM/YYYY').toDate();
  }
  
}
