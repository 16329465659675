import { Component, OnInit } from '@angular/core';
import { AutenticacaoService } from 'src/app/service/autenticacao/autenticacao.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-adm',
  templateUrl: './login-adm.component.html',
  styleUrls: ['./login-adm.component.css']
})
export class LoginAdmComponent implements OnInit {

  public form: FormGroup;

  constructor(private autenticacaoService: AutenticacaoService,
    private formBuilder: FormBuilder,
    private router: Router) {
    this.form = this.formBuilder.group({
      email: ['', Validators.required],
      senha: ['', Validators.required]
    })
  }

  ngOnInit() {
  }

  login() {
    const dados = this.form.value;
    const sub = this.autenticacaoService.bsAutenticacao.subscribe(
      u => {
        if (!u) return
        sub.unsubscribe();
        this.router.navigateByUrl('/');
      }
    )
    this.autenticacaoService.entrarComEmailESenha(dados.email, dados.senha);
  }

}
