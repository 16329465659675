import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-colunista-portal-view',
  templateUrl: './colunista-portal-view.component.html',
  styleUrls: ['./colunista-portal-view.component.css']
})
export class ColunistaPortalViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
