import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AutenticacaoService } from '../autenticacao/autenticacao.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

  constructor(private authService: AutenticacaoService,
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const usuario = this.authService.obtemUsuario();
    const roles = route.data.roles;
    if ((!roles) || (usuario && roles && roles.some(r => usuario.roles.includes(r)))) {
      return true;
    }
    this.router.navigateByUrl('');
    return false;
  }
}
