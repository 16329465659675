import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GraphqlService, DiretoriaGrupo } from 'src/app/service/graphql/graphql.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-diretoria-upsert',
  templateUrl: './diretoria-upsert.component.html',
  styleUrls: ['./diretoria-upsert.component.css']
})
export class DiretoriaUpsertComponent implements OnInit {

  public form: FormGroup
  public diretoriaGrupo: DiretoriaGrupo;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private graphqlService: GraphqlService,
    private messageService: MessageService,
    private router: Router) {}

  ngOnInit() {
    const idDiretoriaGrupo = this.route.snapshot.params['idDiretoriaGrupo'];
    if (idDiretoriaGrupo) {
      this.graphqlService.obtemDiretoriaGrupo(idDiretoriaGrupo).subscribe(
        d => {
          this.diretoriaGrupo = d;
          this.form = this.formBuilder.group({
            nomeGrupo: [d.nomeGrupo, Validators.required]
          })
        }
      )
    } else {
      this.form = this.formBuilder.group({
        nomeGrupo: ['', Validators.required]
      })
    }
  }

  public inserirEditarGrupo() {
    if (this.diretoriaGrupo) {
      return this.editarGrupo();
    }
    return this.inserirGrupo();
  }

  private editarGrupo() {
    this.graphqlService.atualizaDiretoriaGrupo(this.diretoriaGrupo.id, this.form.value.nomeGrupo).subscribe(
      () => {
        this.router.navigateByUrl('/adm/diretoria-list');
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.'});
      }
    )
  }

  private inserirGrupo() {
    this.graphqlService.insereDiretoriaGrupo(this.form.value.nomeGrupo).subscribe(
      () => {
        this.router.navigateByUrl('/adm/diretoria-list')
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.'});
      }
    )
  }

}
