import { Component, OnInit, Input } from '@angular/core';
import { GraphqlService, LinhaPesquisa } from 'src/app/service/graphql/graphql.service';
import { Observable } from 'apollo-link';

@Component({
  selector: 'app-gtp-listar',
  templateUrl: './gtp-listar.component.html',
  styleUrls: ['./gtp-listar.component.css']
})
export class GtpListarComponent implements OnInit {

  @Input() idEvento: string;
  public linhasDePesquisa: LinhaPesquisa[];
  
  constructor(private graphqlService: GraphqlService) { }

  ngOnInit() {
    this.graphqlService.obtemLinhaDePesquisaEnvento(this.idEvento, false, true).subscribe(
      linhasDePesquisa => {
        this.linhasDePesquisa = linhasDePesquisa;
      }
    );
  }

}
