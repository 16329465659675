import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/primeng';
import { AutenticacaoService } from 'src/app/service/autenticacao/autenticacao.service';
import { routes } from 'src/app/app.routes';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  public items: MenuItem[];
  private template: any[];

  constructor(private autenticacaoService: AutenticacaoService) {
    this.items = [];
    this.template = [
      {
        label: 'Conpedi',
        items: [
          { label: 'Evento', routerLink: ['/adm/evento'] },
          { label: 'Inicial', routerLink: [''] }, // nao contem roles entao todos podem acessar
        ]
      },
      {
        label: 'Gestão de Conteúdo',
        items: [
          { label: 'Configurações gerais', routerLink: ['/adm/configuracao-geral'] },
          { label: 'Contrução do hotsite', routerLink: ['/adm/hotsite'] },
          { label: 'Galeria', routerLink: ['/adm/galeria'] },
          { label: 'Notícias', routerLink: ['/adm/noticias-list'] },
          { label: 'Banners', routerLink: ['/adm/banner-list'] },
          { label: 'Menu Lateral', routerLink: ['/adm/menu-lateral-list'] },
          { label: 'Colunistas', routerLink: ['/adm/colunistas-list'] },
          { label: 'TV Conpedi', routerLink: ['/adm/tv-conpedi-list'] },
          { label: 'Mapa PPGDs', routerLink: ['/adm/mapa-ppgd-list'] },
          { label: 'Editais', routerLink: ['/adm/edital-list'] },
          { label: 'Agenda', routerLink: ['/adm/agenda-list'] },
          { label: 'Revista Conpedi', routerLink: ['/adm/revista-conpedi-list'] },
          { label: 'Espaço do Autor', routerLink: ['/adm/espaco-autor-list'] },
        ]
      },
      { label: 'Logout', command: () => this.autenticacaoService.logout() },
    ];

    const usuario = this.autenticacaoService.obtemUsuario();
    for (const t of this.template) {
      const menu = {
        label: t.label,
        items: undefined
      };
      if (t.items) {
        menu.items = []
        for (const i of t.items) {
          if (i.routerLink[0].includes("/adm/")) {
            i.roles = routes[0].children[0].children.find(c => c.path === i.routerLink[0].replace('/adm/', '')).data.roles;
          } else {
            const route = routes.find(r => r.path === i.routerLink[0]);
            if (route && route.data) {
              i.roles = route.data.roles;
            }
          }
          if (!i.roles || i.roles.some(r => usuario.roles.includes(r))) {
            menu.items.push(i);
          }
        }
        if (menu.items.length > 0) {
          this.items.push(menu);
        }
      } else {
        this.items.push(t)
      }
      // if (t.items) {
      //   menu.items = []
      //   for (const i of t.items) {
      //     if (i.routerLink[0].includes("/adm/")) {
      //       i.roles = routes[0].children.find(c => c.path === i.routerLink[0].replace('/adm/', '')).data.roles;
      //     } else {
      //       const route = routes.find(r => r.path === i.routerLink[0]);
      //       if (route && route.data) {
      //         i.roles = route.data.roles;
      //       }
      //     }
      //     if (!i.roles || i.roles.some(r => usuario.roles.includes(r))) {
      //       menu.items.push(i);
      //     }
      //   }
      //   if (menu.items.length > 0) {
      //     this.items.push(menu);
      //   }
      // } else {
      //   this.items.push(t)
      // }
    }
  }

  ngOnInit() {

  }

}
