import { Component, OnInit } from '@angular/core';
import { GraphqlService } from 'src/app/service/graphql/graphql.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-documentos-texto-introdutorio-edit',
  templateUrl: './documentos-texto-introdutorio-edit.component.html',
  styleUrls: ['./documentos-texto-introdutorio-edit.component.css']
})
export class DocumentosTextoIntrodutorioEditComponent implements OnInit {

  public textoIntrodutorioDocumento: string;

  constructor(private graphqlService: GraphqlService,
    private router: Router,
    private messageService: MessageService) {
    this.textoIntrodutorioDocumento = '';
    this.graphqlService.obtemTextoIntrodutorioDocumeto().subscribe(
      t => {
        this.textoIntrodutorioDocumento = t;
      }
    );
  }

  ngOnInit() {
  }

  public salvar(): void {
    this.graphqlService.atualizaDescricaoDocumento(this.textoIntrodutorioDocumento).subscribe(
      () => {
        this.router.navigateByUrl('/adm/documentos-texto-introdutorio')
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.' });
      }
    )
  }

  public cancelar(): void {
    this.router.navigateByUrl('/adm/documentos-texto-introdutorio')
  }


}
