import { Component, OnInit } from '@angular/core';
import { GraphqlService, DescricaoQuemSomos } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-quem-somos-sobre-gerenciar',
  templateUrl: './quem-somos-sobre-gerenciar.component.html',
  styleUrls: ['./quem-somos-sobre-gerenciar.component.css']
})
export class QuemSomosSobreGerenciarComponent implements OnInit {

  public sobre: DescricaoQuemSomos;
  public processandoImagem: boolean;

  constructor(private graphqlService: GraphqlService) {
    this.graphqlService.obtemQuemSomos().subscribe(
      q => {
        this.sobre = q.sobre;
      }
    )
  }

  ngOnInit() {
  }

  public alterarImagem(file: File): void {
    this.processandoImagem = true;
    this.graphqlService.uploadFile(file, "imagem_sobre").subscribe(
      (url) => {
        this.processandoImagem = false;
        this.sobre.imagem = {
          id: "",
          url: url
        }
      }
    )
  }

  public removerImagem(): void {
    
  }

}
