import { Component, OnInit, ViewChild } from '@angular/core';
import { GraphqlService, Arquivo } from '../../service/graphql/graphql.service';

@Component({
  selector: 'app-revista-conpedi-list',
  templateUrl: './revista-conpedi-list.component.html',
  styleUrls: ['./revista-conpedi-list.component.css']
})
export class RevistaConpediListComponent implements OnInit {

  public arquivo: Arquivo;
  @ViewChild("input", {static: true}) input: any;

  constructor(private graphqlService: GraphqlService) { 
    this.atualizar();
  }

  private atualizar() {
    this.graphqlService.obtemFotoRevista().subscribe(
      arquivo => {
        this.arquivo = arquivo;
      }
    )
  }

  uploadArquivo() {
    const arquivo = this.input.nativeElement.files[0];
    this.input.nativeElement.value = "";
    this.graphqlService.uploadFile(arquivo, "imagem_revista").subscribe(
      () => {
        this.atualizar();
      }
    );

  }

  ngOnInit() {
  }

}
