import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GraphqlService, Trabalho, GrupoTrabalhoPoster, Evento } from 'src/app/service/graphql/graphql.service';
import { MessageService } from 'primeng/api';
declare var $: any;

@Component({
  selector: 'app-gtp-edita',
  templateUrl: './gtp-edita.component.html',
  styleUrls: ['./gtp-edita.component.css'],
  providers: [MessageService]
})
export class GtpEditaComponent implements OnInit {

  private idLinhaPesquisa: string;
  public trabalhos: Trabalho[];
  public gruposTrabalho: GrupoTrabalhoPoster[];
  private jqueryIniciado: boolean;
  public nomeLinhaPesquisa: string;
  public numRomano: string[];
  public evento: Evento;

  constructor(private route: ActivatedRoute,
    private graphqlService: GraphqlService,
    private messageService: MessageService) {
    this.numRomano = ['I', 'II', 'III', 'IV', 'V']
  }

  ngOnInit() {
    this.idLinhaPesquisa = this.route.snapshot.params['id'];
    this.graphqlService.obtemGrupoDeTrabalhoLinhaDePesquisa(this.idLinhaPesquisa).subscribe(
      grupos => {
        this.gruposTrabalho = grupos.filter(g => g.ativo);
        if (this.gruposTrabalho.length > 0) {
          this.nomeLinhaPesquisa = this.gruposTrabalho[0].linhaPesquisa.descricao;
        }
      }
    );
    this.graphqlService.obtemtrabalhosLinhaDePesquisaPoster(this.idLinhaPesquisa).subscribe(
      trabalhos => {
        this.trabalhos = trabalhos;
      }
    );
    this.graphqlService.obtemEventoLinhaPesquisa(this.idLinhaPesquisa).subscribe(
      evento => {
        this.evento = evento;
      }
    );
  }

  ngAfterViewInit() {

  }

  iniciaJquery() {
    if (!this.jqueryIniciado) {
      setTimeout(() => {
        $(this.gruposTrabalho.map(g => `#sortable_${g.id}`).join(',')).sortable({
          connectWith: ".connectedSortable"
        }).disableSelection();
        this.jqueryIniciado = true;
      }, 0)
    }
  }

  trabalhosGrupo(trabalhos: Trabalho[], grupo: GrupoTrabalhoPoster): Trabalho[] {
    var t: Trabalho[] = [];
    for (const trabalho of trabalhos) {
      // trabalho que não pertence a nenhum grupo
      if (!trabalho.grupoTrabalhoPoster && this.gruposTrabalho.length > 0 && grupo.id === this.gruposTrabalho[0].id) {
        t.push(trabalho);
      }
      // trabalho que pertence a um grupo que não está mais ativo
      else if (trabalho.grupoTrabalhoPoster && this.gruposTrabalho.length > 0 && grupo.id === this.gruposTrabalho[0].id && !trabalho.grupoTrabalhoPoster.ativo) {
        t.push(trabalho);
      } else if (trabalho.grupoTrabalhoPoster && trabalho.grupoTrabalhoPoster.id === grupo.id) {
        t.push(trabalho);
      }
    }
    return t;
  }

  gravar() {
    var res = [];
    for (const grupo of this.gruposTrabalho) {
      var itens = document.getElementById('sortable_' + grupo.id).getElementsByClassName('item');
      for (var i = 0; i < itens.length; i++) {
        const item = itens[i];
        res.push({ idGrupo: parseInt(grupo.id), idTrabalho: parseInt(item.id.substr(item.id.indexOf('_') + 1)), ordem: i + 1 })
      }
    }
    this.graphqlService.atualizaGruposTrabalhoOrdem(res).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'Grupos atualizados' })
      }
    )
  }

}
