import { Component, OnInit, ViewChild } from '@angular/core';
import { GraphqlService, Colunista } from '../../service/graphql/graphql.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-colunista-view',
  templateUrl: './colunista-view.component.html',
  styleUrls: ['./colunista-view.component.css']
})
export class ColunistaViewComponent implements OnInit {

  public colunista: Colunista;
  @ViewChild('input', { static: true}) input: any;

  constructor(private graphqlService: GraphqlService, private route: ActivatedRoute) { 
    this.atualizar();
  }

  ngOnInit() {

  }

  private atualizar() {
    const idHotsiteInfo = Number(this.route.snapshot.params['id']);
    this.graphqlService.obtemColunista(idHotsiteInfo).subscribe(
      colunista => {
        this.colunista = colunista;
      }
    )
  }

  public publicar() {
    this.graphqlService.alterarStatusPublicacaoColunista(this.colunista.id, true).subscribe(
      () => {
        this.atualizar();
      }
    );
  }

  public removerPublicacao() {
    this.graphqlService.alterarStatusPublicacaoColunista(this.colunista.id, false).subscribe(
      () => {
        this.atualizar();
      }
    );
  }

  public alterarFoto() {
    const file = this.input.nativeElement.files[0];
    if (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg") {
      this.graphqlService.uploadFile(file, "imagem_colunista", this.colunista.id).subscribe(
        () => {
          this.atualizar();
        }
      )
    }
    this.input.nativeElement.value = "";
  }


}
