import { Component } from '@angular/core';
import { UploadAdapterHotsite, TipoImagemCkeditor } from '../../service/upload-adapter/upload-adapter-hotsite';
import { ActivatedRoute, Router } from '@angular/router';
import { GraphqlService, HotsiteSecao } from "../../service/graphql/graphql.service";

@Component({
  selector: 'app-hotsite-editor',
  templateUrl: './hotsite-editor.component.html',
  styleUrls: ['./hotsite-editor.component.css']
})
export class HotsiteEditorComponent {

  public nomeNovaSecao: string;
  public secoes: HotsiteSecao[] = [];
  private idHotsiteInfo: number;

  constructor(private route: ActivatedRoute, private router: Router, private graphqlService: GraphqlService) {
    this.nomeNovaSecao = '';
  }

  onReady(event) {
    event.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new UploadAdapterHotsite(loader, TipoImagemCkeditor.HOTSITE);
    }
    event.plugins.get('InserirImagem').getImagensGaleria = () => {
      return this.graphqlService.obtemArquivosGaleria().toPromise();
    }
  }

  ngOnInit() {
    this.idHotsiteInfo = Number(this.route.snapshot.params['id']);
    this.inicializa();
  }

  public excluirSecao(secao: HotsiteSecao) {
    const index = this.secoes.findIndex(x => x.ordem === secao.ordem)
    if (typeof (index) === 'number') {
      this.secoes.splice(index, 1);
      this.corrigirOrdem();
    }
  }

  public subirSecao(secao: HotsiteSecao) {
    const index = this.secoes.findIndex(x => x.ordem === secao.ordem)
    if (typeof (index) === 'number') {
      if (index === 0) {
        return
      }
      let temp = this.secoes[index - 1]
      this.secoes[index - 1] = this.secoes[index]
      this.secoes[index] = temp
      this.corrigirOrdem();
    }
  }

  public descerSecao(secao: HotsiteSecao) {
    const index = this.secoes.findIndex(x => x.ordem === secao.ordem)
    if (typeof (index) === 'number') {
      if (index === this.secoes.length - 1) {
        return
      }
      const temp = this.secoes[index]
      this.secoes[index] = this.secoes[index + 1]
      this.secoes[index + 1] = temp
      this.corrigirOrdem();
    }
  }

  public adicionaSecao() {
    this.secoes.push({
      id: null,
      conteudo: "",
      ordem: this.secoes.length + 1,
      titulo: this.nomeNovaSecao,
      
    });
    this.nomeNovaSecao = '';
  }

  private corrigirOrdem() {
    let ordem = 1;
    for (const secao of this.secoes) {
      secao.ordem = ordem
      ordem++;
    }
  }

  private async inicializa(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.graphqlService.obtemHotsitePorId(this.idHotsiteInfo).subscribe(
        hotsite => {
          resolve();
          this.secoes = hotsite.secao;
        }, error => {
          reject(error)
        }
      );
    });

  }

  public async gravarDados() {
      let secoes = [];
      for (const secao of this.secoes) {
        secoes.push({ id: Number(secao.id), ordem: secao.ordem, titulo: secao.titulo, conteudo: secao.conteudo });
      }
      this.graphqlService.atualizaSecoesHotsite(this.idHotsiteInfo, secoes).subscribe(
        async () => {
          await this.inicializa();
          alert('As seções foram atualizadas com sucesso!');
        }
      )
  }

  public cancelarEdicao() {
    this.router.navigate(['/hotsite']);
  }

}
