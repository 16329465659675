import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from '@angular/router';
import { GraphqlService, HotsiteSecao, Hotsite } from "../../service/graphql/graphql.service";

interface Secao {
  idHotsiteSecao: number,
  conteudo: string,
  ordem: number,
  titulo: string
}

@Component({
  selector: 'app-hotsite-view',
  templateUrl: './hotsite-view.component.html',
  styleUrls: ['./hotsite-view.component.css']
})
export class HotsiteViewComponent implements OnInit {

  public hotsite: Hotsite;
  public urlImagem: string;
  private caminhoHotsite: string;
  
  constructor( private graphqlService: GraphqlService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.caminhoHotsite = this.route.snapshot.params['caminho'];
    this.inicializa();
  }

  public corrigirHTML(html: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  private async inicializa() {
    this.graphqlService.obtemHotsitePorCaminho(this.caminhoHotsite).subscribe(
      hotsite => {
        this.hotsite = hotsite;
      }
    )
    //var resp = await this.remoteService.remote('hotsite', 'obtemInfo', { idHotsiteInfo: this.idHotsiteInfo });
    //this.secoes = resp['secoes'] as Secao[];
    //this.urlImagem = resp.hotsiteInfo.urlFileInicial || resp.hotsiteInfo.urlFileHotsite;
  }

}
