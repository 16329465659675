import { Component, OnInit } from '@angular/core';
import { GraphqlService, FiltroAprovacaoTrabalho, FiltroAutor, OrdenacaoTrabalho, TipoTrabalho, Trabalho, Evento, ConfirmacaoAutoria } from 'src/app/service/graphql/graphql.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-trabalhos-list',
  templateUrl: './trabalhos-list.component.html',
  styleUrls: ['./trabalhos-list.component.css']
})
export class TrabalhosListComponent implements OnInit {

  public filtros: any;
  public filtroAprovacaoTrabalho: any[];
  public filtroAutor: any[];
  public filtroOrdenacaoTrabalho: any[];
  public filtroTipoTrabalho: any[];
  public filtroLinhaPesquisa: any[];
  public trabalhos: Trabalho[];
  private idEvento: string;
  public evento: Evento;
  public confAutoria = ConfirmacaoAutoria;

  constructor(private graphqlService: GraphqlService,
    public route: ActivatedRoute,) {
    this.filtroAprovacaoTrabalho = [ 
      { label: "Aprovados ou não para o evento", value: FiltroAprovacaoTrabalho.TODOS },
      { label: "Somente os aprovados para o evento", value: FiltroAprovacaoTrabalho.SOMENTE_APROVADOS },
      { label: "Somente os NÃO aprovados para o evento", value: FiltroAprovacaoTrabalho.SOMENTE_NAO_APROVADOS },
    ];
    this.filtroAutor = [
      { label: "Todos os autores", value: FiltroAutor.TODOS },
      { label: "Autores sem resposta", value: FiltroAutor.SEM_RESPOSTA },
      { label: "Autores confirmados", value: FiltroAutor.CONFIRMADOS },
      { label: "Autores recusados", value: FiltroAutor.RECUSADOS },
    ];
    this.filtroOrdenacaoTrabalho = [
      { label: "Ordenar por", value: null },
      { label: "Titulo", value: OrdenacaoTrabalho.TITULO },
      { label: "Nota", value: OrdenacaoTrabalho.NOTA },
      { label: "Nota presencial (somente pôsteres)", value: OrdenacaoTrabalho.NOTA_PRESENCIAL },
      { label: "Recebimento", value: OrdenacaoTrabalho.RECEBIMENTO }
    ];
    this.filtroTipoTrabalho = [
      { label: "Artigos e pôsteres", value: null },
      { label: "Somente artigos", value: TipoTrabalho.ARTIGO },
      { label: "Somente pôsteres", value: TipoTrabalho.POSTER },
    ]
    this.filtros = {
      titulo: "",
      aprovacaoTrabalho: this.filtroAprovacaoTrabalho[0].value,
      autor: this.filtroAutor[0].value,
      tipoTrabalho: this.filtroTipoTrabalho[0].value,
      ordenar: this.filtroOrdenacaoTrabalho[0].value,
    }
    this.idEvento = this.route.snapshot.params['id'];
    this.atualizarTrabalhos();
    this.graphqlService.obtemEvento(this.idEvento).subscribe(
      e => {
        this.evento = e;
      }
    )
    this.graphqlService.obtemLinhaDePesquisaEnvento(this.idEvento, true, true).subscribe(
      l => {
        this.filtroLinhaPesquisa = [
          { label: "Em todas as linhas de pesquisa", value: null },
          ...l.map(l => {
            return {
              label: l.descricao,
              value: l.id
            }
          })
        ];
        this.filtros.linhaPesquisa = this.filtroLinhaPesquisa[0].value
      }
    )
  }

  ngOnInit() {
  }

  public atualizarTrabalhos(): void {
    this.graphqlService.listaTrabalhos(this.idEvento, this.filtros.linhaPesquisa, this.filtros.tipoTrabalho, this.filtros.titulo, this.filtros.aprovacaoTrabalho, this.filtros.autor, this.filtros.ordenar).subscribe(
      trabalho => {
        this.trabalhos = trabalho
      }
    )
  }

  public converterAnaliseEditorial(a: string): string {
    switch(a) {
      case "REENVIO_SOLICITADO":
        return "Reenvio solicitado"
      case "APROVADO":
        return "Aprovado"
      case "REPROVADO":
        return "Reenvio solicitado"
    }
  }

  public converterTipo(t: string): string {
    if (t === TipoTrabalho.ARTIGO) {
      return "Artigo"
    } else if (t === TipoTrabalho.POSTER) {
      return "Pôster"
    }
  }

}
