import { Component, OnInit, Input } from '@angular/core';
import { GraphqlService, Painel } from 'src/app/service/graphql/graphql.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-painel-evento-lista',
  templateUrl: './painel-evento-lista.component.html',
  styleUrls: ['./painel-evento-lista.component.css']
})
export class PainelEventoListaComponent implements OnInit {

  @Input() idEvento: string;
  public paineis: Painel[];

  constructor(private graphqlService: GraphqlService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService) {

  }

  ngOnInit() {
    this.graphqlService.obtemPaineis(this.idEvento).subscribe(
      p => {
        this.paineis = p;
      }
    );
  }

  public excluirPainel(painel: Painel) {
    this.confirmationService.confirm({
      message: `Você tem certeza que deseja remover o painel "${painel.titulo}"?`,
      accept: () => {
        this.graphqlService.excluirPainel(painel.id).subscribe(
          () => {
            this.paineis = this.paineis.filter(p => p.id !== painel.id);
            this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'O painel foi excluido com sucesso.' });
          }, error => {
            this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde. ' + error });
          }
        )
      }
    })
  }

}
