import { Component, OnInit } from '@angular/core';
import { GraphqlService, Documento, GrupoDocumento } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css']
})
export class DocumentosComponent implements OnInit {

  public textoIntrodutorioDocumento: string;
  public documentos: Documento[];
  public documentosEstatuto: Documento[];
  public documentosOutros: Documento[];
  public documentosAtlas: Documento[];
  public documentosPortarias: Documento[];

  constructor(private graphqlService: GraphqlService) {
    this.documentosEstatuto = [];
    this.documentosOutros = [];
    this.documentosPortarias = [];
    this.documentosAtlas = [];
    this.graphqlService.obtemTextoIntrodutorioDocumeto().subscribe(
      t => {
        this.textoIntrodutorioDocumento = t;
      }
    );
    this.graphqlService.obtemDocumentos().subscribe(
      dd => {
        this.documentos = dd;
        for (const d of dd) {
          if (!d.publicado) continue
          switch(d.grupo) {
            case GrupoDocumento.ESTATUTO:
              this.documentosEstatuto.push(d);
              break
            case GrupoDocumento.ATAS:
              this.documentosAtlas.push(d);
              break
            case GrupoDocumento.PORTARIAS:
              this.documentosPortarias.push(d);
              break
            case GrupoDocumento.OUTROS:
              this.documentosOutros.push(d);
              break
          }
        }
      }
    )
  }

  ngOnInit() {
  }

}
