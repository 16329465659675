import { Component, OnInit } from '@angular/core';
import { GraphqlService, Video } from 'src/app/service/graphql/graphql.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-tvs-conpedi-view',
  templateUrl: './tvs-conpedi-view.component.html',
  styleUrls: ['./tvs-conpedi-view.component.css']
})
export class TvsConpediViewComponent {

  public titulo = "TV CONPEDI";
  public destaque: Video;
  public videos: Video[];
  public urlDestaque = null;

  constructor(private graphqlService: GraphqlService, private router: Router, private sanitizer: DomSanitizer) {
    this.atualizarLista();
  }

  private atualizarLista() {
    var th = this;
    this.graphqlService.obtemVideos().subscribe(
      videos => {
        this.videos = videos;
        for (var v of videos) {
          if (v['destaque']) {
            th.destaque = v;
            const url = "https://www.youtube.com/embed/" + v["codigoYoutube"];
            th.urlDestaque = th.sanitizer.bypassSecurityTrustResourceUrl(url);
          }
        }
      }
    )
  }

  onClick(imagem) {
    //    this.router.navigateByUrl(`adm/colunista-portal-view/${imagem.id}`);
  }

}
