import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { ThrowStmt } from '@angular/compiler';
import { GraphqlService } from 'src/app/service/graphql/graphql.service';
import { MessageService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/api';

interface Formacao {
  value: string
  label: string
}

@Component({
  selector: 'app-edicao-titulacao-formacao',
  templateUrl: './edicao-titulacao-formacao.component.html',
  styleUrls: ['./edicao-titulacao-formacao.component.css']
})
export class EdicaoTitulacaoFormacaoComponent implements OnInit {

  @Input() tipo: string
  @Input() idEvento: string

  public form: FormGroup;
  public titulos: Formacao[];
  public salvando: boolean;

  constructor(private formBuilder: FormBuilder,
    private graphqlService: GraphqlService,
    private messageService: MessageService) {
    this.titulos = [
      {
        value: "GRADUANDO",
        label: "Graduando"
      },
      {
        value: "GRADUADO",
        label: "Graduado"
      },
      {
        value: "ESPECIALIZANDO",
        label: "Especializando"
      },
      {
        value: "ESPECIALISTA",
        label: "Especialista"
      },
      {
        value: "MESTRANDO",
        label: "Mestrando"
      },
      {
        value: "MESTRE",
        label: "Mestre"
      },
      {
        value: "DOUTORANDO",
        label: "Doutorando"
      },
      {
        value: "DOUTOR",
        label: "Doutor"
      },
      {
        value: "OUTRA",
        label: "Outra"
      },
    ]
    this.form = this.formBuilder.group({
      titulosAutores: [null, Validators.required],
      titulos: this.formBuilder.array([])
    })
  }

  ngOnInit() {
    this.graphqlService.obtemEventoFormacaoExigidaAutor(this.idEvento, this.tipo).subscribe(
      ee => {
        this.form = this.formBuilder.group({
          titulosAutores: [ee.map(e => e.formacao), Validators.required],
          titulos: this.formBuilder.array(ee.map(e => {
            return this.formBuilder.group({
              formacaoAutor: [this.titulos.find(t => t.value === e.formacao)],
              formacaoExigida: [e.eventoFormacaoExigida.map(f => f.formacaoExigida), Validators.required]
            })
          }))
        });
      }
    )
  }

  public changeFormacao(formacao: Formacao, checked: boolean): void {
    if (checked) {
      this.adicionarAbaFormacaoExigida(formacao)
    } else {
      this.removerAbaFormacaoExigida(formacao)
    }
  }

  public getControlsTitulo() {
    return (this.form.get('titulos') as FormArray).controls;
  }

  public adicionarAbaFormacaoExigida(formacaoAutor: Formacao): void {
    const titulos = (this.form.get('titulos') as FormArray);
    titulos.push(this.formBuilder.group({
      formacaoAutor: [formacaoAutor],
      formacaoExigida: [[]]
    }));
  }

  public removerAbaFormacaoExigida(formacaoAutor: Formacao): void {
    const titulos = (this.form.get('titulos') as FormArray);
    const i = titulos.value.findIndex(t => t.formacaoAutor.value === formacaoAutor.value);
    if (i >= 0) {
      titulos.removeAt(i)
    }
  }

  public salvar(): void {
    this.salvando = true;
    const titulos = this.form.value.titulos.map(t => {
      return {
        formacaoAutor: t.formacaoAutor.value,
        formacoesExigidas: t.formacaoExigida
      }
    })
    this.graphqlService.defineFormacoesExigidasEvento(this.idEvento, this.tipo, titulos).subscribe(
      () => {
        this.salvando = false;
        this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'Os titulos foram atualizados com sucesso!' });
      }, () => {
        this.salvando = false;
        this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.' });
      }
    )
  }

}
