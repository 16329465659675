import { Component, ViewChild } from '@angular/core';
import { GraphqlService, EspacoAutor } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-espacos-autor-view',
  templateUrl: './espacos-autor-view.component.html',
  styleUrls: ['./espacos-autor-view.component.css']
})
export class EspacosAutorViewComponent {

  @ViewChild("paginator",{static: false}) paginator: any;

  // todos os livros
  private _espacosAutor: EspacoAutor[] = [];
  
  // todos os livros com filtro
  public espacosAutor: EspacoAutor[];

  // todos os livros da página
  public livrosPagina: EspacoAutor[];

  public readonly numItensPagina = 24;

  constructor(private graphqlService: GraphqlService) {
    this.atualizarLista();
  }

  private atualizarLista() {
    this.graphqlService.obtemEspacosAutor().subscribe(
      recs => {
        this._espacosAutor = recs;
        if (!this.espacosAutor) {
          this.espacosAutor = recs;
          this.livrosPagina = recs.slice(0, this.numItensPagina);
        }
      }
    )
  }

  public onSearchChange(filtro: string) {
    this.espacosAutor = this._espacosAutor.filter(
      espaco => {
        const f = filtro.toLowerCase().replace(/ /g,'');
        const dados = espaco.titulo.concat(espaco.autoresPublicacao).toLocaleLowerCase().replace(/ /g,'');
        return dados.includes(f);
      }
    );
    this.livrosPagina = this.espacosAutor.slice(0, this.numItensPagina);
    // reseta o paginador
    this.paginator.changePageToFirst();
  }

  paginate(event) {
    this.livrosPagina = this.espacosAutor.slice(event.first, event.first + this.numItensPagina);
  }

}
