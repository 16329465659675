import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GraphqlService, GrupoTrabalhoPoster, GrupoTrabalho, Trabalho, Painel } from 'src/app/service/graphql/graphql.service';
import { NomeGrupoTrabalhoPipe } from 'src/app/shared/pipes/nome-grupo-trabalho/nome-grupo-trabalho.pipe';
import { timingSafeEqual } from 'crypto';


@Component({
  selector: 'app-links-gts-view',
  templateUrl: './links-gts-view.component.html',
  styleUrls: ['./links-gts-view.component.css']
})
export class LinksGtsViewComponent implements OnInit {

  private idUsuario: string;
  public grupoTrabalhoPoster: Map<string, GrupoTrabalhoPoster>;
  public grupoTrabalho: Map<string, GrupoTrabalho>;
  public grupoTrabalhoPosterCoordenador: GrupoTrabalhoPoster[];
  public grupoTrabalhoCoordenador: GrupoTrabalho[];
  public paineis: Painel[];
  private idEvento: string;

  constructor(private route: ActivatedRoute,
    private graphqlService: GraphqlService,
    private nomeGrupoTrabalhoPipe: NomeGrupoTrabalhoPipe) {
      this.grupoTrabalhoPoster = new Map();
      this.grupoTrabalho = new Map();
    }

  ngOnInit() {
    this.idUsuario = this.route.snapshot.params['idPessoa'];
    this.idEvento = this.route.snapshot.params['idEvento'];
    this.graphqlService.obtemPaineis(this.idEvento).subscribe(
      p => {
        this.paineis = p;
      }
    )
    this.graphqlService.trabalhoPessoaAutorOrientador(this.idUsuario, this.idEvento).subscribe(
      trabalhos => {
        for (const t of trabalhos) {
          if (t.grupoTrabalho) {
            const nome = this.nomeGrupoTrabalhoPipe.transform(t.grupoTrabalho);
            if (this.grupoTrabalho.has(nome)) {
              this.grupoTrabalho.get(nome).trabalhos.push(t)
            } else {
              if (!t.grupoTrabalho.trabalhos) t.grupoTrabalho.trabalhos = [];
              t.grupoTrabalho.trabalhos.push(t);
              this.grupoTrabalho.set(nome, t.grupoTrabalho);
            }
          } else if (t.grupoTrabalhoPoster) {
            const nome = this.nomeGrupoTrabalhoPipe.transform(t.grupoTrabalhoPoster);
            if (this.grupoTrabalhoPoster.has(nome)) {
              this.grupoTrabalhoPoster.get(nome).trabalhos.push(t)
            } else {
              if (!t.grupoTrabalhoPoster.trabalhos) t.grupoTrabalhoPoster.trabalhos = [];
              t.grupoTrabalhoPoster.trabalhos.push(t);
              this.grupoTrabalhoPoster.set(nome, t.grupoTrabalhoPoster);
            }
          }
        }
      }
    );
    this.graphqlService.obtemGrupoTrabalhoPosterCoordenador(this.idUsuario, this.idEvento).subscribe(
      gtps => {
        this.grupoTrabalhoPosterCoordenador = gtps;
      }
    )
    this.graphqlService.obtemGrupoTrabalhoCoordenador(this.idUsuario, this.idEvento).subscribe(
      gts => {
        this.grupoTrabalhoCoordenador = gts;
      }
    )
  }

  public isOrientador(trabalho: Trabalho): boolean {
    return trabalho.orientador.some(o => o.id === this.idUsuario);
  }

}
