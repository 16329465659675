import { Component, OnInit, HostListener } from '@angular/core';
import { MapaService } from 'src/app/service/mapa/mapa.service';
import { GraphqlService } from '../../service/graphql/graphql.service'; 

@Component({
  selector: 'app-mapa-ppgd-view',
  templateUrl: './mapa-ppgd-view.component.html',
  styleUrls: ['./mapa-ppgd-view.component.css']
})
export class MapaPpgdViewComponent implements OnInit {

  mostraMapa = false;

  constructor(private mapaService: MapaService, private graphqlService: GraphqlService) {
    this.mapaService.bsMostraMapa.subscribe((mostra) => this.mostraMapa = mostra);
  }

  ngOnInit() {
  }

  fecharMapa() {
    this.mapaService.escondeMapa();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.fecharMapa();
  }

}
