import { FormControl } from '@angular/forms';

export function maxPalavras(limite: number) {
    return (control: FormControl) => {
        const numPalavras = control.value.match(/(\w+)/g).length;
        if (numPalavras > limite) {
            return {
                maxPalavras: {
                    limite: limite,
                    numPalavras: numPalavras
                }
            }
        }
    }
}