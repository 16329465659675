import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GraphqlService, GrupoTrabalho, Evento, Usuario, Pessoa } from 'src/app/service/graphql/graphql.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AutenticacaoService } from 'src/app/service/autenticacao/autenticacao.service';
import { maxPalavras } from 'src/app/shared/validators/max-palavras';

declare var $: any;

@Component({
  selector: 'app-enviar-artigo',
  templateUrl: './enviar-artigo.component.html',
  styleUrls: ['./enviar-artigo.component.css']
})
export class EnviarArtigoComponent implements OnInit {

  public gruposTrabalho: GrupoTrabalho[];
  public formArtigo: FormGroup;
  public formAutores: FormGroup;
  public formPdf: FormGroup;
  public evento: Evento;
  public indexForm: number;
  public usuario: Usuario;
  public resultadosPesquisaPessoa: Pessoa[];
  public pessoaNaoEncontrada: string;
  public autorQualificacaoEditando: FormGroup;
  private jqueryIniciado: boolean;
  public msgs: any;

  constructor(private route: ActivatedRoute,
    private graphqlService: GraphqlService,
    private formBuilder: FormBuilder,
    private authService: AutenticacaoService) {
    this.indexForm = 0;
    this.msgs = [];
    this.formArtigo = this.formBuilder.group({
      gt: [null, Validators.required],
      titulo: ['', Validators.required],
      resumo: ['', Validators.required],
      palavrasChave: ['', Validators.required],
      tituloEstrangeiro: ['', Validators.required],
      resumoEstrangeiro: ['', Validators.required],
      palavrasChaveEstrangeiro: ['', Validators.required]
    });
    this.usuario = this.authService.obtemUsuario();
    this.formAutores = this.formBuilder.group({
      autores: [this.formBuilder.array(
        [this.formBuilder.group({
          id: this.usuario.id,
          autor: [this.usuario],
          ordem: [1],
          qualificacao: ['']
        })]
      )]
    });
    this.formPdf = this.formBuilder.group({
      pdf: [null, Validators.required],
      enviado: [false]
    })
  }

  public getAutoresControls(): any {
    return this.formAutores.get('autores').value.controls;
  }

  public definirQualificacao(q: string): void {
    this.autorQualificacaoEditando.get('qualificacao').setValue(q);
  }

  ngOnInit() {
    const idEvento = this.route.snapshot.params['idEvento'];
    this.graphqlService.obtemEvento(idEvento).subscribe(
      e => {
        this.evento = e;
        this.formArtigo.get('resumo').setValidators([Validators.required, maxPalavras(e.limitePalavrasResumoArtigo)])
        this.formAutores.get('autores').setValidators([Validators.required, Validators.minLength(e.autoresMinimoArtigo)])
      }
    )
    this.graphqlService.obtemGrupoDeTrabalhoEvento(idEvento).subscribe(
      gtt => {
        this.gruposTrabalho = gtt;
      }
    )
  }

  public procurarAutor(value) {
    this.graphqlService.obtemPessoasPorNome(value, 20).subscribe(
      pessoas => {
        this.resultadosPesquisaPessoa = pessoas;
        if (pessoas.length === 0) {
          this.pessoaNaoEncontrada = value;
        } else {
          this.pessoaNaoEncontrada = null;
        }
      }
    )
  }

  public adicionarNovoAutor(pessoa: Pessoa): void {
    const autores = (this.formAutores.get('autores').value as FormArray);
    // impede de adicionar autores repetidos
    for (const a of autores.value) {
      if (a.id === pessoa.id) {
        return
      }
    }
    autores.push(this.formBuilder.group({
      id: [pessoa.id],
      autor: [pessoa],
      ordem: [2],
      qualificacao: ['']
    }));
  }

  public removerAutor(): void {
    const autores = (this.formAutores.get('autores').value as FormArray);
    autores.removeAt(1);
  }

  public definirAutorEditandoQualificacao(autor: any): void {
    this.autorQualificacaoEditando = autor;
  }

  iniciaJquery() {
    if (!this.jqueryIniciado) {
      $(function () {
        $('#sortable').sortable({
          containment: "parent",
          tolerance: "pointer"
        });
        this.jqueryIniciado = true;
      })
    }
  }

  public atualizarOrdemAutores(): void {
    const ids = $("#sortable").sortable('toArray');
    for (let i = 0; i < ids.length; i++) {
      const formArray = (this.formAutores.get('autores').value as FormArray)
      const index = formArray.controls.findIndex(c => c.get('id').value == ids[i]);
      formArray.at(index).get('ordem').setValue(i + 1);
    }
  }

  public verificarPaginasPDF(): void {
    if (!this.evento.paginaArtigoMin || !this.evento.paginaArtigoMax) {
      this.indexForm = 3
      return
    }
    const reader = new FileReader();
    const fileInfo = this.formPdf.get('pdf').value;
    if (fileInfo) {
      reader.readAsBinaryString(fileInfo);
      reader.onloadend = () => {
        const count = (reader.result as string).match(/\/Type[\s]*\/Page[^s]/g).length;
        if (count < this.evento.paginaArtigoMin || count > this.evento.paginaArtigoMax) {
          this.formPdf.get('pdf').setErrors({numPaginas: count})
        } else {
          this.formPdf.get('pdf').setErrors(null)
          this.formPdf.get('enviado').setValue(true)
          this.indexForm = 3
        }
      }
    }
  }

  public adicionarArquivo(arquivos: File[]): void {
    this.formPdf.get('pdf').setValue(arquivos[0]);
  }

  public getDescricaoGt(): void {
    return this.formArtigo.get('gt').value.linhaPesquisa.descricao;
  }

  public getPalavrasChave(): void {
    return this.formArtigo.get('palavrasChave').value.split(';')
  }

  public getPalavrasChaveEstrangeiro(): void {
    return this.formArtigo.get('palavrasChaveEstrangeiro').value.split(';')
  }
}
