import { Component, OnInit, ViewChild } from '@angular/core';
import { GraphqlService, Colunista } from '../../service/graphql/graphql.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-colunista-editor',
  templateUrl: './colunista-editor.component.html',
  styleUrls: ['./colunista-editor.component.css']
})
export class ColunistaEditorComponent implements OnInit {

  public colunista: Colunista;
  @ViewChild('nome', { static: true}) nome: any;
  @ViewChild('curriculo', { static: true}) curriculo: any;

  constructor(private graphqlService: GraphqlService, private route: ActivatedRoute) { 
    this.atualizar();
  }

  ngOnInit() {

  }

  private atualizar() {
    const idHotsiteInfo = Number(this.route.snapshot.params['id']);
    this.graphqlService.obtemColunista(idHotsiteInfo).subscribe(
      colunista => {
        this.colunista = colunista;
      }
    )
  }

  public salvar() {
    this.graphqlService.atualizarColunista(this.colunista.id, this.nome.nativeElement.value, this.curriculo.nativeElement.value).subscribe(
      () => {
        alert('ok');
      }
    );
  }


}
