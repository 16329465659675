import { Component, OnInit } from '@angular/core';
import { GraphqlService, AgendaInput, Agenda } from 'src/app/service/graphql/graphql.service';
import * as moment from 'moment';
import { ptBr } from 'src/app/shared/calendar.locale';

interface EventoDia {
  data: Date,
  eventos: Agenda[]
}

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit {

  public ptBr: any;
  public isCarregando: boolean;
  public dataSelecionada: AgendaInput;
  public eventos: EventoDia[];
  public datasSelecionadas: Date[];

  constructor(private graphqlService: GraphqlService) {
    const dataAtual = new Date();
    this.ptBr = ptBr;
    this.dataSelecionada = {
      mes: dataAtual.getMonth() + 1,
      ano: dataAtual.getFullYear()
    }
    this.atualizarEventos();
  }

  ngOnInit() {

  }

  private converterStringParaData(data: string): Date {
    return moment(data, 'DD/MM/YYYY').toDate();
  }

  private atualizarEventos(): void {
    this.isCarregando = true;
    this.graphqlService.obtemAgendasPorMesAno(this.dataSelecionada).subscribe(
      ev => {
        const eventos: EventoDia[] = [];
        for (const e of ev) {
          const index = eventos.findIndex( event => {
            return event.data.getTime() === this.converterStringParaData(e.dataItem).getTime();
          });
          if (index != -1) {
            eventos[index].eventos.push(e);
          } else {
            eventos.push({
              data: this.converterStringParaData(e.dataItem),
              eventos: [e]
            });
          }
        }
        this.eventos = eventos;
        const datas = eventos.map(e => e.data);
        this.datasSelecionadas = datas.length > 0 ? datas : null;
      }, () => {

      }, () => {
        this.isCarregando = false;
      }
    );
  }

  public alterarMes(evento: any){
    this.dataSelecionada = {
      mes: evento.month,
      ano: evento.year
    }
    this.atualizarEventos();
  }

  public hasEventos(): boolean {
    return this.eventos && this.eventos.length > 0;
  }

}
