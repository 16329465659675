import { Component, OnInit } from '@angular/core';
import { GraphqlService, Hotsite } from 'src/app/service/graphql/graphql.service';
import { Image, ImageEvent, KS_DEFAULT_BTN_CLOSE } from '@ks89/angular-modal-gallery';
import { Router } from '@angular/router';
import { Observable } from 'apollo-link';
import { merge, forkJoin } from 'rxjs';

@Component({
  selector: 'app-inicial-eventos',
  templateUrl: './inicial-eventos.component.html',
  styleUrls: ['./inicial-eventos.component.css']
})
export class InicialEventosComponent implements OnInit {

  public hotsiteList = [];
  public selecionado = 0;

  constructor(private graphqlService: GraphqlService, private router: Router) {
    forkJoin(this.graphqlService.obtemHotsites(), this.graphqlService.obtemBanners()).subscribe(
      resp => {
        var list = [];
        var p = 0;
        for (const h of resp[0].filter(h => h.habilitado)) {
          if (h.imagemHotsite != null && h.imagemHotsite.url != null) {
            list.push({ id: h.id, posicao: p, urlImagem: h.imagemHotsite.url, link: `/h/${h.caminho}`, isHotsite: true });
            p++;
          }
        }
        for (const b of resp[1]) {
          list.push({ id: b.id, posicao: p, urlImagem: b.imagem.url, link: b.link });
          p++;
        }
        this.hotsiteList = list;
      }
    )
  }

  ngOnInit() {
  }

  onClickLeft() {
    if (this.selecionado == 0) {
      this.selecionado = this.hotsiteList.length - 1;
    } else {
      this.selecionado--;
    }
  }

  onClickRight() {
    if (this.selecionado == this.hotsiteList.length - 1) {
      this.selecionado = 0;
    } else {
      this.selecionado++;
    }
  }

}
