import { Component, OnInit } from '@angular/core';
import { Noticia, GraphqlService, ProprietarioNoticia } from 'src/app/service/graphql/graphql.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-noticia-view',
  templateUrl: './noticia-view.component.html',
  styleUrls: ['./noticia-view.component.css']
})
export class NoticiaViewComponent implements OnInit {

  public idNoticia: number;
  public noticia: Noticia;
  public titulo: string;

  constructor(private graphqlService: GraphqlService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  ngOnInit() {
    this.atualizarPagina();
  }

  public get rotaAtual() {
    return window.location.href;
  }

  public atualizarPagina() {
    this.idNoticia = Number(this.route.snapshot.params['id']);
    this.graphqlService.obtemNoticia(this.idNoticia).subscribe(
      noticia => {
        this.noticia = noticia;
        if (this.noticia.proprietario === ProprietarioNoticia.CONPEDI) {
          this.titulo = "Notícias CONPEDI";
        } else {
          this.titulo = "Notícias PPGDs";
        }
      }
    );
   
  }

}
