import { Component, OnInit } from '@angular/core';
import { GraphqlService, MenuLateral } from '../../service/graphql/graphql.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-inicial-menu-lateral',
    templateUrl: './inicial-menu-lateral.component.html',
    styleUrls: ['./inicial-menu-lateral.component.css']
})
export class InicialMenuLateralComponent implements OnInit {

    public menus: MenuLateral[];

    constructor(private graphqlService: GraphqlService,
        private router: Router) {
        this.menus = [];
        this.graphqlService.obtemMenusLaterais().subscribe(
            menus => {
                this.menus = menus;
            }
        )
    }

    ngOnInit() {
    }


    public abrir(m: MenuLateral): void {
        // https começa com http também
        if (m.link.startsWith("http")) {
            window.open(m.link, "_blank");
        } else {
            this.router.navigateByUrl(m.link)
        }
    }
}
