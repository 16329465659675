import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GraphqlService, GrupoTrabalhoPoster, Evento, GrupoTrabalho } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-publicacao-lista-gt',
  templateUrl: './publicacao-lista-gt.component.html',
  styleUrls: ['./publicacao-lista-gt.component.css']
})
export class PublicacaoListaGtComponent implements OnInit {

  public tipo: string;
  public gruposTrabalho: GrupoTrabalho[];
  public evento: Evento;

  constructor(public route: ActivatedRoute,
    private graphqlService: GraphqlService) { }

  ngOnInit() {
    this.tipo = this.route.snapshot.params['tipo'];
    const idEvento = this.route.snapshot.params['idEvento'];
    this.graphqlService.obtemEvento(idEvento).subscribe(
      e => {
        this.evento = e;
      }
    )
    if (this.tipo === "poster") {
      this.graphqlService.obtemGrupoDeTrabalhoPosterEvento(idEvento).subscribe(
        g => {
          this.gruposTrabalho = g;
        }
      );
    } else {
      this.graphqlService.obtemGrupoDeTrabalhoEvento(idEvento).subscribe(
        g => {
          this.gruposTrabalho = g;
        }
      )
    }
  }

}
