import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documento-foto-upsert',
  templateUrl: './documento-foto-upsert.component.html',
  styleUrls: ['./documento-foto-upsert.component.css']
})
export class DocumentoFotoUpsertComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
