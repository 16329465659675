import { Component, OnInit, Input } from '@angular/core';
import { GraphqlService, GrupoTrabalhoPoster } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-gtp-publicacao-lista',
  templateUrl: './gtp-publicacao-lista.component.html',
  styleUrls: ['./gtp-publicacao-lista.component.css']
})
export class GtpPublicacaoListaComponent implements OnInit {

  @Input() idEvento: string;
  public gruposTrabalho: GrupoTrabalhoPoster[];
  public numRomano: string[];

  constructor(private graphqlService: GraphqlService) {
    this.numRomano = ['I', 'II', 'III', 'IV', 'V']
  }

  ngOnInit() {
    this.graphqlService.obtemGrupoDeTrabalhoPosterEvento(this.idEvento).subscribe(
      g => {
        this.gruposTrabalho = g;
      }
    );
  }

}
