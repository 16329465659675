import { Component, OnInit } from '@angular/core';
import { GraphqlService, Banner, Arquivo } from 'src/app/service/graphql/graphql.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { ArquivoService } from 'src/app/service/arquivo/arquivo.service';
import { MessageService, ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.css']
})
export class BannerListComponent implements OnInit {

  public banners: Banner[];
  public form: FormGroup;
  public bannerEditando: Banner;
  public displayModal: boolean;
  private readonly regexUrl = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  constructor(private graphqlService: GraphqlService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private arquivoService: ArquivoService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) {
      this.atualizarLista();
  }

  ngOnInit() {
  }

  public atualizarLista(): void {
    this.graphqlService.obtemBanners().subscribe(
      bb => {
        this.banners = bb;
      }
    )
  }

  public abrirInsercao(): void {
    this.form = this.formBuilder.group({
      link: ['', [Validators.required, Validators.pattern(this.regexUrl)]],
      imagem: [null, Validators.required]
    })
    this.bannerEditando = null;
    this.displayModal = true;
  }

  public abrirEdicao(b: Banner): void {
    this.form = this.formBuilder.group({
      link: [b.link, [Validators.required, Validators.pattern(this.regexUrl)]],
      imagem: [b.imagem, Validators.required]
    });
    this.bannerEditando = b;
    this.displayModal = true;
  }

  public inserirEditarBanner(): void {
    if (this.bannerEditando) {
      return this.editarBanner();
    }
    return this.inserirBanner();
  }

  public uploadImagem(): void {
    this.dialogService.uploadArquivo({
      header: "Upload Arquivo",
      data: {
        mimeType: 'image/png',
        accept: (a: File) => {
          return new Promise<any>((resolve, reject) => {
            this.arquivoService.upload(a).then(
              res => {
                this.form.get("imagem").setValue({
                  id: res.id,
                  nome: a.name,
                  url: res.url
                });
                resolve()
              }, err => {
                reject(err)
              }
            )
          })
        }
      }
    })
  }

  public editarBanner(): void {
    this.graphqlService.atualizaBanner({
      id: this.bannerEditando.id,
      ... this.form.value
    }).subscribe(
      () => {
        this.atualizarLista();
        this.displayModal = false;
        this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'Banner atualizado com sucesso!' });
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.' });
      }
    )
  }

  public inserirBanner(): void {
    const dados = this.form.value;
    this.graphqlService.insereBanner(dados.imagem.id, dados.link).subscribe(
      () => {
        this.atualizarLista();
        this.displayModal = false;
        this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'Banner criado com sucesso!' });
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.' });
      }
    )
  }

  public excluir(banner: Banner): void {
    this.confirmationService.confirm({
      header: "Excluir Banner",
      message: `Você tem certeza que deseja excluir este banner?`,
      accept: () => {
        this.graphqlService.excluiBanner(banner.id).subscribe(
          () => {
            this.banners.splice(this.banners.findIndex(c => c.id === banner.id), 1);
            this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: 'O banner foi excluído com sucesso.' });
          }, () => {
            this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'Ocorreu um problema inesperado.' });
          }
        )
      }
    })
  }

}
