import { Component, OnInit } from '@angular/core';
import { GraphqlService, DiretoriaGrupo } from 'src/app/service/graphql/graphql.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-diretoria-list',
  templateUrl: './diretoria-list.component.html',
  styleUrls: ['./diretoria-list.component.css']
})
export class DiretoriaListComponent implements OnInit {

  public diretoriaGrupos: DiretoriaGrupo[];

  constructor(private graphqlService: GraphqlService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService) {
    this.graphqlService.obtemDiretoriaGrupos().subscribe(
      dg => {
        this.diretoriaGrupos = dg;
      }
    )
  }

  ngOnInit() {
  }

  public excluirAgrupamento(diretoriaGrupo: DiretoriaGrupo) {
    this.confirmationService.confirm({
      message: `Você tem certeza que deseja remover o agrupamento "${diretoriaGrupo.nomeGrupo}"?`,
      accept: () => {
        this.graphqlService.excluiDiretoriaGrupo(diretoriaGrupo.id).subscribe(
          () => {
            this.diretoriaGrupos = this.diretoriaGrupos.filter(d => d.id !== diretoriaGrupo.id);
            this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'O agrupamento foi excluido com sucesso.' });
          }, error => {
            this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde. ' + error });
          }
        )
      }
    })
  }
}
