import { Component, OnInit, ViewChild } from '@angular/core';
import { GraphqlService, Video } from '../../service/graphql/graphql.service';

@Component({
  selector: 'app-tv-conpedi-list',
  templateUrl: './tv-conpedi-list.component.html',
  styleUrls: ['./tv-conpedi-list.component.css']
})
export class TvConpediListComponent implements OnInit {

  public videos: Video[];
  public videoSelecionado: Video;
  public cols: any[];
  public displayInsercao: boolean;
  public displayAtualizacao: boolean;
  public displayDestaque: boolean;
  public publicadoInsercao: boolean;
  public publicadoAtualizacao: boolean;

  @ViewChild("tituloInsercao", { static: true }) tituloInsercao: any;
  @ViewChild("descricaoInsercao", { static: true }) descricaoInsercao: any;
  @ViewChild("urlInsercao", { static: true }) urlInsercao: any;

  @ViewChild("tituloAtualizacao", { static: true }) tituloAtualizacao: any;
  @ViewChild("descricaoAtualizacao", { static: true }) descricaoAtualizacao: any;
  @ViewChild("urlAtualizacao", { static: true }) urlAtualizacao: any;

  constructor(private graphqlService: GraphqlService) {
    this.atualizarLista();
  }

  private atualizarLista() {
    this.graphqlService.obtemVideos().subscribe(
      videos => {
        this.videos = videos;
      }
    )
  }

  ngOnInit() {
    this.cols = [
      { field: 'titulo', header: 'Titulo do video' },
      { field: 'dataInclusao', header: 'Data da inclusão' },
      { field: 'publicado', header: 'Publicado?' },
      { field: 'destaque', header: 'Destaque?' },
    ];
  }

  public abrirDestaque(video: Video) {
    this.videoSelecionado = video;
    this.displayDestaque = true;
  }

  public definirComoDestaque() {
    this.graphqlService.definirVideoDestaque(this.videoSelecionado.id).subscribe(
      () => {
        this.atualizarLista();
        this.displayDestaque = false;
      }
    );
  }

  public abrirInsercao() {
    this.displayInsercao = true;
    this.publicadoInsercao = false;
    this.tituloInsercao.nativeElement.value = "";
    this.descricaoInsercao.nativeElement.value = "";
    this.urlInsercao.nativeElement.value = "";
  }

  public abrirEdicao(video: Video) {
    this.displayAtualizacao = true;
    this.publicadoAtualizacao = video.publicado;
    this.tituloAtualizacao.nativeElement.value = video.titulo;
    this.descricaoAtualizacao.nativeElement.value = video.descricao;
    this.urlAtualizacao.nativeElement.value = video.urlYoutube;
    this.videoSelecionado = video;
  }

  public inserirVideo():void {
    const titulo = this.tituloInsercao.nativeElement.value;
    const descricao = this.descricaoInsercao.nativeElement.value;
    const url = this.urlInsercao.nativeElement.value;
    this.graphqlService.inserirVideo(titulo, descricao, url, this.publicadoInsercao).subscribe(
      () => {
        this.atualizarLista();
        this.displayInsercao = false;
      }
    )
  }

  public atualizarVideo():void {
    const titulo = this.tituloAtualizacao.nativeElement.value;
    const descricao = this.descricaoAtualizacao.nativeElement.value;
    const url = this.urlAtualizacao.nativeElement.value;
    this.graphqlService.atualizarVideo(this.videoSelecionado.id, titulo, descricao, url, this.publicadoAtualizacao).subscribe(
      () => {
        this.atualizarLista();
        this.displayAtualizacao = false;
      }
    )
  }

  public isInserirHabilidato(): boolean {
    return this.tituloInsercao.nativeElement.value && this.urlInsercao.nativeElement.value;
  }

  public isAtualizarHabilitado(): boolean {
    return this.tituloAtualizacao.nativeElement.value && this.urlAtualizacao.nativeElement.value;
  }

  public excluir(video: Video) {
    this.graphqlService.excluirVideo(video.id).subscribe(
      () => {
        this.atualizarLista();
      }
    )
  }
}
