import { Injectable } from '@angular/core';
import { AutenticacaoService } from '../autenticacao/autenticacao.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthLoginExternoService {

  constructor(private authService: AutenticacaoService,
    private router: Router){

  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    this.authService.loginExterno(route.params.token);
    this.router.navigateByUrl('')
    return true
  }

}
