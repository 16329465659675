import { Component, OnInit, Input } from '@angular/core';
import { Documento } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-secao-documento',
  templateUrl: './secao-documento.component.html',
  styleUrls: ['./secao-documento.component.css']
})
export class SecaoDocumentoComponent implements OnInit {

  @Input() titulo: string;
  @Input() documentos: Documento[];

  constructor() { }

  ngOnInit() {
  }

}
