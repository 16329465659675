import { Component, OnInit } from '@angular/core';
import { Imagem } from '../inicial-mostra-imagens/inicial-mostra-imagens.component';
import { GraphqlService } from 'src/app/service/graphql/graphql.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inicial-midia',
  templateUrl: './inicial-midia.component.html',
  styleUrls: ['./inicial-midia.component.css']
})
export class InicialMidiaComponent implements OnInit {


  imagens: Imagem[] = [
    new Imagem("1", "https://img.youtube.com/vi/UAWYzgvK8Ys/mqdefault.jpg", "TV CONPEDI", "Assista aos vídeos da TV Conpedi"),
    new Imagem("2", "assets/images/15gsh85f80bl05n3.png", "REVISTA CONPEDI", "Veja a revista do CONPEDI"),
    new Imagem("3", "assets/images/8cct89il393a2885.png", "ESPAÇO DO AUTOR", "Conheça as obras dos nossos associados")
  ];
  public descricaoTipo = "Mídias";

  constructor(private graphqlService: GraphqlService, private router: Router) { }

  ngOnInit() {
  }

  onClick(imagem) {
    if (imagem.id == "1") {
      this.router.navigateByUrl(`/tvs-conpedi-view`);
    }
    if (imagem.id == "2") {
      window.open('https://issuu.com/conpedi', '_');
    }
    if (imagem.id == "3") {
      this.router.navigateByUrl(`/espacos-autor-view`);
    }
  }

}
