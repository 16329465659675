import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-inicial-mostra-imagens',
  templateUrl: './inicial-mostra-imagens.component.html',
  styleUrls: ['./inicial-mostra-imagens.component.css']
})
export class InicialMostraImagensComponent implements OnInit {

  @Input() imagens: string;

  constructor() { }

  ngOnInit() {
  }

}

export class Imagem {
  constructor(public id: String, public url: String, public texto: String, public data: String) { }
}