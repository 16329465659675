import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GraphqlService, MapaPpgd } from '../../service/graphql/graphql.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-mapa-ppdg-edit',
  templateUrl: './mapa-ppdg-edit.component.html',
  styleUrls: ['./mapa-ppdg-edit.component.css']
})
export class MapaPpdgEditComponent implements OnInit {

  public form: FormGroup;
  public ufs: any[];
  private id: string;
  public msgs: any[];
  private readonly regexUrl = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

  constructor(private formBuilder: FormBuilder,
    private graphql: GraphqlService,
    private route: ActivatedRoute,
    private router: Router) { 
  
      this.ufs = [
        {label: "Acre", value: "AC"},
        {label: "Alagoas", value: "AL"},
        {label: "Amazonas", value: "AM"},
        {label: "Amapá", value: "AP"},
        {label: "Bahia", value: "BA"},
        {label: "Ceará", value: "CE"},
        {label: "Distrito Federal", value: "DF"},
        {label: "Espírito Santo", value: "ES"},
        {label: "Goiás", value: "GO"},
        {label: "Maranhão", value: "MA"},
        {label: "Minas Gerais", value: "MG"},
        {label: "Mato Grosso do Sul", value: "MS"},
        {label: "Mato Grosso", value: "MT"},
        {label: "Pará", value: "PA"},
        {label: "Paraíba", value: "PB"},
        {label: "Pernambuco", value: "PE"},
        {label: "Piauí", value: "PI"},
        {label: "Paraná", value: "PR"},
        {label: "Rio de Janeiro", value: "RJ"},
        {label: "Rio Grande do Norte", value: "RN"},
        {label: "Rondônia", value: "RO"},
        {label: "Roraima", value: "RR"},
        {label: "Rio Grande do Sul", value: "RS"},
        {label: "Santa Catarina", value: "SC"},
        {label: "Sergipe", value: "SE"},
        {label: "São Paulo", value: "SP"},
        {label: "Tocantins", value: "TO"},
      ];
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.graphql.obtemMapaPpgd(this.id).subscribe(
      mapa => {
        this.form = this.formBuilder.group({
          titulo: [mapa.titulo, Validators.required],
          sigla: [mapa.sigla, Validators.required],
          iesNomeCompleto: [mapa.iesNomeCompleto, Validators.required],
          iesSigla: [mapa.iesSigla, Validators.required],
          cidade: [mapa.cidade, Validators.required],
          uf: [mapa.uf, Validators.required],
          apresentacao: [mapa.apresentacao, Validators.required],
          telefone: [mapa.telefone, Validators.required],
          email: [mapa.email, Validators.required],
          nomeCoordenador: [mapa.nomeCoordenador, Validators.required],
          urlPrograma: [mapa.urlPrograma, Validators.pattern(this.regexUrl)],
          urlFacebook: [mapa.urlFacebook, Validators.pattern(this.regexUrl)],
          urlTwitter: [mapa.urlTwitter, Validators.pattern(this.regexUrl)],  
          urlOutro: [mapa.urlOutro, Validators.pattern(this.regexUrl)],
          fgAtivo: [mapa.fgAtivo]
        });
      }, () => {
        this.apresentarMensagemDeErro();
      }
    )
  }

  public editarMapaPpdg() {
    this.graphql.atualizarMapaPpgd({
      id: this.id,
      ...this.form.value
    }).subscribe(
      () => {
        this.router.navigateByUrl('/adm/mapa-ppgd-list');
      }, () => {
        this.apresentarMensagemDeErro();
      }
    )
  }

  private apresentarMensagemDeErro() {
    this.msgs = [
      {severity:'error', detail:'Ocorreu um erro inesperado'}
    ]
  }

}
