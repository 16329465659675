import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GraphqlService } from 'src/app/service/graphql/graphql.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-fale-conosco',
  templateUrl: './fale-conosco.component.html',
  styleUrls: ['./fale-conosco.component.css'],
  providers: [MessageService]
})
export class FaleConoscoComponent implements OnInit {

  public form: FormGroup;
  public areas: any[];
  public descricao: string;

  constructor(private formBuilder: FormBuilder,
    private graphqlService: GraphqlService,
    private messageService: MessageService) {
    this.areas = [
      {label: 'Secretaria', value: 'Secretaria'},
      {label: 'Comunicação', value: 'Comunicação'},
      {label: 'Financeiro', value: 'Financeiro'},
      {label: 'Publicações', value: 'Publicações'},
      {label: 'Pôster', value: 'Pôster'},
      {label: 'Sistema', value: 'Sistema'},
      {label: 'Avaliadores', value: 'Avaliadores'},
      {label: 'Espaço do autor', value: 'Espaço do autor'},
    ]
    this.iniciarFormulario();
    this.graphqlService.obtemDescricaoFaleConosco().subscribe(
      d => {
        this.descricao = d;
      }
    )
  }

  private iniciarFormulario() {
    this.form = this.formBuilder.group({
      nome: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      area: [this.areas[0].value, Validators.required],
      telefone: ['', Validators.minLength(10)],
      mensagem: ['', Validators.required]
    })
  }

  ngOnInit() {
  }

  public enviar() {
    this.graphqlService.contato({
      nome: this.form.get('nome').value,
      email: this.form.get('email').value,
      area: this.form.get('area').value,
      telefone: this.form.get('telefone').value,
      mensagem: this.form.get('mensagem').value,
    }).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'A mensagem foi enviada com sucesso.' });
        this.iniciarFormulario();
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.'});
      }
    )
  }

}
