import { Component, OnInit } from '@angular/core';
import { GraphqlService, Usuario } from 'src/app/service/graphql/graphql.service';
import { AutenticacaoService } from 'src/app/service/autenticacao/autenticacao.service';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UploadAdapterHotsite, TipoImagemCkeditor } from 'src/app/service/upload-adapter/upload-adapter-hotsite';
import { UploadAdapter } from 'src/app/service/upload-adapter/upload-adapter';

@Component({
  selector: 'app-memoria-edit',
  templateUrl: './memoria-edit.component.html',
  styleUrls: ['./memoria-edit.component.css'],
  providers: [MessageService]
})
export class MemoriaEditComponent implements OnInit {

  public memoria: string;
  public usuario: Usuario;
  private sub: Subscription;

  constructor(private graphqlService: GraphqlService,
    private authService: AutenticacaoService,
    private messageService: MessageService,
    private router: Router) {
    this.memoria = "";
    this.sub = this.authService.bsAutenticacao.subscribe(
      u => {
        this.usuario = u;
        if (u) {
          this.graphqlService.obtemMemoria().subscribe(
            m => {
              this.memoria = m;
            }
          )
        } else {
          this.memoria = "";
        }
      }
    );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public salvar(): void {
    this.graphqlService.atualizaMemoria(this.memoria).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'Memória atualizada.' });
        this.router.navigateByUrl('/memoria');
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.'});
      }
    )
  }

  public cancelar(): void {
    this.router.navigateByUrl('/memoria');
  }
}
