import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-portal-titulo',
  templateUrl: './portal-titulo.component.html',
  styleUrls: ['./portal-titulo.component.css']
})
export class PortalTituloComponent implements OnInit {

  @Input() titulo: string;
  @Input() route: string;

  constructor() { }

  ngOnInit() {
  }

}
