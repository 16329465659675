import { Component, OnInit } from '@angular/core';
import { GraphqlService } from 'src/app/service/graphql/graphql.service';
import { DadosConteudo } from 'src/app/components/gerenciar-conteudo/gerenciar-conteudo.component';

@Component({
  selector: 'app-documentos-texto-introdutorio',
  templateUrl: './documentos-texto-introdutorio.component.html',
  styleUrls: ['./documentos-texto-introdutorio.component.css']
})
export class DocumentosTextoIntrodutorioComponent implements OnInit {

  public dados: DadosConteudo;

  constructor(private graphqlService: GraphqlService) {
    this.graphqlService.obtemTextoIntrodutorioDocumeto().subscribe(
      t => {
        this.dados = {
          descricao: t
        };
      }
    );
  }

  ngOnInit() {
  }

}
