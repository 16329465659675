import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { GraphqlService, Noticia } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-noticia-lista',
  templateUrl: './noticia-lista.component.html',
  styleUrls: ['./noticia-lista.component.css'],
})
export class NoticiaListaComponent implements OnInit {

  private _noticias: Noticia[];
  public noticias: Noticia[];
  public cols: any[];
  @Input() proprietario: string;
  public displayExcluir: boolean;
  public noticiaSelecionada: Noticia;

  constructor(private graphqlService: GraphqlService, private router: Router) {
  }

  ngOnInit() {
    this.cols = [
      { field: 'titulo', header: 'Titulo' },
      { field: 'dataNoticia', header: 'Data' },
      { field: 'status', header: 'Situação' },
    ];
    this.atualizarLista();
  }

  private atualizarLista() {
    this.graphqlService.obtemNoticias().subscribe(
      noticias => {
        this._noticias = noticias;
        this.noticias = this._noticias.filter(n => n.proprietario === this.proprietario);
      }
    );

  }

  public editarNoticia(idNoticia) {
    this.router.navigateByUrl(`adm/noticias-view/${idNoticia}`, { state: { proprietario: this.proprietario } });
  }

  public inserirNoticia() {
    this.router.navigate([`adm/noticias-insert`], { state: { proprietario: this.proprietario } });
  }

  public abrirExclusao(noticia: Noticia) {
    this.noticiaSelecionada = noticia;
    this.displayExcluir = true;
  }

  public excluirNoticia() {
    this.graphqlService.excluirNoticia(this.noticiaSelecionada.id).subscribe(
      () => {
        this.atualizarLista();
        this.displayExcluir = false;
      }
    )
  }

  ordenarAcima(noticia: Noticia) {
    this.graphqlService.ordenarNoticia(noticia.id, true).subscribe(
      () => {
        this.atualizarLista();
      }
    )
  }

  ordenarAbaixo(noticia: Noticia) {
    this.graphqlService.ordenarNoticia(noticia.id, false).subscribe(
      () => {
        this.atualizarLista();
      }
    )
  }

}
