import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GraphqlService, Diretor } from 'src/app/service/graphql/graphql.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-diretor-upsert',
  templateUrl: './diretor-upsert.component.html',
  styleUrls: ['./diretor-upsert.component.css']
})
export class DiretorUpsertComponent implements OnInit {

  public diretor: Diretor;
  public form: FormGroup;
  private idDiretoriaGrupo: string;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private graphqlService: GraphqlService,
    private router: Router,
    private messageService: MessageService) {

  }

  ngOnInit() {
    const idDiretor = this.route.snapshot.params['idDiretor'];
    const regexUrl = /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    if (idDiretor) {
      this.graphqlService.obtemDiretor(idDiretor).subscribe(
        d => {
          this.diretor = d;
          this.idDiretoriaGrupo = d.diretoriaGrupo.id;
          this.form = this.formBuilder.group({
            titulo: [d.titulo, Validators.required],
            qualificacao: [d.qualificacao, Validators.required],
            nomeCompleto: [d.nomeCompleto, Validators.required],
            instituicao: [d.instituicao, Validators.required],
            urlLattes: [d.urlLattes, [Validators.required, Validators.pattern(regexUrl)]],
            rodape: [d.rodape],
            ordem: [d.ordem, Validators.required]
          })
        }
      )
    } else {
      this.idDiretoriaGrupo = this.route.snapshot.params['idDiretoriaGrupo'];
      this.form = this.formBuilder.group({
        titulo: ['', Validators.required],
        qualificacao: ['', Validators.required],
        nomeCompleto: ['', Validators.required],
        instituicao: ['', Validators.required],
        urlLattes: ['', [Validators.required, Validators.pattern(regexUrl)]],
        rodape: [''],
        ordem: [null, Validators.required]
      })
    }
  }

  public inserirEditarDiretor() {
    if (this.diretor) {
      return this.editarDiretor();
    }
    return this.inserirDiretor();
  }

  private editarDiretor() {
    this.graphqlService.atualizaDiretor({
      id: this.diretor.id,
      ... this.form.value
    }).subscribe(
      () => {
        this.router.navigateByUrl(`/adm/diretores-list/${this.idDiretoriaGrupo}`);
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.' });
      }
    )
  }

  private inserirDiretor() {
    this.graphqlService.insereDiretor({
      ... this.form.value
    }, this.idDiretoriaGrupo).subscribe(
      () => {
        this.router.navigateByUrl(`/adm/diretores-list/${this.idDiretoriaGrupo}`);
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.' });
      }
    )
  }


}
