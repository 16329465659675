import { Component, OnInit, Input } from '@angular/core';
import { AutenticacaoService } from 'src/app/service/autenticacao/autenticacao.service';
import { MenuItem } from 'primeng/api';
import { Router, NavigationEnd, NavigationStart, NavigationError } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-cabecalho',
  templateUrl: './cabecalho.component.html',
  styleUrls: ['./cabecalho.component.css']
})
export class CabecalhoComponent implements OnInit {

  usuario: any = null;
  fazendoLogin = false;
  @Input() paginaInicial: boolean;
  public items: MenuItem[];
  public email = "";
  public senha = "";

  constructor(private autenticacaoService: AutenticacaoService,
    router: Router) {
    const th = this;
    autenticacaoService.bsAutenticacao.subscribe((usuario) => {
      th.usuario = usuario;
      if (usuario = null) {
        th.fazendoLogin = false;
      }
    });
    autenticacaoService.bsFazendoLogin.subscribe((fazendo) => {
      this.fazendoLogin = fazendo;
    });

    router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          this.fazendoLogin = false;
        }
      }
    )
  }

  ngOnInit() {
    this.items = [
      {
        label: 'Sobre',
        items: [
          { label: 'Quem Somos', routerLink: ['/quem-somos'] },
          { label: 'Diretoria', routerLink: ['/diretoria'] },
          { label: 'Documentos', routerLink: ['/documentos'] },
          { label: 'Memória', routerLink: ['/memoria'] },
          { label: 'Fale conosco', routerLink: ['/fale-conosco'] },
        ]
      },
      {
        label: 'Notícias',
        items: [
          { label: 'Conpedi', routerLink: ['/detalhe-noticia-view/conpedi'] },
          { label: 'PPGDs', routerLink: ['/detalhe-noticia-view/ppgd'] },
          //  { label: 'Newsletter', routerLink: [''] },
        ]
      },
      {
        label: 'Eventos',
        items: [
          { label: 'CONPEDI', routerLink: ['/eventos/conpedi'] },
          { label: 'Parceiros', routerLink: ['/eventos/parceiros'] },
        ]
      },
      {
        label: 'Editais',
        items: [
          { label: 'Conpedi - Publica direito', routerLink: ['/editais/conpedi'] },
          { label: 'Parceiros - Publica direito', routerLink: ['/editais/parceiros'] },
          { label: 'Diversos', routerLink: ['/editais/diversos'] },
        ]
      },
      {
        label: 'Publicações',
        items: [
          { label: 'Periódicos', command: () => window.open("https://indexlaw.org ", "_blank") },
          { label: 'Artigos CONPEDI', command: () => { window.location.href = 'http://site.conpedi.org.br/publicacoes' } },
          { label: 'Põsteres CONPEDI', routerLink: ['/publicacao-lista-evento/poster'] },
          { label: 'Publicação de parceiros', routerLink: ['/publicacao-lista-evento/parceiro'] },
        ]
      },
      {
        label: 'Mídias',
        items: [
          { label: 'TV', routerLink: ['/tvs-conpedi-view'] },
          { label: 'Revistas', command: () => window.open('https://issuu.com/conpedi', '_') },
          { label: 'Fotos', routerLink: ['/detalhe-galeria-view'] },
        ]
      },
      { label: 'Espaço do autor', routerLink: ['/espacos-autor-view'] },
      { label: 'Agenda', routerLink: ['/agenda'] },
      //  {label: 'teste', routerLink: ['/links-gts-view/70/32034']},
      //  { label: 'Colunas', routerLink: [''] },
      {
        label: 'PPGDs',
        items: [
          { label: 'Notícias', routerLink: ['/detalhe-noticia-view/ppgd'] },
          { label: 'Editais', routerLink: ['/editais/parceiros'] },
          { label: 'Eventos', routerLink: ['/eventos/parceiros'] },
          { label: 'Publicações', command: () => window.open('http://site.conpedi.org.br/publicacoes', '_') },
          { label: 'Mapa', routerLink: ['/mapa-dos-ppgds'] },
        ]
      },
    ];
  }

  entrar() {
    //this.fazendoLogin = !this.fazendoLogin;
    window.location.href = 'http://site.conpedi.org.br/login'
  }

  cancelaLogin() {
    this.fazendoLogin = false;
  }

  login() {
    this.autenticacaoService.entrarComEmailESenha(this.email, this.senha);
  }

  menuExtendido(): boolean {
    if (this.paginaInicial) {
      return true;
    }
    return this.fazendoLogin;
  }

  mostraMenu() {
    return this.usuario != null;
  }


  public sair(): void {
    this.autenticacaoService.logout();
  }
}
