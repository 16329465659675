import { Component, OnInit } from '@angular/core';
import { GraphqlService, Agenda } from '../../service/graphql/graphql.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-agenda-list',
  templateUrl: './agenda-list.component.html',
  styleUrls: ['./agenda-list.component.css']
})
export class AgendaListComponent implements OnInit {

  public cols: any[];
  public agendas: Agenda[];
  public agendaSelecionada: Agenda;
  public displayExcluir: boolean;
  public displayAtualizacao: boolean;
  public displayInsercao: boolean;
  public formAtualizacao: FormGroup;
  public formInsercao: FormGroup;

  constructor(private graphqlService: GraphqlService, private formBuilder: FormBuilder) { 
    this.atualizarLista();
  }

  private atualizarLista() {
    this.graphqlService.obtemAgendas().subscribe(
      agenda => {
        this.agendas = agenda;
      }
    )
  }

  ngOnInit() {
    this.cols = [
      { field: 'titulo', header: 'Título' },
      { field: 'dataItem', header: 'Data'},
      { field: 'urlDestino', header: 'Link'},
      { field: 'fgPublicado', header: 'Publicado?'}
    ];

    this.formInsercao = this.formBuilder.group({
      titulo: ['',  Validators.required],
      descricao: [''],
      data: [new Date(), Validators.required],
      url: ['',  Validators.required],
      nomeLink: ['',  Validators.required],
      publicado: [false, Validators.required]
    });

    this.formAtualizacao = this.formBuilder.group({
      titulo: ['',  Validators.required],
      descricao: [''],
      data: [new Date(), Validators.required],
      url: ['',  Validators.required],
      nomeLink: ['',  Validators.required],
      publicado: [false, Validators.required]
    });
  }

  public abrirInsercao() {
    this.formInsercao.get("titulo").setValue('');
    this.formInsercao.get("descricao").setValue('');
    this.formInsercao.get("url").setValue('');
    this.formInsercao.get("nomeLink").setValue('');
    this.formInsercao.get("publicado").setValue(false);
    this.formInsercao.get("data").setValue(new Date());
    this.displayInsercao = true;
  }

  public abrirEdicao(agenda: Agenda) {
    const datas = agenda.dataItem.split('/');
    const data = `${datas[1]}/${datas[0]}/${datas[2]}`;
    this.formAtualizacao.get("titulo").setValue(agenda.titulo);
    this.formAtualizacao.get("descricao").setValue(agenda.descricao);
    this.formAtualizacao.get("url").setValue(agenda.urlDestino);
    this.formAtualizacao.get("nomeLink").setValue(agenda.urlLabel);
    this.formAtualizacao.get("publicado").setValue(agenda.fgPublicado);
    this.formAtualizacao.get("data").setValue(new Date(data));
    this.agendaSelecionada = agenda;
    this.displayAtualizacao = true;
  }

  public abrirExclusao(agenda: Agenda) {
    this.agendaSelecionada = agenda;
    this.displayExcluir = true;
  }

  public excluirAgenda() {
   this.graphqlService.excluirAgenda(this.agendaSelecionada).subscribe(
      () => {
        this.atualizarLista();
        this.displayExcluir = false;
      }
    )
  }

  public inserirAgenda() {
    const agenda: any = {
      titulo: this.formInsercao.get("titulo").value,
      descricao: this.formInsercao.get("descricao").value,
      urlDestino: this.formInsercao.get("url").value,
      urlLabel: this.formInsercao.get("nomeLink").value,
      fgPublicado: this.formInsercao.get("publicado").value,
      dataItem: this.formatarDateParaString(this.formInsercao.get("data").value)
    }
    this.graphqlService.inserirAgenda(agenda).subscribe(
      () => {
        this.atualizarLista();
        this.displayInsercao = false;
      }
    )
  }

  public editarAgenda() {
    const agenda = this.agendaSelecionada;
    agenda.titulo = this.formAtualizacao.get("titulo").value;
    agenda.descricao = this.formAtualizacao.get("descricao").value;
    agenda.urlDestino = this.formAtualizacao.get("url").value;
    agenda.urlLabel = this.formAtualizacao.get("nomeLink").value;
    agenda.fgPublicado = this.formAtualizacao.get("publicado").value;
    const data = this.formAtualizacao.get("data").value as Date;
    agenda.dataItem = this.formatarDateParaString(data);
    this.graphqlService.atualizarAgenda(agenda).subscribe(
      () => {
        this.atualizarLista();
        this.displayAtualizacao = false;
      }
    )
  }

  public formatarDateParaString(data: Date): string {
    return moment(data).format('DD/MM/YYYY');
  }


}
