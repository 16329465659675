import { Component, OnInit } from '@angular/core';
import { GraphqlService, DescricaoQuemSomos } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-quem-somos-valores-gerenciar',
  templateUrl: './quem-somos-valores-gerenciar.component.html',
  styleUrls: ['./quem-somos-valores-gerenciar.component.css']
})
export class QuemSomosValoresGerenciarComponent implements OnInit {

  public valores: DescricaoQuemSomos;
  public processandoImagem: boolean;

  constructor(private graphqlService: GraphqlService) {
    this.graphqlService.obtemQuemSomos().subscribe(
      q => {
        this.valores = q.valores;
      }
    )
  }

  ngOnInit() {
  }


  public alterarImagem(file: File): void {
    this.processandoImagem = true;
    this.graphqlService.uploadFile(file, "imagem_valores").subscribe(
      url => {
        this.processandoImagem = false;
        this.valores.imagem = {
          id: "",
          url: url
        }
      }
    )
  }

  public removerImagem(): void {
    
  }

}
