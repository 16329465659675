import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GraphqlService } from '../../service/graphql/graphql.service';
import { Image } from '@ks89/angular-modal-gallery';

@Component({
  selector: 'app-galeria-view',
  templateUrl: './galeria-view.component.html',
  styleUrls: ['./galeria-view.component.css']
})
export class GaleriaViewComponent implements OnInit {

  public imagens: Image[];

  constructor(private route: ActivatedRoute, private graphqlService: GraphqlService) {

  }

  ngOnInit() {
    const idGaleria = Number(this.route.snapshot.params['id']);
    this.graphqlService.obtemGaleria(idGaleria).subscribe(
      galeria => {
        const imagens: Image[] = [];
        const fotos = galeria.foto;
        for (let i = 0; i < fotos.length; i++) {
          imagens.push(new Image(i + 1, { img: fotos[i].url }));
        }
        // importante!
        // se der o push direto em this.imagens o componente não vai apresentar as imagens, assim tem que redefinir ele
        this.imagens = imagens;
      }
    )
  }

}
