import { Component, OnInit, ViewChild } from '@angular/core';
import { GraphqlService, Edital, Pessoa } from '../../service/graphql/graphql.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DialogService } from 'src/app/service/dialog/dialog.service';
import { ArquivoService } from 'src/app/service/arquivo/arquivo.service';
import { MessageService, ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-edital-list',
  templateUrl: './edital-list.component.html',
  styleUrls: ['./edital-list.component.css']
})
export class EditalListComponent implements OnInit {

  public editais: Edital[];
  public form: FormGroup;
  public displayModal: boolean;
  public displayExcluir: boolean;
  public editalSelecionado: Edital;
  public resultadosPesquisaAutor: Pessoa[];
  public secoes: any;
  public tiposAutor: any;

  constructor(private graphqlService: GraphqlService,
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private arquivoService: ArquivoService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) { 
    this.atualizarLista();
  }

  public atualizarLista() {
    this.graphqlService.obtemEditais().subscribe(
      editais => {
        this.editais = editais;
      }
    );
  }

  ngOnInit() {
    this.secoes = [
      {label: "CONPEDI", value: "CONPEDI"},
      {label: "PARCEIRO", value: "PARCEIRO"},
      {label: "OUTROS", value: "OUTROS"},
    ];

    this.tiposAutor = [
      {label: "CONPEDI", value: "CONPEDI"},
      {label: "PARCEIRO", value: "PARCEIRO"},
    ];
  }

  public abrirInsercao() {
    this.form = this.formBuilder.group({
      pessoaAutor: [null, Validators.required],
      autor: [this.tiposAutor[0].value, Validators.required],
      secao: [this.secoes[0].value, Validators.required],
      dtCadastro: [new Date(), Validators.required],
      dataPrazo: [new Date(), Validators.required],
      descricaoEdital: ['', Validators.required],
      fgPublicado: [false, Validators.required],
      arquivo: [null, Validators.required]
    });
    this.editalSelecionado = null;
    this.resultadosPesquisaAutor = [];
    this.displayModal = true;
  }

  public abrirEdicao(e: Edital) {
    this.form = this.formBuilder.group({
      pessoaAutor: [e.pessoaAutor, Validators.required],
      autor: [e.autor, Validators.required],
      secao: [e.secao, Validators.required],
      dtCadastro: [new Date(this.corrigirFormatoDataString(e.dtCadastro)), Validators.required],
      dataPrazo: [new Date(this.corrigirFormatoDataString(e.dataPrazo)), Validators.required],
      descricaoEdital: [e.descricaoEdital, Validators.required],
      fgPublicado: [e.fgPublicado, Validators.required],
      arquivo: [e.arquivo, Validators.required]
    });
    this.editalSelecionado = e;
    this.resultadosPesquisaAutor = [];
    this.displayModal = true;
  }

  private corrigirFormatoDataString(data: string): string {
    return moment(data, 'DD/MM/YYYY').format('MM/DD/YYYY');
  }

  public formatarDateParaString(data: Date): string {
    return moment(data).format('DD/MM/YYYY');
  }

  public abrirExclusao(edital: Edital) {
    this.editalSelecionado = edital;
    this.displayExcluir = true;
  }

  public excluir(edital: Edital): void {
    this.confirmationService.confirm({
      header: "Excluir edital",
      message: `Você tem certeza que deseja excluir o edital "<strong>${edital.descricaoEdital}</strong>"?`,
      accept: () => {
        this.graphqlService.excluirEdital(edital.id).subscribe(
          () => {
            this.editais.splice(this.editais.findIndex(c => c.id === edital.id), 1);
            this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: 'O edital foi excluído com sucesso.' });
          }, () => {
            this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'Ocorreu um problema inesperado.' });
          }
        )
      }
    })
  }

  public pesquisarAutor(event) {
    this.graphqlService.obtemPessoasPorNome(event.query, 10).subscribe(
      pessoas => {
        this.resultadosPesquisaAutor = pessoas;
      }
    )
  }

  public uploadArquivo(): void {
    this.dialogService.uploadArquivo({
      header: "Upload Arquivo",
      data: {
        mimeType: '.pdf',
        accept: (a: File) => {
          return new Promise<any>((resolve, reject) => {
            this.arquivoService.upload(a).then(
              res => {
                this.form.get("arquivo").setValue({
                  id: res.id,
                  nome: a.name,
                  url: res.url
                });
                resolve()
              }, err => {
                reject(err)
              }
            )
          })
        }
      }
    })
  }

  public inserirEditarEdital(): void {
    if (this.editalSelecionado) {
      return this.atualizarEdital();
    }
    return this.inserirEdital();
  }

  public inserirEdital() {
    const edital = this.form.value;
    edital.dtCadastro = this.formatarDateParaString(edital.dtCadastro);
    edital.dataPrazo = this.formatarDateParaString(edital.dataPrazo);
    this.graphqlService.inserirEdital(edital).subscribe(
      () => {
        this.atualizarLista();
        this.displayModal = false;
        this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'Edital criado com sucesso!' });
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.' });
      }
    );
  }

  public atualizarEdital() {
    const edital = this.form.value;
    edital.id = this.editalSelecionado.id;
    edital.dtCadastro = this.formatarDateParaString(edital.dtCadastro);
    edital.dataPrazo = this.formatarDateParaString(edital.dataPrazo);
    this.graphqlService.atualizarEdital(edital).subscribe(
      () => {
        this.atualizarLista();
        this.displayModal = false;
        this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'Edital atualizado com sucesso!' });
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.' });
      }
    )
  }


}
