import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { enderecoServidorGraphql } from 'src/app/configuracao';

export abstract class Http {

    constructor(protected http: HttpClient) { }

    protected montarURL(nomeDoMetodo: string): string {
        return `${enderecoServidorGraphql}/${nomeDoMetodo}`;
    }

    protected get<T>(nomeMetodo: string, form: HttpParams | { [param: string]: string | string[] }): Observable<T> {
        return new Observable<T>(
            observer => {
                const url = this.montarURL(nomeMetodo);
                this.http.get(url, { headers: {}, params: form }).subscribe(
                    (res: any) => {
                        if (res.ok) {
                            observer.next(res.result);
                        } else {
                            observer.error(res.msg);
                        }
                    }, (error) => {
                        observer.error(error);
                    }, () => {
                        observer.complete();
                    }
                )
            }
        )
    }

    protected post<T>(nomeMetodo: string, data: FormData | string): Observable<T> {
        return new Observable<T>(
            observer => {
                const url = this.montarURL(nomeMetodo);
                this.http.post(url, data, { headers: {} }).subscribe(
                    (res: any) => {
                        if (res.ok) {
                            observer.next(res.result);
                        } else {
                            observer.error(res.msg);
                        }
                    }, (error) => {
                        observer.error(error);
                    }, () => {
                        observer.complete();
                    }
                )
            }
        )
    }

}