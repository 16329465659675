import { Component, OnInit } from '@angular/core';
import { Imagem } from '../inicial-mostra-imagens/inicial-mostra-imagens.component';
import { GraphqlService } from 'src/app/service/graphql/graphql.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inicial-galeria',
  templateUrl: './inicial-galeria.component.html',
  styleUrls: ['./inicial-galeria.component.css']
})
export class InicialGaleriaComponent implements OnInit {

  imagens: Imagem[] = [];
  public descricaoTipo = "Fotos";

  constructor(private graphqlService: GraphqlService, private router: Router) { }

  ngOnInit() {
    this.graphqlService.obtemGalerias().subscribe(
      noticias => {
        this.imagens = [];
        for (const rec of noticias) {
          if (this.imagens.length < 4 && rec.foto != null && rec.foto.length > 0) {
            this.imagens.push(new Imagem(rec.id, rec.foto[0].url, rec.titulo, null));
          }
        }
      });
  }

  onClick(imagem) {
    this.router.navigateByUrl(`/galeria-view/${imagem.id}`);
  }

}
