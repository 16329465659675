import { Component, OnInit } from '@angular/core';
import { Imagem } from '../inicial-mostra-imagens/inicial-mostra-imagens.component';
import { GraphqlService } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-colunista-apresentacao-lista',
  templateUrl: './colunista-apresentacao-lista.component.html',
  styleUrls: ['./colunista-apresentacao-lista.component.css']
})
export class ColunistaApresentacaoListaComponent implements OnInit {

  public imagens: Imagem[] = [];

  constructor(private graphqlService: GraphqlService) { 
    this.graphqlService.obtemColunistas().subscribe(
      noticias => {
        this.imagens = [];
        for (const rec of noticias) {
          if (this.imagens.length < 4 && rec.foto != null && rec.foto.url != null) {
            this.imagens.push(new Imagem(rec.id, rec.foto.url, rec.nomePublico, null));
          }
        }
      });
  }

  ngOnInit() {

  }

}
