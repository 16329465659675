import { Pipe, PipeTransform } from '@angular/core';
import {  GrupoTrabalho, GrupoTrabalhoPoster } from '../../../service/graphql/graphql.service';

@Pipe({
  name: 'nomeGrupoTrabalho'
})
export class NomeGrupoTrabalhoPipe implements PipeTransform {

  private numRomanos = ['I', 'II', 'III', 'IV', 'V'];

  transform(g: GrupoTrabalho | GrupoTrabalhoPoster, args?: any): any {
    if (!g || !g.linhaPesquisa) {
      return "";
    }
    return `${g.linhaPesquisa.descricao} ${this.numRomanos[g.numeroGrupo - 1]}`;
  }

}
