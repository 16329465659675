import { Component, OnInit } from '@angular/core';
import { Imagem } from '../inicial-mostra-imagens/inicial-mostra-imagens.component';
import { GraphqlService } from 'src/app/service/graphql/graphql.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inicial-colunistas',
  templateUrl: './inicial-colunistas.component.html',
  styleUrls: ['./inicial-colunistas.component.css']
})
export class InicialColunistasComponent implements OnInit {


  public descricaoTipo = "Colunistas";

  constructor(private router: Router) { }

  ngOnInit() {
  
  }

  onClick(imagem) {
  //this.router.navigateByUrl(`adm/colunista-portal-view/${imagem.id}`);
  }

}
