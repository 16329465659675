import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { Arquivo  } from 'src/app/service/graphql/graphql.service';

export interface DadosConteudo {
  descricao: string
  imagem?: Arquivo
}

@Component({
  selector: 'app-gerenciar-conteudo',
  templateUrl: './gerenciar-conteudo.component.html',
  styleUrls: ['./gerenciar-conteudo.component.css']
})
export class GerenciarConteudoComponent implements OnInit {

  @ViewChild('inputImagem', {static: false}) input: any;

  @Input() label: string;
  @Input() editPath: string;
  @Input() conteudo: DadosConteudo;
  @Input() processandoImagem: boolean;
  @Input() hasImagemVinculadaConteudo: boolean;
  @Input() backPath: string;
  @Output() alterarImagem: EventEmitter<File>;
  @Output() removerImagem: EventEmitter<void>;

  constructor() {
    this.alterarImagem = new EventEmitter();
    this.removerImagem = new EventEmitter();
  }

  ngOnInit() {
  }

  public trocarImagem() {
    const file = this.input.nativeElement.files[0];
    if (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg") {
      this.alterarImagem.emit(file);
    }
    this.input.nativeElement.value = "";
  }

  public apagarImagem() {
    this.alterarImagem.emit();
  }

}
