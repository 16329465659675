import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GraphqlService, GrupoTrabalhoPoster } from 'src/app/service/graphql/graphql.service';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-gtp-publicacao-texto',
  templateUrl: './gtp-publicacao-texto.component.html',
  styleUrls: ['./gtp-publicacao-texto.component.css'],
  providers: [MessageService]
})
export class GtpPublicacaoTextoComponent implements OnInit {

  public form: FormGroup;
  private grupoTrabalho: GrupoTrabalhoPoster;

  constructor(private graphqlService: GraphqlService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private messageService: MessageService) {
      this.form = this.formBuilder.group({
        textoApresentacao: ['', Validators.required],
        versaoFinal: [false, Validators.required]
      });
    }

  ngOnInit() {
    const idGrupoTrabalho = this.route.snapshot.params['id'];
    this.graphqlService.obtemGrupoDeTrabalhoPoster(idGrupoTrabalho).subscribe(
      g => {
        this.grupoTrabalho = g;
        this.form = this.formBuilder.group({
          textoApresentacao: [g.textoApresentacao, Validators.required],
          versaoFinal: [g.versaoFinalTextoApresentacao, Validators.required]
        });
      }
    );
  }

  public atualizarTextoPublicacao(): void {
    this.grupoTrabalho.textoApresentacao = this.form.get('textoApresentacao').value;
    this.grupoTrabalho.versaoFinalTextoApresentacao = this.form.get('versaoFinal').value;
    this.graphqlService.atualizaTextoGrupoTrabalhoPoster(this.grupoTrabalho).subscribe(
      () => {
        this.messageService.add({severity:'success', summary:'Sucesso!', detail:'O texto de publicação foi atualizado.'});
      }, (msg) => {
        this.messageService.add({severity:'error', summary:'Error!', detail:msg});
      }
    )
  }

}
