import { Component, OnInit } from '@angular/core';
import { GraphqlService, Documento } from 'src/app/service/graphql/graphql.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-documentos-list',
  templateUrl: './documentos-list.component.html',
  styleUrls: ['./documentos-list.component.css']
})
export class DocumentosListComponent implements OnInit {

  public documentos: Documento[];

  constructor(private graphqlService: GraphqlService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService) {
    this.graphqlService.obtemDocumentos().subscribe(
      dd => {
        this.documentos = dd;
      }
    )
  }

  ngOnInit() {
  }

  public excluirDocumento(documento: Documento) {
    this.confirmationService.confirm({
      message: `Você tem certeza que deseja remover o documento <strong>${documento.descDocumento}</strong>?`,
      accept: () => {
        this.graphqlService.excluiDocumento(documento.id).subscribe(
          () => {
            this.documentos = this.documentos.filter(d => d.id !== documento.id);
            this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'O documento foi excluido com sucesso.' });
          }, error => {
            this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde. ' + error });
          }
        )
      }
    })
  }

}
