import { Component, OnInit, ViewChild } from '@angular/core';
import { GraphqlService, Evento, Hotsite } from 'src/app/service/graphql/graphql.service';
import { ActivatedRoute, Router } from '@angular/router';
import { isNumber } from 'util';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { EditaCredencialComponent } from 'src/app/components/edita-credencial/edita-credencial.component';

@Component({
  selector: 'app-evento-editor',
  templateUrl: './evento-editor.component.html',
  styleUrls: ['./evento-editor.component.css']
})
export class EventoEditorComponent implements OnInit {

  public idEvento: string;
  public evento: Evento;
  public indexAba: number;
  public resultadoPesquisaHotsite: Hotsite[];
  public form: FormGroup;

  @ViewChild(EditaCredencialComponent, { static: true })
  private editaCredencialComponent!: EditaCredencialComponent;

  constructor(private graphqlService: GraphqlService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private messageService: MessageService) {
    const state = this.router.getCurrentNavigation().extras.state;
    if (state) {
      const aba = Number(state.aba);
      if (isNumber(aba)) {
        this.indexAba = aba;
      }
    }
  }

  ngOnInit() {
    this.idEvento = this.route.snapshot.params['id'];
    this.graphqlService.obtemEvento(this.idEvento).subscribe(
      evento => {
        this.evento = evento;
        this.form = this.formBuilder.group({
          hotsite: [evento.hotsite, Validators.required],
          mostrarPainel: [evento.linkEventoLiberado],
          publicacaoLiberadaPoster: [evento.publicacaoLiberadaPoster],
          publicacaoLiberadaArtigo: [evento.publicacaoLiberadaArtigo]
        })
      }
    )
  }

  editaCredencial() {
    document.getElementById(`credencial`).click();
  }

  async uploadPdf(event) {
    if (event.srcElement.files.length <= 0) {
      return
    }
    const file = event.srcElement.files[0];
    event.srcElement.value = null;
    // existe um filtro no input, mas ele pode ser modificado no navegador com as ferramentas de desenvolvedor
    if (file.type == "application/pdf") {
      const v = await this.graphqlService.uploadFile(file, "pdf_evento", this.idEvento).toPromise();
      console.log(v)
      this.editaCredencialComponent.refresh()
    }
  }

  public atualizarHotsite(): void {
    const dados = this.form.value;
    this.graphqlService.atualizaEvento(this.evento.id, dados.hotsite.id, dados.mostrarPainel, dados.publicacaoLiberadaPoster, dados.publicacaoLiberadaArtigo).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: 'O evento foi atualizado com sucesso!' });
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'Ocorreu um problema inesperado.' });
      }
    )
  }

  public pesquisarHotsite(event) {
    this.graphqlService.obtemHotsitesPorDescricao(event.query, 10).subscribe(
      hh => {
        this.resultadoPesquisaHotsite = hh;
      }
    )
  }

}
