import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GraphqlService, Evento, Trabalho } from 'src/app/service/graphql/graphql.service';
import { AutenticacaoService } from 'src/app/service/autenticacao/autenticacao.service';

@Component({
  selector: 'app-evento-associado',
  templateUrl: './evento-associado.component.html',
  styleUrls: ['./evento-associado.component.css']
})
export class EventoAssociadoComponent implements OnInit {

  public evento: Evento;
  public trabalhos: Trabalho[];

  constructor(private route: ActivatedRoute,
    private graphqlService: GraphqlService,
    private authService: AutenticacaoService) { }

  ngOnInit() {
    const idEvento = this.route.snapshot.params['idEvento'];
    const idUsuario = this.authService.obtemUsuario().id;
    this.graphqlService.obtemEvento(idEvento).subscribe(
      e => {
        this.evento = e;
      }
    )
   // this.graphqlService.trabalhoPessoaAutorOrientador(String(idUsuario), idEvento).subscribe(
    this.graphqlService.trabalhoPessoaAutorOrientador(String(32034), "70").subscribe(
      tt => {
        this.trabalhos = tt;
      }
    )
  }

}
