import { Component, OnInit, Input } from '@angular/core';
import { GraphqlService, EventoValorInscricao, TipoInscricaoEventoValor } from 'src/app/service/graphql/graphql.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';

enum TipoAssociadoInscricao {

}


@Component({
  selector: 'app-evento-edicao-pagamento',
  templateUrl: './evento-edicao-pagamento.component.html',
  styleUrls: ['./evento-edicao-pagamento.component.css']
})
export class EventoEdicaoPagamentoComponent implements OnInit {

  @Input() idEvento: string
  public eventosValorInscricaoArtigo: EventoValorInscricao[];
  public eventosValorInscricaoPoster: EventoValorInscricao[];
  public eventosValorInscricaoOuvinte: EventoValorInscricao[];
  public form: FormGroup;
  public tiposAssociados = {
    TODOS: "Todos",
    APENAS_ASSOCIADOS: "Apenas associados",
    NAO_ASSOCIADOS: "Não associados"
  }

  constructor(private graphqlService: GraphqlService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private confirmationService: ConfirmationService) {
      this.iniciarForm();
  }

  iniciarForm() {
    this.form = this.formBuilder.group({
      tipoInscricao: ['APRESENTA_ARTIGO', Validators.required],
      tipoAssociado: ['TODOS', Validators.required],
      validoAte: ['', Validators.required],
      valor: [0, [Validators.required, Validators.min(0.01)]]
    })
  }

  ngOnInit() {
    this.graphqlService.obtemEventoValorInscricao(this.idEvento).subscribe(
      ee => {
        this.eventosValorInscricaoArtigo = ee.filter(e => e.tipoInscricao === TipoInscricaoEventoValor.ApresentaArtigo);
        this.eventosValorInscricaoPoster = ee.filter(e => e.tipoInscricao === TipoInscricaoEventoValor.ApresentaPoster);
        this.eventosValorInscricaoOuvinte = ee.filter(e => e.tipoInscricao === TipoInscricaoEventoValor.Ouvinte);
      }, () => {

      }
    )
  }

  public excluir(index: number, inscricao: EventoValorInscricao): void {
    /*this.confirmationService.confirm({
      header: "Excluir inscrição",
      message: `Você tem certeza que deseja excluir esta inscrição?`,
      accept: () => {

      }
    })*/
    this.graphqlService.excluiEventoValorInscricao(this.idEvento, inscricao.dataLimite, inscricao.tipoInscricao).subscribe(
      () => {
        switch(inscricao.tipoInscricao) {
          case "APRESENTA_ARTIGO": this.eventosValorInscricaoArtigo.splice(index, 1); break;
          case "APRESENTA_POSTER": this.eventosValorInscricaoPoster.splice(index, 1); break;
          case "OUVINTE": this.eventosValorInscricaoOuvinte.splice(index, 1); break;
        }
        this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'A inscrição foi removida com sucesso!' });
      }, () => {
        this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde.' });
      }
    )

  }

  public inserirInscricao(): void {
    const dados = this.form.value;
    this.graphqlService.insereEventoValorInscricao(this.idEvento, dados.validoAte, dados.tipoInscricao, dados.valor, dados.tipoAssociado).subscribe(
      () => {
        const inscricao : EventoValorInscricao = {
          dataLimite: dados.validoAte,
          disponivel: true,
          idEvento: Number(this.idEvento),
          tipoAssociado: dados.tipoAssociado,
          tipoInscricao: dados.tipoInscricao,
          valor: dados.valor
        }
        switch(dados.tipoInscricao) {
          case "APRESENTA_ARTIGO": this.eventosValorInscricaoArtigo.push(inscricao); break;
          case "APRESENTA_POSTER": this.eventosValorInscricaoPoster.push(inscricao); break;
          case "OUVINTE": this.eventosValorInscricaoOuvinte.push(inscricao); break;
        }
        this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'A inscrição foi criada com sucesso!' });
      }, (msg) => {
        this.messageService.add({ severity: 'error', summary: 'Erro!', detail: msg });
      }
    )
  }

}
