import { Component, OnInit } from '@angular/core';
import { GraphqlService, DescricaoQuemSomos } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-quem-somos-missao-gerenciar',
  templateUrl: './quem-somos-missao-gerenciar.component.html',
  styleUrls: ['./quem-somos-missao-gerenciar.component.css']
})
export class QuemSomosMissaoGerenciarComponent implements OnInit {

  public missao: DescricaoQuemSomos;
  public processandoImagem: boolean;

  constructor(private graphqlService: GraphqlService) {
    this.graphqlService.obtemQuemSomos().subscribe(
      q => {
        this.missao = q.missao;
      }
    )
  }

  ngOnInit() {
  }

  public alterarImagem(file: File): void {
    this.processandoImagem = true;
    this.graphqlService.uploadFile(file, "imagem_missao").subscribe(
      (url) => {
        this.processandoImagem = false;
        this.missao.imagem = {
          id: "",
          url: url
        }
      }
    )
  }

  public removerImagem(): void {

  }

}
