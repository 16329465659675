import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GraphqlService, EspacoAutor } from '../../service/graphql/graphql.service';
@Component({
  selector: 'app-espaco-autor-livro-view',
  templateUrl: './espaco-autor-livro-view.component.html',
  styleUrls: ['./espaco-autor-livro-view.component.css']
})
export class EspacoAutorLivroViewComponent implements OnInit {

  public livro: EspacoAutor;

  constructor(private route: ActivatedRoute,
    private graphql: GraphqlService) { 

  }

  ngOnInit() {
    const idLivro = this.route.snapshot.params['id'];
    this.graphql.obtemEspacoAutor(idLivro).subscribe(
      livro => {
        this.livro = livro;
      }, error => {
        console.log(error);
      }
    )
  }


}
