import { Component, Input, OnInit } from '@angular/core';
import { GraphqlService } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-edita-credencial',
  templateUrl: './edita-credencial.component.html',
  styleUrls: ['./edita-credencial.component.css']
})
export class EditaCredencialComponent implements OnInit {

  @Input() idEvento: string;
  public evento: any = {};
  public temCredencial: String = "?";

  constructor(private graphqlService: GraphqlService) {

  }

  ngOnInit() {
    this.refreshInfo()
  }

  async refreshInfo() {
    console.log(this.idEvento);
    const eventos = await this.graphqlService.query(`{
      evento(id:${this.idEvento}) {
        id
        titulo
        credencial {
          id
          hash
          url
          nome
        }
      }
    }`, {});
    this.evento = eventos[0]
    if (this.evento.credencial) {
      this.temCredencial = "V"
    } else {
      this.temCredencial = "F"
    }
  }

  refresh() {
    this.refreshInfo()
  }

}
