import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GraphqlService, Pessoa, GrupoTrabalhoPoster, Evento } from 'src/app/service/graphql/graphql.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-gtp-coordenador',
  templateUrl: './gtp-coordenador.component.html',
  styleUrls: ['./gtp-coordenador.component.css'],
  providers: [ConfirmationService]
})
export class GtpCoordenadorComponent implements OnInit {

  public gruposTrabalho: GrupoTrabalhoPoster[];
  public nomeLinhaPesquisa: string;
  public displayModal: boolean;
  public grupoTabalhoSelecionado: GrupoTrabalhoPoster;
  public coordenadoresGrupoTrabalho: Map<String, Pessoa[]>;
  public numRomano: string[];
  public evento: Evento;

  constructor(private graphqlService: GraphqlService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService) {
      this.coordenadoresGrupoTrabalho = new Map();
      this.numRomano = ['I', 'II', 'III', 'IV', 'V']
    }

  ngOnInit() {
    const idLinhaPesquisa = this.route.snapshot.params['id'];
    this.graphqlService.obtemGrupoDeTrabalhoLinhaDePesquisa(idLinhaPesquisa).subscribe(
      grupos => {
        this.gruposTrabalho = grupos.filter(g => g.ativo);
        if (this.gruposTrabalho.length > 0) {
          this.nomeLinhaPesquisa = this.gruposTrabalho[0].linhaPesquisa.descricao;
        }
        for (const gt of this.gruposTrabalho) {
          this.graphqlService.obtemCoordenadores(gt.id).subscribe(
            c => {
              this.coordenadoresGrupoTrabalho.set(gt.id, c);
            }
          )
        }
      }
    );
    this.graphqlService.obtemEventoLinhaPesquisa(idLinhaPesquisa).subscribe(
      evento => {
        this.evento = evento;
      }
    );
  }

  public abrirModal(grupoTabalho: GrupoTrabalhoPoster): void {
    this.grupoTabalhoSelecionado = grupoTabalho;
    this.displayModal = true;
  }

  public removerCoordenador(coordenador: Pessoa, grupoTabalho: GrupoTrabalhoPoster): void {
    this.confirmationService.confirm({
      message: `Você tem certeza que deseja remover o coordenador "${coordenador.nome}"?`,
      accept: () => {
        this.graphqlService.excluiCoordenador(grupoTabalho.id, coordenador.id).subscribe(
          () => {
            const novosCoordenadores = this.coordenadoresGrupoTrabalho.get(grupoTabalho.id).filter(c => c.id !== coordenador.id);
            this.coordenadoresGrupoTrabalho.set(grupoTabalho.id, novosCoordenadores);
          }
        )
      }
    })
  }

  public adicionarCoordenador(pessoa: Pessoa) {
    const idGrupoTrabalho = this.grupoTabalhoSelecionado.id;
    this.graphqlService.insereCoordenador(idGrupoTrabalho, pessoa.id).subscribe(
      () => {
        const novosCoordenadores = this.coordenadoresGrupoTrabalho.get(idGrupoTrabalho);
        novosCoordenadores.push(pessoa);
        this.coordenadoresGrupoTrabalho.set(idGrupoTrabalho, novosCoordenadores);
        this.displayModal = false;
      }
    );
  }

  public voltar() {
    this.router.navigate(['/adm/evento-editor/' + this.evento.id], {state: {aba: 2}});
  }

}
