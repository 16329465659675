import { Component, OnInit } from '@angular/core';
import { Imagem } from 'src/app/components/inicial-mostra-imagens/inicial-mostra-imagens.component';
import { GraphqlService } from 'src/app/service/graphql/graphql.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-colunistas-portal-view',
  templateUrl: './colunistas-portal-view.component.html',
  styleUrls: ['./colunistas-portal-view.component.css']
})
export class ColunistasPortalViewComponent implements OnInit {

  imagens: Imagem[] = [];

  constructor(private graphqlService: GraphqlService, private router: Router) { }

  ngOnInit() {
    this.graphqlService.obtemColunistas().subscribe(
      noticias => {
        this.imagens = [];
        for (const rec of noticias) {
          if (this.imagens.length < 4 && rec.foto != null && rec.foto.url != null) {
            this.imagens.push(new Imagem(rec.id, rec.foto.url, rec.nomePublico, null));
          }
        }
      });
  }

  onClick(imagem) {
    //    this.router.navigateByUrl(`adm/colunista-portal-view/${imagem.id}`);
  }

}
