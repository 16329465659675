import { Component, OnInit, Input } from '@angular/core';
import { GrupoTrabalho, GraphqlService } from 'src/app/service/graphql/graphql.service';

@Component({
  selector: 'app-gt-publicacao-lista',
  templateUrl: './gt-publicacao-lista.component.html',
  styleUrls: ['./gt-publicacao-lista.component.css']
})
export class GtPublicacaoListaComponent implements OnInit {

  @Input() idEvento: string;
  public gruposTrabalho: GrupoTrabalho[];
  public numRomano: string[];

  constructor(private graphqlService: GraphqlService) {
    this.numRomano = ['I', 'II', 'III', 'IV', 'V']
  }

  ngOnInit() {
    this.graphqlService.obtemGrupoDeTrabalhoEvento(this.idEvento).subscribe(
      g => {
        this.gruposTrabalho = g;
      }
    );
  }

}
