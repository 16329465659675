import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GraphqlService, GrupoTrabalho } from 'src/app/service/graphql/graphql.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-gt-publicacao',
  templateUrl: './gt-publicacao.component.html',
  styleUrls: ['./gt-publicacao.component.css'],
})
export class GtPublicacaoComponent implements OnInit {

  private idGrupoTrabalho: string;
  public grupoTrabalho: GrupoTrabalho;
  public form: FormGroup;

  constructor(private route: ActivatedRoute,
    private graphqlService: GraphqlService,
    private formBuilder: FormBuilder,
    private messageService: MessageService) { }

  ngOnInit() {
    this.idGrupoTrabalho = this.route.snapshot.params['id'];
    this.atualizarDadosGrupoTrabalho();
  }

  private atualizarDadosGrupoTrabalho(): void {
    this.graphqlService.obtemGrupoDeTrabalho(this.idGrupoTrabalho).subscribe(
      g => {
        this.grupoTrabalho = g;
        this.form = this.formBuilder.group({
          link: [g.linkApresentacao || "", Validators.required]
        });
      }
    );
  }

  public atualizaLinkApresentacao(): void {
    this.grupoTrabalho.linkApresentacao = this.form.get('link').value;
    this.graphqlService.atualizaGrupoTrabalho(this.grupoTrabalho).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Sucesso!', detail: 'O link da apresentação do grupo de trabalho foi atualizado com sucesso.' });
      }, (error) => {
        this.messageService.add({ severity: 'error', summary: 'Erro!', detail: error });
      }
    )
  }



}
