import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mapa-ppgd',
  templateUrl: './mapa-ppgd.component.html',
  styleUrls: ['./mapa-ppgd.component.css']
})
export class MapaPpgdComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
