import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy, registerLocaleData } from '@angular/common';
import { AppRoutes } from './app.routes';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { GrowlModule } from 'primeng/growl';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';

import { AppComponent } from './app.component';
import { AppProfileComponent } from './app.profile.component';
import { AppBreadcrumbComponent } from './app.breadcrumb.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';

import { BreadcrumbService } from './breadcrumb.service';

import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
import { HttpLinkModule, HttpLink } from "apollo-angular-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { EventoComponent } from './pages/evento/evento.component';
import { HotsiteEditorComponent } from './pages/hotsite-editor/hotsite-editor.component';
import { HotsiteComponent } from './pages/hotsite/hotsite.component';
import { HotsiteViewComponent } from './pages/hotsite-view/hotsite-view.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { GaleriaListComponent } from './pages/galeria-list/galeria-list.component';
import { GaleriaViewComponent } from './pages/galeria-view/galeria-view.component';
import { GalleryModule } from '@ks89/angular-modal-gallery';

import 'hammerjs';
import 'mousetrap';
import { TemlateSiteComponent } from './pages/temlate-site/temlate-site.component';
import { enderecoServidorGraphql } from './configuracao';
import { InicialComponent } from './pages/inicial/inicial.component';
import { InicialEventosComponent } from './components/inicial-eventos/inicial-eventos.component';
import { InicialNoticiaComponent } from './components/inicial-noticia/inicial-noticia.component';
import { InicialGaleriaComponent } from './components/inicial-galeria/inicial-galeria.component';
import { InicialMidiaComponent } from './components/inicial-midia/inicial-midia.component';
import { InicialColunistasComponent } from './components/inicial-colunistas/inicial-colunistas.component';
import { InicialMapaPddgComponent } from './components/inicial-mapa-pddg/inicial-mapa-pddg.component';
import { NoticiasListComponent } from './pages/noticias-list/noticias-list.component';
import { NoticiasViewComponent } from './pages/noticias-view/noticias-view.component';
import { NoticiaListaComponent } from './components/noticia-lista/noticia-lista.component';
import { MenuLateralListComponent } from './pages/menu-lateral-list/menu-lateral-list.component';
import { ColunistaListComponent } from './pages/colunista-list/colunista-list.component';
import { MapaPpdgListComponent } from './pages/mapa-ppdg-list/mapa-ppdg-list.component';
import { TvConpediListComponent } from './pages/tv-conpedi-list/tv-conpedi-list.component';
import { AgendaListComponent } from './pages/agenda-list/agenda-list.component';
import { EditaisListComponent } from './pages/editais-list/editais-list.component';
import { EspacoDoAutorListComponent } from './pages/espaco-do-autor-list/espaco-do-autor-list.component';
import { RevistaConpediListComponent } from './pages/revista-conpedi-list/revista-conpedi-list.component';
import { InicialMostraImagensComponent } from './components/inicial-mostra-imagens/inicial-mostra-imagens.component';
import { InicialMenuLateralComponent } from './components/inicial-menu-lateral/inicial-menu-lateral.component';
import { ColunistaEditorComponent } from './pages/colunista-editor/colunista-editor.component';
import { ColunistaViewComponent } from './pages/colunista-view/colunista-view.component';
import { EditalListComponent } from './pages/edital-list/edital-list.component';
import { NoticiasInsertComponent } from './pages/noticias-insert/noticias-insert.component';
import { NoticiasEditComponent } from './pages/noticias-edit/noticias-edit.component';
import { MapaPpdgEditComponent } from './pages/mapa-ppdg-edit/mapa-ppdg-edit.component';
import { MessageService, ConfirmationService, DialogService } from 'primeng/api';
import { MapaPpgdInsertComponent } from './pages/mapa-ppgd-insert/mapa-ppgd-insert.component';
import { NoticiaViewComponent } from './pages/noticia-view/noticia-view.component';
import { RevistasViewComponent } from './pages/revistas-view/revistas-view.component';
import { EspacosAutorViewComponent } from './pages/espacos-autor-view/espacos-autor-view.component';
import { TvsConpediViewComponent } from './pages/tvs-conpedi-view/tvs-conpedi-view.component';
import { ColunistaPortalViewComponent } from './pages/colunista-portal-view/colunista-portal-view.component';
import { ColunistasPortalViewComponent } from './pages/colunistas-portal-view/colunistas-portal-view.component';
import { FotoColunistaViewComponent } from './components/foto-colunista-view/foto-colunista-view.component';
import { EspacoAutorLivroViewComponent } from './pages/espaco-autor-livro-view/espaco-autor-livro-view.component';
import { PortalTituloComponent } from './components/portal-titulo/portal-titulo.component';
import { PortalTituloPaginaComponent } from './components/portal-titulo-pagina/portal-titulo-pagina.component';
import { PortalRodapeComponent } from './components/portal-rodape/portal-rodape.component';
import { MenuComponent } from './components/menu/menu.component';
import { CabecalhoComponent } from './components/cabecalho/cabecalho.component';
import { MapaPpgdViewComponent } from './components/mapa-ppgd-view/mapa-ppgd-view.component';
import { DetalheNoticiaViewComponent } from './pages/portal/detalhe-noticia-view/detalhe-noticia-view.component';
import { LayoutModule } from '@angular/cdk/layout';
import { DetalheGaleriaViewComponent } from './pages/portal/detalhe-galeria-view/detalhe-galeria-view.component';
import { LoginLegadoComponent } from './components/login-legado/login-legado.component';
import { MenuInicialComponent } from './components/menu-inicial/menu-inicial.component';
import { AnuidadeManualComponent } from './pages/anuidade-manual/anuidade-manual.component';
import { ColunistaApresentacaoListaComponent } from './components/colunista-apresentacao-lista/colunista-apresentacao-lista.component';
import { EventoEditorComponent } from './pages/evento-editor/evento-editor.component';
import { GtpDefinirComponent } from './pages/gtp-definir/gtp-definir.component';
import { GtpListarComponent } from './pages/gtp-listar/gtp-listar.component';
import { GtpArtigosComponent } from './pages/gtp-artigos/gtp-artigos.component';
import { GtpEditaComponent } from './pages/gtp-edita/gtp-edita.component';
import { KeyFilterModule } from 'primeng/keyfilter';
import { EventoInscritoConsultaComponent } from './pages/evento-inscrito-consulta/evento-inscrito-consulta.component';
import { GtpCoordenadorComponent } from './pages/gtp-coordenador/gtp-coordenador.component';
import { SelecaoPessoaComponent } from './components/selecao-pessoa/selecao-pessoa.component';
import { RoleDirective } from './shared/directives/role.directive';
import { FaleConoscoComponent } from './pages/fale-conosco/fale-conosco.component';
import { MemoriaComponent } from './pages/memoria/memoria.component';
import { MemoriaEditComponent } from './pages/memoria-edit/memoria-edit.component';
import { BotaoDireitaCrudComponent } from './components/botao-direita-crud/botao-direita-crud.component';
import { FaleConoscoEditComponent } from './pages/fale-conosco-edit/fale-conosco-edit.component';
import { QuemSomosComponent } from './pages/quem-somos/quem-somos.component';
import { EditarConteudoComponent } from './components/editar-conteudo/editar-conteudo.component';
import { GerenciarConteudoComponent } from './components/gerenciar-conteudo/gerenciar-conteudo.component';
import { QuemSomosSobreGerenciarComponent } from './pages/quem-somos-sobre-gerenciar/quem-somos-sobre-gerenciar.component';
import { QuemSomosMissaoGerenciarComponent } from './pages/quem-somos-missao-gerenciar/quem-somos-missao-gerenciar.component';
import { QuemSomosValoresGerenciarComponent } from './pages/quem-somos-valores-gerenciar/quem-somos-valores-gerenciar.component';
import { QuemSomosSobreEditComponent } from './pages/quem-somos-sobre-edit/quem-somos-sobre-edit.component';
import { QuemSomosMissaoEditComponent } from './pages/quem-somos-missao-edit/quem-somos-missao-edit.component';
import { QuemSomosValoresEditComponent } from './pages/quem-somos-valores-edit/quem-somos-valores-edit.component';
import { AgendaComponent } from './pages/agenda/agenda.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LOCALE_ID } from '@angular/core';
import localeBr from '@angular/common/locales/pt';
import { GtpPublicacaoComponent } from './pages/gtp-publicacao/gtp-publicacao.component';
import { GtpPublicacaoListaComponent } from './pages/gtp-publicacao-lista/gtp-publicacao-lista.component';
import { GtpPublicacaoTextoComponent } from './pages/gtp-publicacao-texto/gtp-publicacao-texto.component';
import { NomeGrupoTrabalhoPipe } from './shared/pipes/nome-grupo-trabalho/nome-grupo-trabalho.pipe';
import { PublicacaoListaEventoComponent } from './pages/publicacao-lista-evento/publicacao-lista-evento.component';
import { PublicacaoListaGtComponent } from './pages/publicacao-lista-gt/publicacao-lista-gt.component';
import { PublicacaoListaTrabalhoComponent } from './pages/publicacao-lista-trabalho/publicacao-lista-trabalho.component';
import { TrabalhosListComponent } from './pages/trabalhos-list/trabalhos-list.component';
import { LinksGtsViewComponent } from './pages/links-gts-view/links-gts-view.component';
import { GtPublicacaoListaComponent } from './pages/gt-publicacao-lista/gt-publicacao-lista.component';
import { GtPublicacaoComponent } from './pages/gt-publicacao/gt-publicacao.component';
import { PainelEventoListaComponent } from './pages/painel-evento-lista/painel-evento-lista.component';
import { PainelEventoInsertComponent } from './pages/painel-evento-insert/painel-evento-insert.component';
import { PainelEventoEditComponent } from './pages/painel-evento-edit/painel-evento-edit.component';
import { DiretoriaComponent } from './pages/diretoria/diretoria.component';
import { DiretoriaGrupoComponent } from './components/diretoria-grupo/diretoria-grupo.component';
import { DiretoriaListComponent } from './pages/diretoria-list/diretoria-list.component';
import { DiretoresListComponent } from './pages/diretores-list/diretores-list.component';
import { DiretoriaUpsertComponent } from './pages/diretoria-upsert/diretoria-upsert.component';
import { DiretorUpsertComponent } from './pages/diretor-upsert/diretor-upsert.component';
import { DiretorFotoComponent } from './pages/diretor-foto/diretor-foto.component';
import { DocumentosComponent } from './pages/documentos/documentos.component';
import { DocumentosListComponent } from './pages/documentos-list/documentos-list.component';
import { DocumentoUpsertComponent } from './pages/documento-upsert/documento-upsert.component';
import { DocumentoFotoUpsertComponent } from './pages/documento-foto-upsert/documento-foto-upsert.component';
import { BannerListComponent } from './pages/banner-list/banner-list.component';
import { UploadArquivoComponent } from './components/upload-arquivo/upload-arquivo.component';
import { DragDropDirective } from './shared/directives/drag-drop/drag-drop.directive';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { SecaoDocumentoComponent } from './components/secao-documento/secao-documento.component';
import { DocumentosTextoIntrodutorioEditComponent } from './pages/documentos-texto-introdutorio-edit/documentos-texto-introdutorio-edit.component';
import { DocumentosTextoIntrodutorioComponent } from './pages/documentos-texto-introdutorio/documentos-texto-introdutorio.component';
import { AreaDoAssociadoComponent } from './pages/area-do-associado/area-do-associado.component';
import { EditaisComponent } from './pages/editais/editais.component';
import { EventosComponent } from './pages/eventos/eventos.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MapaPpgdComponent } from './pages/mapa-ppgd/mapa-ppgd.component';
import { MapaPpgdBaseComponent } from './components/mapa-ppgd-base/mapa-ppgd-base.component';
import { EventoAssociadoComponent } from './pages/evento-associado/evento-associado.component';
import { EnviarArtigoComponent } from './pages/enviar-artigo/enviar-artigo.component';
import { EventoInscricaoComponent } from './pages/evento-inscricao/evento-inscricao.component';
import { EnviarPosterComponent } from './pages/enviar-poster/enviar-poster.component';
import { EventoTrabalhoComponent } from './pages/evento-trabalho/evento-trabalho.component';
import { AtualizaDadosComponent } from './pages/atualiza-dados/atualiza-dados.component';
import { NgxMaskModule } from 'ngx-mask';
import { EdicaoTitulacaoFormacaoComponent } from './components/edicao-titulacao-formacao/edicao-titulacao-formacao.component';
import { EventoEdicaoPagamentoComponent } from './components/evento-edicao-pagamento/evento-edicao-pagamento.component';
import { NgxCurrencyModule } from "ngx-currency";
import { LoginAdmComponent } from './pages/login-adm/login-adm.component';
import { ConfiguracaoGeralComponent } from './pages/configuracao-geral/configuracao-geral.component';
import { UploadImagemComponent } from './components/upload-imagem/upload-imagem.component';
import { EditaCredencialComponent } from './components/edita-credencial/edita-credencial.component';

registerLocaleData(localeBr, 'pt')

const defaultOptionsApollo = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

@NgModule({
  imports: [
    GalleryModule.forRoot(),
    ScrollToModule.forRoot(),
    BrowserModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AppRoutes,
    HttpClientModule,
    BrowserAnimationsModule,
    AccordionModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    ChartModule,
    CheckboxModule,
    ChipsModule,
    CodeHighlighterModule,
    ConfirmDialogModule,
    DynamicDialogModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DropdownModule,
    EditorModule,
    FieldsetModule,
    FileUploadModule,
    FullCalendarModule,
    GalleriaModule,
    GrowlModule,
    InplaceModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    LightboxModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    ScrollPanelModule,
    SelectButtonModule,
    SlideMenuModule,
    SliderModule,
    SpinnerModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TerminalModule,
    TieredMenuModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    VirtualScrollerModule,
    ApolloModule,
    HttpLinkModule,
    LayoutModule,
    KeyFilterModule,
    ProgressSpinnerModule,
    NgxCurrencyModule
  ],
  declarations: [
    AppComponent,
    AppProfileComponent,
    AppBreadcrumbComponent,
    AppTopBarComponent,
    AppFooterComponent,
    EventoComponent,
    HotsiteEditorComponent,
    HotsiteComponent,
    HotsiteViewComponent,
    GaleriaListComponent,
    GaleriaViewComponent,
    TemlateSiteComponent,
    InicialComponent,
    InicialEventosComponent,
    InicialNoticiaComponent,
    InicialGaleriaComponent,
    InicialMidiaComponent,
    InicialColunistasComponent,
    InicialMapaPddgComponent,
    NoticiasListComponent,
    NoticiasViewComponent,
    NoticiaListaComponent,
    MenuLateralListComponent,
    ColunistaListComponent,
    MapaPpdgListComponent,
    TvConpediListComponent,
    AgendaListComponent,
    EditaisListComponent,
    EspacoDoAutorListComponent,
    RevistaConpediListComponent,
    InicialMostraImagensComponent,
    InicialMenuLateralComponent,
    ColunistaEditorComponent,
    ColunistaViewComponent,
    EditalListComponent,
    NoticiasInsertComponent,
    NoticiasEditComponent,
    MapaPpdgEditComponent,
    MapaPpgdInsertComponent,
    NoticiaViewComponent,
    RevistasViewComponent,
    EspacosAutorViewComponent,
    TvsConpediViewComponent,
    ColunistaPortalViewComponent,
    ColunistasPortalViewComponent,
    FotoColunistaViewComponent,
    PortalTituloComponent,
    PortalTituloPaginaComponent,
    EspacoAutorLivroViewComponent,
    PortalRodapeComponent,
    MenuComponent,
    CabecalhoComponent,
    MapaPpgdViewComponent,
    DetalheNoticiaViewComponent,
    DetalheGaleriaViewComponent,
    LoginLegadoComponent,
    MenuInicialComponent,
    AnuidadeManualComponent,
    ColunistaApresentacaoListaComponent,
    EventoEditorComponent,
    GtpDefinirComponent,
    GtpListarComponent,
    GtpArtigosComponent,
    GtpEditaComponent,
    EventoInscritoConsultaComponent,
    GtpCoordenadorComponent,
    SelecaoPessoaComponent,
    RoleDirective,
    DragDropDirective,
    FaleConoscoComponent,
    MemoriaComponent,
    MemoriaEditComponent,
    BotaoDireitaCrudComponent,
    FaleConoscoEditComponent,
    QuemSomosComponent,
    EditarConteudoComponent,
    GerenciarConteudoComponent,
    QuemSomosSobreGerenciarComponent,
    QuemSomosMissaoGerenciarComponent,
    QuemSomosValoresGerenciarComponent,
    QuemSomosSobreEditComponent,
    QuemSomosMissaoEditComponent,
    QuemSomosValoresEditComponent,
    AgendaComponent,
    GtpPublicacaoComponent,
    GtpPublicacaoListaComponent,
    GtpPublicacaoTextoComponent,
    NomeGrupoTrabalhoPipe,
    PublicacaoListaEventoComponent,
    PublicacaoListaGtComponent,
    PublicacaoListaTrabalhoComponent,
    TrabalhosListComponent,
    LinksGtsViewComponent,
    GtPublicacaoListaComponent,
    GtPublicacaoComponent,
    PainelEventoListaComponent,
    PainelEventoInsertComponent,
    PainelEventoEditComponent,
    DiretoriaComponent,
    DiretoriaGrupoComponent,
    DiretoriaListComponent,
    DiretoresListComponent,
    DiretoriaUpsertComponent,
    DiretorUpsertComponent,
    DiretorFotoComponent,
    DocumentosComponent,
    DocumentosListComponent,
    DocumentoUpsertComponent,
    DocumentoFotoUpsertComponent,
    BannerListComponent,
    UploadArquivoComponent,
    SecaoDocumentoComponent,
    DocumentosTextoIntrodutorioEditComponent,
    DocumentosTextoIntrodutorioComponent,
    AreaDoAssociadoComponent,
    EditaisComponent,
    EventosComponent,
    MapaPpgdComponent,
    MapaPpgdBaseComponent,
    EventoAssociadoComponent,
    EnviarArtigoComponent,
    EventoInscricaoComponent,
    EnviarPosterComponent,
    EventoTrabalhoComponent,
    AtualizaDadosComponent,
    EdicaoTitulacaoFormacaoComponent,
    EventoEdicaoPagamentoComponent,
    LoginAdmComponent,
    ConfiguracaoGeralComponent,
    UploadImagemComponent,
    EditaCredencialComponent,
  ],
  entryComponents: [UploadArquivoComponent],
  providers: [
    NomeGrupoTrabalhoPipe,
    ConfirmationService,
    DialogService,
    DeviceDetectorService,
    { provide: LOCALE_ID, useValue: "pt" },
    MessageService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    BreadcrumbService,
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: `${enderecoServidorGraphql}/graphql`
          }),
          defaultOptions: defaultOptionsApollo
        }
      },
      deps: [HttpLink]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
