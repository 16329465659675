import { Component, OnInit } from '@angular/core';
import { GraphqlService, Usuario } from 'src/app/service/graphql/graphql.service';
import { AutenticacaoService } from 'src/app/service/autenticacao/autenticacao.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-memoria',
  templateUrl: './memoria.component.html',
  styleUrls: ['./memoria.component.css']
})
export class MemoriaComponent implements OnInit {

  public descricao: string;

  constructor(private graphqlService: GraphqlService) {
      this.graphqlService.obtemMemoria().subscribe(
        (memoria) => {
          this.descricao = memoria
        }
      );
    }

  ngOnInit() {
  }

}
