import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GraphqlService } from '../../service/graphql/graphql.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-anuidade-manual',
  templateUrl: './anuidade-manual.component.html',
  styleUrls: ['./anuidade-manual.component.css'],
  providers: [MessageService]
})
export class AnuidadeManualComponent implements OnInit {

  public form: FormGroup;

  constructor(private formBuilder: FormBuilder, private graphqlService: GraphqlService, private messageService: MessageService) {
    this.form = this.formBuilder.group({
      email1: ['', Validators.compose([Validators.required, Validators.email])],
      email2: ['', Validators.compose([Validators.required, Validators.email])],
    });
  }

  ngOnInit() {
  }

  public atualizarAnuidade() {
    const email1 = this.form.get('email1').value;
    const email2 = this.form.get('email2').value;
    this.graphqlService.atualizaAnuidadeAssociado(email1, email2, 2020).subscribe(
      resp => {
        if (resp['ok']) {
          this.messageService.add({ severity: 'success', summary: 'Successo', detail: 'Anuidade atualizada.' });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Erro', detail: resp['msg'] });
        }
      }, error => {
        this.messageService.add({ severity: 'error', summary: 'Erro', detail: 'Ocorreu um erro inesperado, por favor tente novamente mais tarde. ' + error });
      }
    )
  }

  public voltar() {

  }

}
