import { Component, OnInit } from '@angular/core';
import { GraphqlService, MapaPpgd } from '../../service/graphql/graphql.service';

@Component({
  selector: 'app-mapa-ppdg-list',
  templateUrl: './mapa-ppdg-list.component.html',
  styleUrls: ['./mapa-ppdg-list.component.css']
})
export class MapaPpdgListComponent implements OnInit {

  public cols: any[];
  public mapasPddg: MapaPpgd[];
  public displayExcluir: boolean;
  public mapaSelecionado: MapaPpgd;
  public msgs: any[];

  constructor(private graphqlService: GraphqlService) { 
    this.atualizarLista();
  }

  private atualizarLista() {
    this.graphqlService.obtemMapasPpgd().subscribe(
      mapas => {
        this.mapasPddg = mapas;
      }
    );
  }

  ngOnInit() {
    this.cols = [
      { field: 'titulo', header: 'Nome do PPGD' },
      { field: 'sigla', header: 'Sigla'},
      { field: 'uf', header: 'UF'},
      { field: 'fgAtivo', header: 'Ativo'}
    ];
  }

  public abrirDialogExclusao(mapa: MapaPpgd) {
    this.mapaSelecionado = mapa;
    this.displayExcluir = true;
  }

  public excluirMapa() {
    this.graphqlService.excluirMapaPpgd(this.mapaSelecionado.id).subscribe(
      () => {
        this.atualizarLista();
        this.displayExcluir = false;
      }, () => { 
        this.apresentarMensagemDeErro();
      }
    )
  }

  private apresentarMensagemDeErro() {
    this.msgs = [
      {severity:'error', detail:'Ocorreu um erro inesperado'}
    ]
  }

}
